import React, { useState, useEffect } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { RENDER_GUESSES, calculateTimeUntilMidnight } from '../config/Constants';
import '../css/Modal.css';

export default function Modal({ imgSrc, message, answer, guesses }) {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeUntilMidnight());

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeRemaining !== 0) {
        setTimeRemaining(calculateTimeUntilMidnight());
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <Dialog
      TransitionComponent={Slide}
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className='modal-content'>
          <Typography sx={{ fontSize: '30px', fontWeight: '900', marginBottom: '20px' }}>{message}</Typography>
          <div className='modal-image'>
            <img
              src={imgSrc}
              alt=''
            />
          </div>
          <div className='answer'>{answer}</div>
          <div className='guess-attempts'>{RENDER_GUESSES(guesses)}</div>
          <div className='timer-text'>PLAY AGAIN IN</div>
          <div className='timer'>
            {hours}:{formattedMinutes}:{formattedSeconds}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}