import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import Cookies from 'js-cookie';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Guess from '../components/Guess';
import Modal from '../components/Modal';
import CircularProgress from "@mui/material/CircularProgress";
import {
  GAME_STATES,
  BLUR_STATES,
  GUESSES_REMAINING,
  RENDER_GUESSES,
  calculateTimeUntilMidnight
} from '../config/Constants';
import '../css/Game.css';

export default function Game({ type, answers, hints }) {
  const unsortedOptions = [...answers];
  const [guess, setGuess] = useState('');
  const [guesses, setGuesses] = useState([]);
  const [gameState, setGameState] = useState(GAME_STATES.PLAYING);
  const [blur, setBlur] = useState(BLUR_STATES[0]);
  const [open, setOpen] = useState(false);
  const [guessEntered, setGuessEntered] = useState(false);
  const [options, setOptions] = useState(unsortedOptions.map(item => item.name).sort());
  const [imageLoading, setImageLoading] = useState(true);
  const loading = open && options.length === 0;
  const currentTime = Date.now();
  const timezoneOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;
  const startTime = new Date('2024-06-01').getTime() + timezoneOffsetMs;
  const diffTime = currentTime - startTime;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const index = diffDays % answers.length;
  const todaysAnswer = answers[index].name;
  const todaysHints = answers[index].hints;
  const imageSrc = `https://daily-blur-game-data.s3.amazonaws.com/${type}/${todaysAnswer}.jpg`;
  const today = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  useEffect(() => {
    document.title = `Daily Blur - ${type}`;
  }, []);

  useEffect(() => {
    const savedGameData = Cookies.get(type);
    if (savedGameData) {
      const { guesses, gameState } = JSON.parse(savedGameData);
      setGuesses(guesses);
      setGameState(gameState);
      if (gameState === GAME_STATES.WIN) {
        setBlur(0);
      }
      else if (gameState === GAME_STATES.LOSS) {
        setBlur(0);
      } else {
        setBlur(BLUR_STATES[guesses.length]);
        setOptions(options.filter(option => !guesses.some(guess => guess.text === option)));
      }
    }
  }, [type]);

  const newGuess = (guess) => {
    return {
      text: guess,
      isCorrect: guess.toLowerCase() === todaysAnswer.toLowerCase(),
    }
  }

  const handleGuess = () => {
    // Make updates after a guess
    if (guess == null) return;
    const updatedGuesses = [...guesses, newGuess(guess)];
    const updatedBlur = BLUR_STATES[updatedGuesses.length]
    let updatedGameState = GAME_STATES.PLAYING;
    setGuesses(updatedGuesses);

    // Remove the guess from the options
    setOptions(options.filter(item => item !== guess));

    // Player ran out of guesses
    if (updatedGuesses.length === GUESSES_REMAINING &&
      guess.toLowerCase() !== todaysAnswer.toLowerCase()) {
      setBlur(0);
      updatedGameState = GAME_STATES.LOSS;
      setGameState(updatedGameState);

      // Player guessed correctly
    } else if (guess.toLowerCase() === todaysAnswer.toLowerCase()) {
      setBlur(0);
      updatedGameState = GAME_STATES.WIN;
      setGameState(updatedGameState);

      // Player still guessing
    } else {
      setBlur(updatedBlur);
    }
    setGuess('');
    setGuessEntered(false);
    const updatedGameData = { guesses: updatedGuesses, gameState: updatedGameState };
    const timeUntilMidnight = new Date(new Date().getTime() + calculateTimeUntilMidnight());
    Cookies.set(type, JSON.stringify(updatedGameData), { expires: timeUntilMidnight });
  };

  const handleImageLoaded = () => {
    setTimeout(() => {
      setImageLoading(false);
    }, 500);
  };

  const showHint = () => {
    return guesses.length >= 1 && guesses.length < 4 && !guesses[guesses.length - 1].isCorrect;
  }

  return (
    <>
      <h1 className="title">{type.toUpperCase()}</h1>
      <div className='date'>
        {today}
      </div>
      <div className='image'>
        {imageLoading ? <CircularProgress color='inherit' size={50} /> : null}
        <img
          src={imageSrc}
          style={{ height: '100%', width: '100%', filter: `blur(${blur}px)`, transition: 'filter 350ms ease', display: imageLoading ? 'none' : 'block' }}
          onLoad={handleImageLoaded}
          alt=''
        />
        <div className='hint'>{(hints && !imageLoading && showHint()) ? `${todaysHints[guesses.length - 1].key}: ${todaysHints[guesses.length - 1].value}` : null}</div>
      </div>
      <div className='guess'>
        <Autocomplete
          disabled={gameState !== GAME_STATES.PLAYING}
          className='guess-item'
          options={options}
          blurOnSelect
          sx={{
            width: 300,
            '& .MuiInputBase-root': { borderRadius: '0' },
            '& .MuiAutocomplete-popupIndicator': { display: 'none' },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #ccc' },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '1px solid #1976d2' },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { border: '1px solid #1976d2' },
          }}
          componentsProps={{
            popper: {
              modifiers: [
                {
                  name: 'flip',
                  enabled: false
                },
                {
                  name: 'preventOverflow',
                  enabled: false
                }
              ],
            }
          }}
          renderInput={(params) =>
            <TextField
              placeholder={`Guess the ${type}...`}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          }
          ListboxProps={{ style: { maxHeight: 200 } }}
          open={open}
          value={guess}
          onInputChange={(_, value) => {
            if (!guessEntered) {
              if (value.length > 0) setOpen(true);
              else setOpen(false);
            } else {
              if (value.length === 0) setGuessEntered(false);
            }
          }}
          onChange={(_, value) => {
            if (value !== null) {
              setGuess(value);
              window.scrollTo({ top: 0, behavior: 'smooth' });
              if (value.length > 0) {
                setGuessEntered(true);
              } else {
                setGuessEntered(false);
              }
            }
          }}
          onClose={() => setOpen(false)}
        />
        <Button
          className='guess-item'
          sx={{
            width: 80, borderRadius: '0px', boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          }}
          variant='contained'
          onClick={guess !== '' ? handleGuess : null}>
          Guess
        </Button>
      </div>
      <div className='guesses-remaining'>Guesses: <div className='dots'>{RENDER_GUESSES(guesses)}</div></div>
      <div className='guesses'>
        {guesses.map((guess, index) => (
          <Guess key={index} guess={guess} />
        ))}
      </div>
      <div>
        {gameState !== GAME_STATES.PLAYING ?
          <Modal
            imgSrc={imageSrc}
            message={gameState === GAME_STATES.WIN ? 'Well done!' : 'Maybe next time.'}
            answer={todaysAnswer}
            guesses={guesses}>
          </Modal> : null}
      </div>
    </>
  );
}