import React, { useState } from 'react';
import Button from '@mui/material/Button';
import MenuMui from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from "@mui/material/Divider";
import { Link } from 'react-router-dom';

export default function Menu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon color="action" />
      </Button>
      <MenuMui
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          width: 250
        }}
      >
        <MenuItem component={Link} to='/album' onClick={handleClose}>Album Cover</MenuItem>
        <Divider />
        <MenuItem component={Link} to='/celebrity' onClick={handleClose}>Celebrity</MenuItem>
        <Divider />
        <MenuItem component={Link} to='/game' onClick={handleClose}>Video Game</MenuItem>
        <Divider />
        <MenuItem component={Link} to='/movie' onClick={handleClose}>Movie Poster</MenuItem>
      </MenuMui>
    </div>
  );
}