export const GAME_ANSWERS = [
  {
    "name": "NASCAR Racing 4",
    "hints": [
      { "key": "Developer", "value": "Papyrus Design Group" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "God of War III",
    "hints": [
      { "key": "Developer", "value": "Santa Monica Studio" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "GTR 2",
    "hints": [
      { "key": "Developer", "value": "SimBin Studios" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Silent Hunter III",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Bucharest" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Submarine simulation" }
    ]
  },
  {
    "name": "Asgard's Wrath",
    "hints": [
      { "key": "Developer", "value": "Sanzaru Games" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Perfect Dark (2000)",
    "hints": [
      { "key": "Developer", "value": "Rare" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "SSX Tricky",
    "hints": [
      { "key": "Developer", "value": "EA Canada" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Snowboarding" }
    ]
  },
  {
    "name": "Portal 2",
    "hints": [
      { "key": "Developer", "value": "Valve" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Puzzle-platform" }
    ]
  },
  {
    "name": "Age of Mythology",
    "hints": [
      { "key": "Developer", "value": "Ensemble Studios" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "Fallout 4",
    "hints": [
      { "key": "Developer", "value": "Bethesda Game Studios" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Dead Space",
    "hints": [
      { "key": "Developer", "value": "EA Redwood Shores" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Survival horror" }
    ]
  },
  {
    "name": "Mark of the Ninja",
    "hints": [
      { "key": "Developer", "value": "Klei Entertainment" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Stealth" }
    ]
  },
  {
    "name": "XCOM: Enemy Unknown",
    "hints": [
      { "key": "Developer", "value": "Firaxis Games" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Turn-based tactics" }
    ]
  },
  {
    "name": "Yakuza: Like a Dragon",
    "hints": [
      { "key": "Developer", "value": "Ryu Ga Gotoku Studio" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Ninja Gaiden",
    "hints": [
      { "key": "Developer", "value": "Team Ninja" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Flight Simulator 2002",
    "hints": [
      { "key": "Developer", "value": "Microsoft Game Studios" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Flight simulation" }
    ]
  },
  {
    "name": "Warcraft III: The Frozen Throne",
    "hints": [
      { "key": "Developer", "value": "Blizzard Entertainment" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "Far Cry",
    "hints": [
      { "key": "Developer", "value": "Crytek" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Fallout 3",
    "hints": [
      { "key": "Developer", "value": "Bethesda Game Studios" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Call of Duty: Warzone",
    "hints": [
      { "key": "Developer", "value": "Infinity Ward" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Battle royale" }
    ]
  },
  {
    "name": "The Legend of Zelda: Breath of the Wild",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Grand Theft Auto IV",
    "hints": [
      { "key": "Developer", "value": "Rockstar North" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Super Mario 3D Land",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Tony Hawk's Pro Skater",
    "hints": [
      { "key": "Developer", "value": "Neversoft" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Skateboarding" }
    ]
  },
  {
    "name": "Super Mario 3D World",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Tiny Tina's Wonderlands",
    "hints": [
      { "key": "Developer", "value": "Gearbox Software" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Chrono Trigger",
    "hints": [
      { "key": "Developer", "value": "Square" },
      { "key": "Release year", "value": "1995" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Prince of Persia: The Sands of Time",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Trials Evolution",
    "hints": [
      { "key": "Developer", "value": "RedLynx" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Chained Echoes",
    "hints": [
      { "key": "Developer", "value": "Matthias Linda" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "BioShock 2",
    "hints": [
      { "key": "Developer", "value": "2K Marin" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Final Fantasy Chronicles",
    "hints": [
      { "key": "Developer", "value": "Square Enix" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Returnal",
    "hints": [
      { "key": "Developer", "value": "Housemarque" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Third-person shooter" }
    ]
  },
  {
    "name": "Diablo IV",
    "hints": [
      { "key": "Developer", "value": "Blizzard Entertainment" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "PUBG: Battlegrounds",
    "hints": [
      { "key": "Developer", "value": "PUBG Corporation" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Battle royale" }
    ]
  },
  {
    "name": "Into the Breach",
    "hints": [
      { "key": "Developer", "value": "Subset Games" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Turn-based tactics" }
    ]
  },
  {
    "name": "Luigi's Mansion 3",
    "hints": [
      { "key": "Developer", "value": "Next Level Games" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Ratchet & Clank: Rift Apart",
    "hints": [
      { "key": "Developer", "value": "Insomniac Games" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Soulcalibur",
    "hints": [
      { "key": "Developer", "value": "Project Soul" },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "Fighting" }
    ]
  },
  {
    "name": "F.E.A.R. 3",
    "hints": [
      { "key": "Developer", "value": "Day 1 Studios" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Need for Speed: Hot Pursuit 2",
    "hints": [
      { "key": "Developer", "value": "EA Black Box" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Final Fantasy VII",
    "hints": [
      { "key": "Developer", "value": "Square" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "F-Zero GX",
    "hints": [
      { "key": "Developer", "value": "Amusement Vision" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Rise of Nations",
    "hints": [
      { "key": "Developer", "value": "Big Huge Games" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "CTR: Crash Team Racing",
    "hints": [
      { "key": "Developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Uncharted 4: A Thief's End",
    "hints": [
      { "key": "Developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Forza Horizon 3",
    "hints": [
      { "key": "Developer", "value": "Playground Games" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Batman: Arkham City",
    "hints": [
      { "key": "Developer", "value": "Rocksteady Studios" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "The Last of Us Part I",
    "hints": [
      { "key": "Developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Dark Souls III",
    "hints": [
      { "key": "Developer", "value": "FromSoftware" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Super Smash Bros. Brawl",
    "hints": [
      { "key": "Developer", "value": "Sora Ltd." },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Fighting" }
    ]
  },
  {
    "name": "Hades II",
    "hints": [
      { "key": "Developer", "value": "Supergiant Games" },
      { "key": "Release year", "value": "2024" },
      { "key": "Genre", "value": "Roguelike" }
    ]
  },
  {
    "name": "Tales of Arise",
    "hints": [
      { "key": "Developer", "value": "Bandai Namco Studios" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Super Smash Bros. Ultimate",
    "hints": [
      { "key": "Developer", "value": "Bandai Namco Studios" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Fighting" }
    ]
  },
  {
    "name": "Doom Eternal",
    "hints": [
      { "key": "Developer", "value": "id Software" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Yoshi's Island: Super Mario Advance 3",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Borderlands",
    "hints": [
      { "key": "Developer", "value": "Gearbox Software" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "The Outer Worlds",
    "hints": [
      { "key": "Developer", "value": "Obsidian Entertainment" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Devil May Cry 5",
    "hints": [
      { "key": "Developer", "value": "Capcom" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Burnout 3: Takedown",
    "hints": [
      { "key": "Developer", "value": "Criterion Games" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Warcraft III: Reign of Chaos",
    "hints": [
      { "key": "Developer", "value": "Blizzard Entertainment" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "Resident Evil 2",
    "hints": [
      { "key": "Developer", "value": "Capcom" },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "Survival horror" }
    ]
  },
  {
    "name": "Star Wars Jedi: Fallen Order",
    "hints": [
      { "key": "Developer", "value": "Respawn Entertainment" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Neon White",
    "hints": [
      { "key": "Developer", "value": "Angel Matrix" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Spider-Man: Miles Morales",
    "hints": [
      { "key": "Developer", "value": "Insomniac Games" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "DAVE THE DIVER",
    "hints": [
      { "key": "Developer", "value": "MINTROCKET" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Adventure" }
    ]
  },
  {
    "name": "Astro Bot Rescue Mission",
    "hints": [
      { "key": "Developer", "value": "Japan Studio" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Grand Theft Auto: Vice City",
    "hints": [
      { "key": "Developer", "value": "Rockstar North" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Resident Evil 4",
    "hints": [
      { "key": "Developer", "value": "Capcom" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Survival horror" }
    ]
  },
  {
    "name": "Grand Theft Auto: San Andreas",
    "hints": [
      { "key": "Developer", "value": "Rockstar North" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "What Remains of Edith Finch",
    "hints": [
      { "key": "Developer", "value": "Giant Sparrow" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Adventure" }
    ]
  },
  {
    "name": "Battlefield 2",
    "hints": [
      { "key": "Developer", "value": "DICE" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Super Mario Sunshine",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Rise of the Tomb Raider",
    "hints": [
      { "key": "Developer", "value": "Crystal Dynamics" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Baldur's Gate 3",
    "hints": [
      { "key": "Developer", "value": "Larian Studios" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Tom Clancy's Splinter Cell: Pandora Tomorrow",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Shanghai" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Stealth" }
    ]
  },
  {
    "name": "Horizon Forbidden West",
    "hints": [
      { "key": "Developer", "value": "Guerrilla Games" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "The Binding of Isaac: Rebirth",
    "hints": [
      { "key": "Developer", "value": "Nicalis" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Roguelike" }
    ]
  },
  {
    "name": "Celeste",
    "hints": [
      { "key": "Developer", "value": "Maddy Makes Games" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Stardew Valley",
    "hints": [
      { "key": "Developer", "value": "ConcernedApe" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Simulation" }
    ]
  },
  {
    "name": "Mario Kart: Super Circuit",
    "hints": [
      { "key": "Developer", "value": "Intelligent Systems" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Assassin's Creed: Brotherhood",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "World in Conflict",
    "hints": [
      { "key": "Developer", "value": "Massive Entertainment" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Real-time tactics" }
    ]
  },
  {
    "name": "LittleBigPlanet",
    "hints": [
      { "key": "Developer", "value": "Media Molecule" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "The Legend of Zelda: Tears of the Kingdom",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Pillars of Eternity",
    "hints": [
      { "key": "Developer", "value": "Obsidian Entertainment" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Dishonored 2",
    "hints": [
      { "key": "Developer", "value": "Arkane Studios" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Pizza Tower",
    "hints": [
      { "key": "Developer", "value": "Tour De Pizza" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Gears of War 2",
    "hints": [
      { "key": "Developer", "value": "Epic Games" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Third-person shooter" }
    ]
  },
  {
    "name": "Shadow of the Colossus",
    "hints": [
      { "key": "Developer", "value": "Team Ico" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Rome: Total War",
    "hints": [
      { "key": "Developer", "value": "Creative Assembly" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Strategy" }
    ]
  },
  {
    "name": "ARK: Survival Evolved",
    "hints": [
      { "key": "Developer", "value": "Studio Wildcard" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Assassin's Creed IV: Black Flag",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Castlevania: Symphony of the Night",
    "hints": [
      { "key": "Developer", "value": "Konami" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Resident Evil",
    "hints": [
      { "key": "Developer", "value": "Capcom" },
      { "key": "Release year", "value": "1996" },
      { "key": "Genre", "value": "Survival horror" }
    ]
  },
  {
    "name": "The Chronicles of Riddick: Escape From Butcher Bay",
    "hints": [
      { "key": "Developer", "value": "Starbreeze Studios" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Streets of Rage 2",
    "hints": [
      { "key": "Developer", "value": "Sega" },
      { "key": "Release year", "value": "1992" },
      { "key": "Genre", "value": "Beat 'em up" }
    ]
  },
  {
    "name": "Super Mario Galaxy",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "The Curse of Monkey Island",
    "hints": [
      { "key": "Developer", "value": "LucasArts" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Adventure" }
    ]
  },
  {
    "name": "Battlefield 3",
    "hints": [
      { "key": "Developer", "value": "DICE" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Metal Gear Solid",
    "hints": [
      { "key": "Developer", "value": "Konami" },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Hitman 3",
    "hints": [
      { "key": "Developer", "value": "IO Interactive" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Stealth" }
    ]
  },
  {
    "name": "Cuphead",
    "hints": [
      { "key": "Developer", "value": "Studio MDHR" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Run and gun" }
    ]
  },
  {
    "name": "Wave Race 64",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "1996" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Age of Empires II: The Age of Kings",
    "hints": [
      { "key": "Developer", "value": "Ensemble Studios" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "Halo Infinite",
    "hints": [
      { "key": "Developer", "value": "343 Industries" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Cyberpunk 2077: Phantom Liberty",
    "hints": [
      { "key": "Developer", "value": "CD Projekt Red" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Ghost of Tsushima",
    "hints": [
      { "key": "Developer", "value": "Sucker Punch Productions" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Rocket League",
    "hints": [
      { "key": "Developer", "value": "Psyonix" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Sports" }
    ]
  },
  {
    "name": "Project Gotham Racing 3",
    "hints": [
      { "key": "Developer", "value": "Bizarre Creations" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Rock Band 2",
    "hints": [
      { "key": "Developer", "value": "Harmonix" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Rhythm" }
    ]
  },
  {
    "name": "Team Fortress 2",
    "hints": [
      { "key": "Developer", "value": "Valve" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Gran Turismo 4",
    "hints": [
      { "key": "Developer", "value": "Polyphony Digital" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Burnout Revenge",
    "hints": [
      { "key": "Developer", "value": "Criterion Games" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Bayonetta 2",
    "hints": [
      { "key": "Developer", "value": "PlatinumGames" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Action" }
    ]
  },
  {
    "name": "Super Mario Maker",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "A Short Hike",
    "hints": [
      { "key": "Developer", "value": "Adamgryu" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Adventure" }
    ]
  },
  {
    "name": "Metal Gear Solid 2: Sons of Liberty",
    "hints": [
      { "key": "Developer", "value": "Konami" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Devil May Cry 2",
    "hints": [
      { "key": "Developer", "value": "Capcom" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Super Meat Boy",
    "hints": [
      { "key": "Developer", "value": "Team Meat" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Grand Theft Auto: Chinatown Wars",
    "hints": [
      { "key": "Developer", "value": "Rockstar Leeds" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "DayZ",
    "hints": [
      { "key": "Developer", "value": "Bohemia Interactive" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Survival" }
    ]
  },
  {
    "name": "Tekken 8",
    "hints": [
      { "key": "Developer", "value": "Bandai Namco Entertainment" },
      { "key": "Release year", "value": "2024" },
      { "key": "Genre", "value": "Fighting" }
    ]
  },
  {
    "name": "Burnout Paradise",
    "hints": [
      { "key": "Developer", "value": "Criterion Games" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Super Mario Galaxy 2",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Super Mario Bros. 3",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "1988" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Fall Guys",
    "hints": [
      { "key": "Developer", "value": "Mediatonic" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Battle royale" }
    ]
  },
  {
    "name": "Chrono Cross",
    "hints": [
      { "key": "Developer", "value": "Square" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Killzone 2",
    "hints": [
      { "key": "Developer", "value": "Guerrilla Games" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Age of Empires IV",
    "hints": [
      { "key": "Developer", "value": "Relic Entertainment" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "Ori and the Will of the Wisps",
    "hints": [
      { "key": "Developer", "value": "Moon Studios" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Metroidvania" }
    ]
  },
  {
    "name": "R4: Ridge Racer Type 4",
    "hints": [
      { "key": "Developer", "value": "Namco" },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Mario & Luigi: Bowser's Inside Story",
    "hints": [
      { "key": "Developer", "value": "AlphaDream" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Fire Emblem: Three Houses",
    "hints": [
      { "key": "Developer", "value": "Intelligent Systems" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Tactical role-playing" }
    ]
  },
  {
    "name": "The Stanley Parable: Ultra Deluxe",
    "hints": [
      { "key": "Developer", "value": "Crows Crows Crows" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Adventure" }
    ]
  },
  {
    "name": "Battlefield 4",
    "hints": [
      { "key": "Developer", "value": "DICE" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Dark Souls",
    "hints": [
      { "key": "Developer", "value": "FromSoftware" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "The Last of Us: Left Behind",
    "hints": [
      { "key": "Developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "LittleBigPlanet 2",
    "hints": [
      { "key": "Developer", "value": "Media Molecule" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Fable",
    "hints": [
      { "key": "Developer", "value": "Lionhead Studios" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Gears of War 3",
    "hints": [
      { "key": "Developer", "value": "Epic Games" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Third-person shooter" }
    ]
  },
  {
    "name": "NASCAR Racing 2002 Season",
    "hints": [
      { "key": "Developer", "value": "Papyrus Design Group" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Myth: The Fallen Lords",
    "hints": [
      { "key": "Developer", "value": "Bungie" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Real-time tactics" }
    ]
  },
  {
    "name": "FreeSpace 2",
    "hints": [
      { "key": "Developer", "value": "Volition" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Space simulation" }
    ]
  },
  {
    "name": "The Legend of Zelda: A Link Between Worlds",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Uncharted 3: Drake's Deception",
    "hints": [
      { "key": "Developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Metroid Fusion",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Dead by Daylight",
    "hints": [
      { "key": "Developer", "value": "Behaviour Interactive" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Survival horror" }
    ]
  },
  {
    "name": "Brothers in Arms: Road to Hill 30",
    "hints": [
      { "key": "Developer", "value": "Gearbox Software" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "The Operative: No One Lives Forever",
    "hints": [
      { "key": "Developer", "value": "Monolith Productions" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Final Fantasy VI",
    "hints": [
      { "key": "Developer", "value": "Square" },
      { "key": "Release year", "value": "1994" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Sid Meier's Alpha Centauri",
    "hints": [
      { "key": "Developer", "value": "Firaxis Games" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Turn-based strategy" }
    ]
  },
  {
    "name": "GoldenEye 007",
    "hints": [
      { "key": "Developer", "value": "Rare" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Quake",
    "hints": [
      { "key": "Developer", "value": "id Software" },
      { "key": "Release year", "value": "1996" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Resistance: Fall of Man",
    "hints": [
      { "key": "Developer", "value": "Insomniac Games" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Super Mario Bros. Wonder",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Guitar Hero",
    "hints": [
      { "key": "Developer", "value": "Harmonix" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Rhythm" }
    ]
  },
  {
    "name": "Mass Effect 2",
    "hints": [
      { "key": "Developer", "value": "BioWare" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Falcon 4.0: Allied Force",
    "hints": [
      { "key": "Developer", "value": "Lead Pursuit" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Flight simulation" }
    ]
  },
  {
    "name": "Jade Empire",
    "hints": [
      { "key": "Developer", "value": "BioWare" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Unreal Tournament 2004",
    "hints": [
      { "key": "Developer", "value": "Epic Games" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "VIDEOVERSE",
    "hints": [
      { "key": "Developer", "value": "Kinmoku" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Adventure" }
    ]
  },
  {
    "name": "Far Cry 5",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Rayman Legends",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Montpellier" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Grand Theft Auto V",
    "hints": [
      { "key": "Developer", "value": "Rockstar North" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Alan Wake II",
    "hints": [
      { "key": "Developer", "value": "Remedy Entertainment" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Survival horror" }
    ]
  },
  {
    "name": "Metroid Prime Trilogy",
    "hints": [
      { "key": "Developer", "value": "Retro Studios" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "First-person action-adventure" }
    ]
  },
  {
    "name": "Medal of Honor: Frontline",
    "hints": [
      { "key": "Developer", "value": "EA Los Angeles" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "God of War II",
    "hints": [
      { "key": "Developer", "value": "Santa Monica Studio" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Tomb Raider (1996)",
    "hints": [
      { "key": "Developer", "value": "Core Design" },
      { "key": "Release year", "value": "1996" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Far Cry 3",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Superbike 2001",
    "hints": [
      { "key": "Developer", "value": "Milestone" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Rock Band",
    "hints": [
      { "key": "Developer", "value": "Harmonix" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Rhythm" }
    ]
  },
  {
    "name": "Pikmin 2",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "Fallout: New Vegas",
    "hints": [
      { "key": "Developer", "value": "Obsidian Entertainment" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Borderlands 2",
    "hints": [
      { "key": "Developer", "value": "Gearbox Software" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "New Super Mario Bros.",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Resident Evil Code: Veronica",
    "hints": [
      { "Developer": "Capcom" },
      { "Release year": "2000" },
      { "Genre": "Survival horror" }
    ]
  },
  {
    "name": "Far Cry 6",
    "hints": [
      { "Developer": "Ubisoft Toronto" },
      { "Release year": "2021" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "Guild Wars",
    "hints": [
      { "Developer": "ArenaNet" },
      { "Release year": "2005" },
      { "Genre": "MMORPG" }
    ]
  },
  {
    "name": "Apex Legends",
    "hints": [
      { "Developer": "Respawn Entertainment" },
      { "Release year": "2019" },
      { "Genre": "Battle royale" }
    ]
  },
  {
    "name": "Tetris Effect: Connected",
    "hints": [
      { "Developer": "Monstars Inc." },
      { "Release year": "2020" },
      { "Genre": "Puzzle" }
    ]
  },
  {
    "name": "Empire: Total War",
    "hints": [
      { "Developer": "Creative Assembly" },
      { "Release year": "2009" },
      { "Genre": "Real-time strategy" }
    ]
  },
  {
    "name": "Mario Tennis",
    "hints": [
      { "Developer": "Camelot Software Planning" },
      { "Release year": "2000" },
      { "Genre": "Sports" }
    ]
  },
  {
    "name": "Devil May Cry",
    "hints": [
      { "Developer": "Capcom" },
      { "Release year": "2001" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Warframe",
    "hints": [
      { "Developer": "Digital Extremes" },
      { "Release year": "2013" },
      { "Genre": "Action role-playing" }
    ]
  },
  {
    "name": "God of War",
    "hints": [
      { "Developer": "Santa Monica Studio" },
      { "Release year": "2005" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Tony Hawk's Pro Skater 4",
    "hints": [
      { "Developer": "Neversoft" },
      { "Release year": "2002" },
      { "Genre": "Sports" }
    ]
  },
  {
    "name": "Eternal Darkness: Sanity's Requiem",
    "hints": [
      { "Developer": "Silicon Knights" },
      { "Release year": "2002" },
      { "Genre": "Survival horror" }
    ]
  },
  {
    "name": "Resistance 2",
    "hints": [
      { "Developer": "Insomniac Games" },
      { "Release year": "2008" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "Octopath Traveler",
    "hints": [
      { "Developer": "Square Enix" },
      { "Release year": "2018" },
      { "Genre": "Role-playing" }
    ]
  },
  {
    "name": "Dead Space 2",
    "hints": [
      { "Developer": "Visceral Games" },
      { "Release year": "2011" },
      { "Genre": "Survival horror" }
    ]
  },
  {
    "name": "Hollow Knight",
    "hints": [
      { "Developer": "Team Cherry" },
      { "Release year": "2017" },
      { "Genre": "Metroidvania" }
    ]
  },
  {
    "name": "Dishonored",
    "hints": [
      { "Developer": "Arkane Studios" },
      { "Release year": "2012" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Crysis 3",
    "hints": [
      { "Developer": "Crytek" },
      { "Release year": "2013" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "Street Fighter 6",
    "hints": [
      { "Developer": "Capcom" },
      { "Release year": "2023" },
      { "Genre": "Fighting" }
    ]
  },
  {
    "name": "BioShock Infinite",
    "hints": [
      { "Developer": "Irrational Games" },
      { "Release year": "2013" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "The Sims",
    "hints": [
      { "Developer": "Maxis" },
      { "Release year": "2000" },
      { "Genre": "Life simulation" }
    ]
  },
  {
    "name": "The Walking Dead: A Telltale Games Series",
    "hints": [
      { "Developer": "Telltale Games" },
      { "Release year": "2012" },
      { "Genre": "Adventure" }
    ]
  },
  {
    "name": "Tom Clancy's Splinter Cell: Chaos Theory",
    "hints": [
      { "Developer": "Ubisoft Montreal" },
      { "Release year": "2005" },
      { "Genre": "Stealth" }
    ]
  },
  {
    "name": "Quake II",
    "hints": [
      { "Developer": "id Software" },
      { "Release year": "1997" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "System Shock 2",
    "hints": [
      { "Developer": "Irrational Games" },
      { "Release year": "1999" },
      { "Genre": "Action role-playing" }
    ]
  },
  {
    "name": "Resident Evil 3: Nemesis",
    "hints": [
      { "Developer": "Capcom" },
      { "Release year": "1999" },
      { "Genre": "Survival horror" }
    ]
  },
  {
    "name": "Descent 3",
    "hints": [
      { "Developer": "Outrage Entertainment" },
      { "Release year": "1999" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "The Legend of Zelda: Ocarina of Time",
    "hints": [
      { "Developer": "Nintendo EAD" },
      { "Release year": "1998" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Baldur's Gate II: Shadows of Amn",
    "hints": [
      { "Developer": "BioWare" },
      { "Release year": "2000" },
      { "Genre": "Role-playing" }
    ]
  },
  {
    "name": "Factorio",
    "hints": [
      { "Developer": "Wube Software" },
      { "Release year": "2020" },
      { "Genre": "Simulation" }
    ]
  },
  {
    "name": "Diablo III",
    "hints": [
      { "Developer": "Blizzard Entertainment" },
      { "Release year": "2012" },
      { "Genre": "Action role-playing" }
    ]
  },
  {
    "name": "Spyro: Year of the Dragon",
    "hints": [
      { "Developer": "Insomniac Games" },
      { "Release year": "2000" },
      { "Genre": "Platform" }
    ]
  },
  {
    "name": "Medal of Honor (1999)",
    "hints": [
      { "Developer": "DreamWorks Interactive" },
      { "Release year": "1999" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "Mario Kart 8",
    "hints": [
      { "Developer": "Nintendo EAD" },
      { "Release year": "2014" },
      { "Genre": "Racing" }
    ]
  },
  {
    "name": "Left 4 Dead 2",
    "hints": [
      { "Developer": "Valve" },
      { "Release year": "2009" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "Sea of Thieves",
    "hints": [
      { "Developer": "Rare" },
      { "Release year": "2018" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Disco Elysium",
    "hints": [
      { "Developer": "ZA/UM" },
      { "Release year": "2019" },
      { "Genre": "Role-playing" }
    ]
  },
  {
    "name": "Nobody Saves the World",
    "hints": [
      { "Developer": "DrinkBox Studios" },
      { "Release year": "2022" },
      { "Genre": "Action role-playing" }
    ]
  },
  {
    "name": "Deus Ex: Human Revolution",
    "hints": [
      { "Developer": "Eidos Montreal" },
      { "Release year": "2011" },
      { "Genre": "Action role-playing" }
    ]
  },
  {
    "name": "Devil May Cry 3",
    "hints": [
      { "Developer": "Capcom" },
      { "Release year": "2005" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Legacy of Kain: Soul Reaver",
    "hints": [
      { "Developer": "Crystal Dynamics" },
      { "Release year": "1999" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Terraria",
    "hints": [
      { "Developer": "Re-Logic" },
      { "Release year": "2011" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Psychonauts",
    "hints": [
      { "Developer": "Double Fine Productions" },
      { "Release year": "2005" },
      { "Genre": "Platform" }
    ]
  },
  {
    "name": "L.A. Noire",
    "hints": [
      { "Developer": "Team Bondi" },
      { "Release year": "2011" },
      { "Genre": "Adventure" }
    ]
  },
  {
    "name": "Red Dead Redemption",
    "hints": [
      { "Developer": "Rockstar San Diego" },
      { "Release year": "2010" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Tekken 3",
    "hints": [
      { "Developer": "Namco" },
      { "Release year": "1997" },
      { "Genre": "Fighting" }
    ]
  },
  {
    "name": "Diablo II",
    "hints": [
      { "Developer": "Blizzard North" },
      { "Release year": "2000" },
      { "Genre": "Action role-playing" }
    ]
  },
  {
    "name": "Crysis",
    "hints": [
      { "Developer": "Crytek" },
      { "Release year": "2007" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "Metroid Dread",
    "hints": [
      { "Developer": "MercurySteam" },
      { "Release year": "2021" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Rock Band 3",
    "hints": [
      { "Developer": "Harmonix" },
      { "Release year": "2010" },
      { "Genre": "Rhythm" }
    ]
  },
  {
    "name": "Half-Life 2",
    "hints": [
      { "Developer": "Valve" },
      { "Release year": "2004" },
      { "Genre": "First-person shooter" }
    ]
  },
  {
    "name": "Final Fantasy X",
    "hints": [
      { "Developer": "Square" },
      { "Release year": "2001" },
      { "Genre": "Role-playing" }
    ]
  },
  {
    "name": "Metal Gear Solid 4: Guns of the Patriots",
    "hints": [
      { "Developer": "Kojima Productions" },
      { "Release year": "2008" },
      { "Genre": "Action-adventure" }
    ]
  },
  {
    "name": "Age of Empires",
    "hints": [
      { "Developer": "Ensemble Studios" },
      { "Release year": "1997" },
      { "Genre": "Real-time strategy" }
    ]
  },
  {
    "name": "Left 4 Dead",
    "hints": [
      { "key": "Developer", "value": "Valve" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Shovel Knight: Treasure Trove",
    "hints": [
      { "key": "Developer", "value": "Yacht Club Games" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Shadow of the Tomb Raider",
    "hints": [
      { "key": "Developer", "value": "Eidos-Montréal" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Animal Crossing: New Horizons",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Life simulation" }
    ]
  },
  {
    "name": "Halo: Reach",
    "hints": [
      { "key": "Developer", "value": "Bungie" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Ratchet & Clank: Going Commando",
    "hints": [
      { "key": "Developer", "value": "Insomniac Games" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Hades",
    "hints": [
      { "key": "Developer", "value": "Supergiant Games" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Roguelike" }
    ]
  },
  {
    "name": "Balatro",
    "hints": [
      { "key": "Developer", "value": "TBA" },
      { "key": "Release year", "value": "TBA" },
      { "key": "Genre", "value": "TBA" }
    ]
  },
  {
    "name": "Cave Story",
    "hints": [
      { "key": "Developer", "value": "Studio Pixel" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Metroidvania" }
    ]
  },
  {
    "name": "Final Fantasy IX",
    "hints": [
      { "key": "Developer", "value": "Square" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Halo 2",
    "hints": [
      { "key": "Developer", "value": "Bungie" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Conker's Bad Fur Day",
    "hints": [
      { "key": "Developer", "value": "Rare" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Mass Effect",
    "hints": [
      { "key": "Developer", "value": "BioWare" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Devil May Cry 4",
    "hints": [
      { "key": "Developer", "value": "Capcom" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Wipeout Pure",
    "hints": [
      { "key": "Developer", "value": "Studio Liverpool" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Wipeout HD Fury",
    "hints": [
      { "key": "Developer", "value": "Studio Liverpool" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Sid Meier's Civilization III",
    "hints": [
      { "key": "Developer", "value": "Firaxis Games" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Turn-based strategy" }
    ]
  },
  {
    "name": "Lone Echo",
    "hints": [
      { "key": "Developer", "value": "Ready at Dawn" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Adventure" }
    ]
  },
  {
    "name": "Total War: Shogun 2",
    "hints": [
      { "key": "Developer", "value": "Creative Assembly" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "Forza Horizon 5",
    "hints": [
      { "key": "Developer", "value": "Playground Games" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Streets of Rage 4: Mr. X Nightmare",
    "hints": [
      { "key": "Developer", "value": "Dotemu" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Beat 'em up" }
    ]
  },
  {
    "name": "Killzone 3",
    "hints": [
      { "key": "Developer", "value": "Guerrilla Games" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Lego Star Wars: The Skywalker Saga",
    "hints": [
      { "key": "Developer", "value": "Traveller's Tales" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Final Fantasy VIII",
    "hints": [
      { "key": "Developer", "value": "Square" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Tom Clancy's Rainbow Six: Vegas",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Max Payne",
    "hints": [
      { "key": "Developer", "value": "Remedy Entertainment" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Third-person shooter" }
    ]
  },
  {
    "name": "Borderlands 3",
    "hints": [
      { "key": "Developer", "value": "Gearbox Software" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Crusader Kings III",
    "hints": [
      { "key": "Developer", "value": "Paradox Development Studio" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Grand strategy" }
    ]
  },
  {
    "name": "Half-Life: Alyx",
    "hints": [
      { "key": "Developer", "value": "Valve" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "World of Warcraft: Cataclysm",
    "hints": [
      { "key": "Developer", "value": "Blizzard Entertainment" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "MMORPG" }
    ]
  },
  {
    "name": "It Takes Two",
    "hints": [
      { "key": "Developer", "value": "Hazelight Studios" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Guitar Hero II",
    "hints": [
      { "key": "Developer", "value": "Harmonix" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Music" }
    ]
  },
  {
    "name": "Okami HD",
    "hints": [
      { "key": "Developer", "value": "Clover Studio" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Einhander",
    "hints": [
      { "key": "Developer", "value": "Square" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Scrolling shooter" }
    ]
  },
  {
    "name": "Tony Hawk's Pro Skater 2",
    "hints": [
      { "key": "Developer", "value": "Neversoft" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Sports" }
    ]
  },
  {
    "name": "Medal of Honor: Allied Assault",
    "hints": [
      { "key": "Developer", "value": "2015, Inc." },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Halo 3",
    "hints": [
      { "key": "Developer", "value": "Bungie" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Diddy Kong Racing",
    "hints": [
      { "key": "Developer", "value": "Rare" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "No One Lives Forever 2: A Spy in H.A.R.M.'s Way",
    "hints": [
      { "key": "Developer", "value": "Monolith Productions" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Metroid Prime",
    "hints": [
      { "key": "Developer", "value": "Retro Studios" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Slay the Spire",
    "hints": [
      { "key": "Developer", "value": "MegaCrit" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Roguelike deck-building" }
    ]
  },
  {
    "name": "Destiny 2",
    "hints": [
      { "key": "Developer", "value": "Bungie" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Batman: Arkham Asylum",
    "hints": [
      { "key": "Developer", "value": "Rocksteady Studios" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Metroid Prime 3: Corruption",
    "hints": [
      { "key": "Developer", "value": "Retro Studios" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Metal Gear Solid 3: Snake Eater",
    "hints": [
      { "key": "Developer", "value": "Konami" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Guild Wars 2",
    "hints": [
      { "key": "Developer", "value": "ArenaNet" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "MMORPG" }
    ]
  },
  {
    "name": "Final Fantasy XII",
    "hints": [
      { "key": "Developer", "value": "Square Enix" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Call of Duty: Modern Warfare 3",
    "hints": [
      { "key": "Developer", "value": "Infinity Ward" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "The Secret of Monkey Island: Special Edition",
    "hints": [
      { "key": "Developer", "value": "LucasArts" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Adventure" }
    ]
  },
  {
    "name": "Tactics Ogre: The Knight of Lodis",
    "hints": [
      { "key": "Developer", "value": "Quest" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Tactical role-playing" }
    ]
  },
  {
    "name": "The Sims 4",
    "hints": [
      { "key": "Developer", "value": "Maxis" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Life simulation" }
    ]
  },
  {
    "name": "Deathloop",
    "hints": [
      { "key": "Developer", "value": "Arkane Studios" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Marvel's Spider-Man 2",
    "hints": [
      { "key": "Developer", "value": "Insomniac Games" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Dragon Quest VIII: Journey of the Cursed King",
    "hints": [
      { "key": "Developer", "value": "Level-5" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Wipeout 3",
    "hints": [
      { "key": "Developer", "value": "Psygnosis" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "The Sims 2",
    "hints": [
      { "key": "Developer", "value": "Maxis" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Life simulation" }
    ]
  },
  {
    "name": "BioShock",
    "hints": [
      { "key": "Developer", "value": "2K Boston" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "The Legend of Zelda: Majora's Mask",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Grim Fandango",
    "hints": [
      { "key": "Developer", "value": "LucasArts" },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "Adventure" }
    ]
  },
  {
    "name": "Ratchet & Clank: Up Your Arsenal",
    "hints": [
      { "key": "Developer", "value": "Insomniac Games" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Mass Effect 3",
    "hints": [
      { "key": "Developer", "value": "BioWare" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Super Mario Odyssey",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "Minecraft",
    "hints": [
      { "key": "Developer", "value": "Mojang" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Sandbox" }
    ]
  },
  {
    "name": "The Legend of Zelda: The Wind Waker",
    "hints": [
      { "key": "Developer", "value": "Nintendo" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Battlefield 1942",
    "hints": [
      { "key": "Developer", "value": "DICE" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Sid Meier's Civilization II",
    "hints": [
      { "key": "Developer", "value": "MicroProse" },
      { "key": "Release year", "value": "1996" },
      { "key": "Genre", "value": "Turn-based strategy" }
    ]
  },
  {
    "name": "Far Cry 2",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Escape from Tarkov",
    "hints": [
      { "key": "Developer", "value": "Battlestate Games" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Crysis 2",
    "hints": [
      { "key": "Developer", "value": "Crytek" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Age of Empires III",
    "hints": [
      { "key": "Developer", "value": "Ensemble Studios" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "Okami",
    "hints": [
      { "key": "Developer", "value": "Clover Studio" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Gears of War",
    "hints": [
      { "key": "Developer", "value": "Epic Games" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Third-person shooter" }
    ]
  },
  {
    "name": "Tekken 5",
    "hints": [
      { "key": "Developer", "value": "Namco" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Fighting" }
    ]
  },
  {
    "name": "The Elder Scrolls V: Skyrim",
    "hints": [
      { "key": "Developer", "value": "Bethesda Game Studios" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Super Smash Bros. Melee",
    "hints": [
      { "key": "Developer", "value": "HAL Laboratory" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Fighting" }
    ]
  },
  {
    "name": "Titanfall 2",
    "hints": [
      { "key": "Developer", "value": "Respawn Entertainment" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Xenoblade Chronicles 3",
    "hints": [
      { "key": "Developer", "value": "Monolith Soft" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Excitebike 64",
    "hints": [
      { "key": "Developer", "value": "Left Field Productions" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "Call of Duty 2",
    "hints": [
      { "key": "Developer", "value": "Infinity Ward" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Death Stranding",
    "hints": [
      { "key": "Developer", "value": "Kojima Productions" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Action" }
    ]
  },
  {
    "name": "Mario Kart DS",
    "hints": [
      { "key": "Developer", "value": "Nintendo EAD" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Racing" }
    ]
  },
  {
    "name": "World of Warcraft",
    "hints": [
      { "key": "Developer", "value": "Blizzard Entertainment" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "MMORPG" }
    ]
  },
  {
    "name": "World of Warcraft: The Burning Crusade",
    "hints": [
      { "key": "Developer", "value": "Blizzard Entertainment" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "MMORPG" }
    ]
  },
  {
    "name": "NieR: Automata",
    "hints": [
      { "key": "Developer", "value": "PlatinumGames" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Fable III",
    "hints": [
      { "key": "Developer", "value": "Lionhead Studios" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Metal Gear Solid V: The Phantom Pain",
    "hints": [
      { "key": "Developer", "value": "Kojima Productions" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Advance Wars 2: Black Hole Rising",
    "hints": [
      { "key": "Developer", "value": "Intelligent Systems" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Turn-based tactics" }
    ]
  },
  {
    "name": "Halo: Combat Evolved",
    "hints": [
      { "key": "Developer", "value": "Bungie" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Wasteland 3",
    "hints": [
      { "key": "Developer", "value": "inXile Entertainment" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Medieval: Total War",
    "hints": [
      { "key": "Developer", "value": "Creative Assembly" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Turn-based strategy, Real-time tactics" }
    ]
  },
  {
    "name": "Fight Night Round 2",
    "hints": [
      { "key": "Developer", "value": "EA Sports" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Sports" }
    ]
  },
  {
    "name": "Mario & Luigi: Superstar Saga",
    "hints": [
      { "key": "Developer", "value": "AlphaDream" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Crusader Kings II: The Old Gods",
    "hints": [
      { "key": "Developer", "value": "Paradox Development Studio" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Grand strategy" }
    ]
  },
  {
    "name": "Far Cry 4",
    "hints": [
      { "key": "Developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "ICO",
    "hints": [
      { "key": "Developer", "value": "Team Ico" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "The Last of Us",
    "hints": [
      { "key": "Developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Action-adventure, Survival horror" }
    ]
  },
  {
    "name": "Demon's Souls",
    "hints": [
      { "key": "Developer", "value": "FromSoftware" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Monster Hunter: World",
    "hints": [
      { "key": "Developer", "value": "Capcom" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "TimeSplitters",
    "hints": [
      { "key": "Developer", "value": "Free Radical Design" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Uncharted 2: Among Thieves",
    "hints": [
      { "key": "Developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Action-adventure, Third-person shooter" }
    ]
  },
  {
    "name": "Klonoa 2: Lunatea's Veil",
    "hints": [
      { "key": "Developer", "value": "Namco" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Platform" }
    ]
  },
  {
    "name": "The Last of Us Part II",
    "hints": [
      { "key": "Developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Action-adventure, Survival horror" }
    ]
  },
  {
    "name": "Quake III Arena",
    "hints": [
      { "key": "Developer", "value": "id Software" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Command & Conquer: Red Alert",
    "hints": [
      { "key": "Developer", "value": "Westwood Studios" },
      { "key": "Release year", "value": "1996" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "StarCraft II: Wings of Liberty",
    "hints": [
      { "key": "Developer", "value": "Blizzard Entertainment" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Real-time strategy" }
    ]
  },
  {
    "name": "God of War: Ragnarok",
    "hints": [
      { "key": "Developer", "value": "Santa Monica Studio" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Vagrant Story",
    "hints": [
      { "key": "Developer", "value": "Square" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Dark Souls II",
    "hints": [
      { "key": "Developer", "value": "FromSoftware" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Persona 5",
    "hints": [
      { "key": "Developer", "value": "Atlus" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Subnautica",
    "hints": [
      { "key": "Developer", "value": "Unknown Worlds Entertainment" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Adventure, Survival" }
    ]
  },
  {
    "name": "Horizon Zero Dawn",
    "hints": [
      { "key": "Developer", "value": "Guerrilla Games" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Fable II",
    "hints": [
      { "key": "Developer", "value": "Lionhead Studios" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Action role-playing" }
    ]
  },
  {
    "name": "Uncharted: Drake's Fortune",
    "hints": [
      { "key": "Developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Action-adventure, Third-person shooter" }
    ]
  },
  {
    "name": "Sid Meier's Gettysburg!",
    "hints": [
      { "key": "Developer", "value": "Firaxis Games" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Strategy" }
    ]
  },
  {
    "name": "Resistance 3",
    "hints": [
      { "key": "Developer", "value": "Insomniac Games" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Resident Evil 3",
    "hints": [
      { "key": "developer", "value": "Capcom" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "survival horror" }
    ]
  },
  {
    "name": "Crash Bandicoot: Warped",
    "hints": [
      { "key": "developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "platformer" }
    ]
  },
  {
    "name": "Colony Wars",
    "hints": [
      { "key": "developer", "value": "Psygnosis" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "space combat simulation" }
    ]
  },
  {
    "name": "Rez Infinite",
    "hints": [
      { "key": "developer", "value": "Enhance Games" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "rhythm shooter" }
    ]
  },
  {
    "name": "Star Fox 64",
    "hints": [
      { "key": "developer", "value": "Nintendo EAD" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "rail shooter" }
    ]
  },
  {
    "name": "Meteos",
    "hints": [
      { "key": "developer", "value": "Q Entertainment" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "puzzle" }
    ]
  },
  {
    "name": "Ratchet & Clank Future: Tools of Destruction",
    "hints": [
      { "key": "developer", "value": "Insomniac Games" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Super Mario World",
    "hints": [
      { "key": "developer", "value": "Nintendo EAD" },
      { "key": "Release year", "value": "1990" },
      { "key": "Genre", "value": "Platformer" }
    ]
  },
  {
    "name": "Geometry Wars: Retro Evolved 2",
    "hints": [
      { "key": "developer", "value": "Bizarre Creations" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "twin-stick shooter" }
    ]
  },
  {
    "name": "XCOM 2: War of the Chosen",
    "hints": [
      { "key": "developer", "value": "Firaxis Games" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Turn-based tactics" }
    ]
  },
  {
    "name": "Dead Cells",
    "hints": [
      { "key": "developer", "value": "Motion Twin" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Roguelike" }
    ]
  },
  {
    "name": "Animal Well",
    "hints": [
      { "key": "developer", "value": "Shared Memory" },
      { "key": "Release year", "value": "2024" },
      { "key": "Genre", "value": "Metroidvania, puzzle" }
    ]
  },
  {
    "name": "Red Dead Redemption 2",
    "hints": [
      { "key": "developer", "value": "Rockstar Games" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Tony Hawk's Pro Skater 3",
    "hints": [
      { "key": "developer", "value": "Neversoft" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Sports" }
    ]
  },
  {
    "name": "Battlefield 2042",
    "hints": [
      { "key": "developer", "value": "DICE" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "First-person shooter" }
    ]
  },
  {
    "name": "Peggle",
    "hints": [
      { "key": "developer", "value": "PopCap Games" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Puzzle" }
    ]
  },
  {
    "name": "Dragon Age: Origins",
    "hints": [
      { "key": "developer", "value": "BioWare" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Role-playing" }
    ]
  },
  {
    "name": "Fire Emblem: Awakening",
    "hints": [
      { "key": "developer", "value": "Intelligent Systems" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Strategy" }
    ]
  },
  {
    "name": "Sid Meier's Civilization IV",
    "hints": [
      { "key": "developer", "value": "Firaxis Games" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Turn-based strategy" }
    ]
  },
  {
    "name": "Grand Theft Auto III",
    "hints": [
      { "key": "developer", "value": "Rockstar North" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "Metroid Prime 2: Echoes",
    "hints": [
      { "key": "developer", "value": "Retro Studios" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Action-adventure" }
    ]
  },
  {
    "name": "World of Warcraft: Wrath of the Lich King",
    "hints": [
      { "key": "developer", "value": "Blizzard Entertainment" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "MMORPG" }
    ]
  },
  {
    "name": "Railroad Tycoon II",
    "hints": [
      { "key": "developer", "value": "PopTop Software" },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "simulation" }
    ]
  },
  {
    "name": "Outer Wilds",
    "hints": [
      { "key": "developer", "value": "Mobius Digital" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "adventure" }
    ]
  },
  {
    "name": "Tom Clancy's Rainbow Six Siege",
    "hints": [
      { "key": "developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "first-person shooter" }
    ]
  },
  {
    "name": "Elden Ring",
    "hints": [
      { "key": "developer", "value": "FromSoftware" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "action role-playing" }
    ]
  },
  {
    "name": "Rogue Legacy 2",
    "hints": [
      { "key": "developer", "value": "Cellar Door Games" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "rogue-lite" }
    ]
  },
  {
    "name": "The Elder Scrolls IV: Oblivion",
    "hints": [
      { "key": "developer", "value": "Bethesda Game Studios" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "action role-playing" }
    ]
  },
  {
    "name": "Assassin's Creed II",
    "hints": [
      { "key": "developer", "value": "Ubisoft Montreal" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "action-adventure" }
    ]
  },
  {
    "name": "Metroid: Zero Mission",
    "hints": [
      { "key": "developer", "value": "Nintendo R&D1" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "action-adventure" }
    ]
  },
  {
    "name": "Chicory: A Colorful Tale",
    "hints": [
      { "key": "developer", "value": "Greg Lobanov" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "adventure" }
    ]
  },
  {
    "name": "Planescape: Torment",
    "hints": [
      { "key": "developer", "value": "Black Isle Studios" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "role-playing" }
    ]
  },
  {
    "name": "Gunstar Heroes",
    "hints": [
      { "key": "developer", "value": "Treasure" },
      { "key": "Release year", "value": "1993" },
      { "key": "Genre", "value": "run and gun" }
    ]
  },
  {
    "name": "Jak and Daxter: The Precursor Legacy",
    "hints": [
      { "key": "developer", "value": "Naughty Dog" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "platformer" }
    ]
  },
  {
    "name": "Persona 4 Golden",
    "hints": [
      { "key": "developer", "value": "Atlus" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "role-playing" }
    ]
  },
  {
    "name": "The Longest Journey",
    "hints": [
      { "key": "developer", "value": "Funcom" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "adventure" }
    ]
  },
  {
    "name": "Nuclear Throne",
    "hints": [
      { "key": "developer", "value": "Vlambeer" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "rogue-lite" }
    ]
  },
  {
    "name": "Homeworld: Cataclysm",
    "hints": [
      { "key": "developer", "value": "Barking Dog Studios" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "real-time strategy" }
    ]
  },
  {
    "name": "Panzer Dragoon Orta",
    "hints": [
      { "key": "developer", "value": "Smilebit" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "rail shooter" }
    ]
  },
  {
    "name": "Dota 2",
    "hints": [
      { "key": "developer", "value": "Valve Corporation" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "MOBA" }
    ]
  },
  {
    "name": "Call of Duty 4: Modern Warfare",
    "hints": [
      { "key": "developer", "value": "Infinity Ward" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "first-person shooter" }
    ]
  },
  {
    "name": "TimeSplitters 2",
    "hints": [
      { "key": "developer", "value": "Free Radical Design" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "first-person shooter" }
    ]
  },
  {
    "name": "Dark Souls: Artorias of the Abyss",
    "hints": [
      { "key": "developer", "value": "FromSoftware" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "action role-playing" }
    ]
  },
  {
    "name": "Silent Hill 2 (2001)",
    "hints": [
      { "key": "developer", "value": "Konami" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "survival horror" }
    ]
  },
  {
    "name": "Sid Meier's Civilization V",
    "hints": [
      { "key": "developer", "value": "Firaxis Games" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "turn-based strategy" }
    ]
  },
  {
    "name": "The Witcher 3: Wild Hunt",
    "hints": [
      { "key": "developer", "value": "CD Projekt Red" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "action role-playing" }
    ]
  },
  {
    "name": "Thief: The Dark Project",
    "hints": [
      { "key": "developer", "value": "Looking Glass Studios" },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "stealth" }
    ]
  },
  {
    "name": "Forza Horizon 4",
    "hints": [
      { "key": "developer", "value": "Playground Games" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "racing" }
    ]
  },
  {
    "name": "Titanfall",
    "hints": [
      { "key": "developer", "value": "Respawn Entertainment" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "first-person shooter" }
    ]
  },
  {
    "name": "Psychonauts 2",
    "hints": [
      { "key": "developer", "value": "Double Fine Productions" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "platformer" }
    ]
  },
  {
    "name": "Call of Duty: Modern Warfare 2",
    "hints": [
      { "key": "developer", "value": "Infinity Ward" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "first-person shooter" }
    ]
  },
  {
    "name": "Combat Mission: Barbarossa to Berlin",
    "hints": [
      { "key": "developer", "value": "Battlefront.com" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "turn-based strategy" }
    ]
  },
  {
    "name": "Soul Edge",
    "hints": [
      { "key": "developer", "value": "Project Soul" },
      { "key": "Release year", "value": "1995" },
      { "key": "Genre", "value": "fighting" }
    ]
  },
  {
    "name": "Phantasy Star Online",
    "hints": [
      { "key": "developer", "value": "Sonic Team" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "action role-playing" }
    ]
  },
  {
    "name": "Tony Hawk's Underground",
    "hints": [
      { "key": "developer", "value": "Neversoft" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "sports" }
    ]
  },
  {
    "name": "Advance Wars",
    "hints": [
      { "key": "developer", "value": "Intelligent Systems" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "turn-based strategy" }
    ]
  },
  {
    "name": "Bloodborne",
    "hints": [
      { "key": "developer", "value": "FromSoftware" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "action role-playing" }
    ]
  },
  {
    "name": "Metro Exodus",
    "hints": [
      { "key": "developer", "value": "4A Games" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "first-person shooter" }
    ]
  },
  {
    "name": "Donkey Kong 64",
    "hints": [
      { "key": "developer", "value": "Rare" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "platformer" }
    ]
  },
  {
    "name": "Sekiro: Shadows Die Twice",
    "hints": [
      { "key": "developer", "value": "FromSoftware" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "action-adventure" }
    ]
  },
  {
    "name": "Evil Dead: The Game",
    "hints": [
      { "key": "developer", "value": "Boss Team Games" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "survival horror" }
    ]
  },
  {
    "name": "Counter-Strike: Global Offensive",
    "hints": [
      { "key": "developer", "value": "Valve Corporation" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "first-person shooter" }
    ]
  },
  {
    "name": "Homeworld",
    "hints": [
      { "key": "developer", "value": "Relic Entertainment" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "real-time strategy" }
    ]
  },
  {
    "name": "Nioh 2",
    "hints": [
      { "key": "developer", "value": "Team Ninja" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "action role-playing" }
    ]
  },
]