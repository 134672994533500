export default function Privacy() {
  return (
    <div style={{ marginBottom: '10px' }}>
      <h1>Privacy Policy</h1>

      <h3>Data we collect</h3>

      <p>We don't collect any personal data. We do use Cookies.</p>

      <h3>What Are Cookies?</h3>

      <p>Cookies are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>

      <h3>How We Use Cookies</h3>

      <p>We use cookies to improve the functionality and experience of our game. Without them, we wouldn't be able to save your progress across browser sessions.</p>

      <h3>Disabling Cookies</h3>

      <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>

      <h3>The Cookies We Set</h3>

      <p><b>Game state cookies</b></p>

      <p>When you start playing, we use a cookie to keep track of those guesses and if you've won, lost or still have guesses remaining.</p>

    </div>
  )
}