export const MOVIE_ANSWERS = [
  {
    "name": "Kiki's Delivery Service",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hayao Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$41.8 million"
      },
      {
        "key": "Release date",
        "value": "July 29, 1989"
      }
    ]
  },
  {
    "name": "North by Northwest",
    "hints": [
      {
        "key": "Directed by",
        "value": "Alfred Hitchcock"
      },
      {
        "key": "Box office",
        "value": "$9.8 million"
      },
      {
        "key": "Release date",
        "value": "July 1, 1959"
      }
    ]
  },
  {
    "name": "Spartacus",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stanley Kubrick"
      },
      {
        "key": "Box office",
        "value": "$60 million "
      },
      {
        "key": "Release date",
        "value": "October 6, 1960"
      }
    ]
  },
  {
    "name": "Avatar",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Cameron"
      },
      {
        "key": "Box office",
        "value": "$2.320 billion"
      },
      {
        "key": "Release date",
        "value": "December 6, 2022"
      }
    ]
  },
  {
    "name": "The Wild Bunch",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sam Peckinpah"
      },
      {
        "key": "Box office",
        "value": "$11 million"
      },
      {
        "key": "Release date",
        "value": "June 18, 1969"
      }
    ]
  },
  {
    "name": "The Tale of the Princess Kaguya",
    "hints": [
      {
        "key": "Directed by",
        "value": "Isao Takahata"
      },
      {
        "key": "Box office",
        "value": "$27 million"
      },
      {
        "key": "Release date",
        "value": "23 November 2013"
      }
    ]
  },
  {
    "name": "How to Train Your Dragon 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Dean DeBlois"
      },
      {
        "key": "Box office",
        "value": "$621.5 million"
      },
      {
        "key": "Release date",
        "value": "May 16, 2014"
      }
    ]
  },
  {
    "name": "Spider-Man: Into the Spider-Verse",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joaquim Dos Santos, Kemp Powers, Justin K. Thompson"
      },
      {
        "key": "Box office",
        "value": "$690.9million"
      },
      {
        "key": "Release date",
        "value": "May 30, 2023"
      }
    ]
  },
  {
    "name": "The Aristocats",
    "hints": [
      {
        "key": "Directed by",
        "value": "Wolfgang Reitherman"
      },
      {
        "key": "Box office",
        "value": "$191 million"
      },
      {
        "key": "Release date",
        "value": "December 11, 1970"
      }
    ]
  },
  {
    "name": "Logan",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Mangold"
      },
      {
        "key": "Box office",
        "value": "$619.2 million"
      },
      {
        "key": "Release date",
        "value": "February 17, 2017"
      }
    ]
  },
  {
    "name": "Kubo and the Two Strings",
    "hints": [
      {
        "key": "Directed by",
        "value": "Travis Knight"
      },
      {
        "key": "Box office",
        "value": "$77.5 million"
      },
      {
        "key": "Release date",
        "value": "August 13, 2016"
      }
    ]
  },
  {
    "name": "Logan's Run",
    "hints": [
      {
        "key": "Directed by",
        "value": "Michael Anderson"
      },
      {
        "key": "Box office",
        "value": "$25 million "
      },
      {
        "key": "Release date",
        "value": "June 23, 1976"
      }
    ]
  },
  {
    "name": "Inside Out",
    "hints": [
      {
        "key": "Directed by",
        "value": "Pete Docter"
      },
      {
        "key": "Box office",
        "value": "$858.8million"
      },
      {
        "key": "Release date",
        "value": "May 18, 2015"
      }
    ]
  },
  {
    "name": "Pulp Fiction",
    "hints": [
      {
        "key": "Directed by",
        "value": "Quentin Tarantino"
      },
      {
        "key": "Box office",
        "value": "$213.9 million"
      },
      {
        "key": "Release date",
        "value": "May 21, 1994"
      }
    ]
  },
  {
    "name": "The Addams Family",
    "hints": [
      {
        "key": "Directed by",
        "value": "Barry Sonnenfeld"
      },
      {
        "key": "Box office",
        "value": "$191.5 million"
      },
      {
        "key": "Release date",
        "value": "November 22, 1991"
      }
    ]
  },
  {
    "name": "The Outlaw Josey Wales",
    "hints": [
      {
        "key": "Directed by",
        "value": "Clint Eastwood"
      },
      {
        "key": "Box office",
        "value": "$31.8 million"
      },
      {
        "key": "Release date",
        "value": "June 30, 1976"
      }
    ]
  },
  {
    "name": "The Lord of the Rings: The Two Towers",
    "hints": [
      {
        "key": "Directed by",
        "value": "Peter Jackson"
      },
      {
        "key": "Box office",
        "value": "$951.6 million"
      },
      {
        "key": "Release date",
        "value": "December, 5th 2002"
      }
    ]
  },
  {
    "name": "The Graduate",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mike Nichols"
      },
      {
        "key": "Box office",
        "value": "$104.9 million"
      },
      {
        "key": "Release date",
        "value": "December 20, 1967"
      }
    ]
  },
  {
    "name": "The Terminator",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Cameron"
      },
      {
        "key": "Box office",
        "value": "$78.3 million"
      },
      {
        "key": "Release date",
        "value": "October 26, 1984"
      }
    ]
  },
  {
    "name": "The Fox and the Hound",
    "hints": [
      {
        "key": "Directed by",
        "value": "Ted Berman, Richard Rich, Art Stevens"
      },
      {
        "key": "Box office",
        "value": "$63.5 million"
      },
      {
        "key": "Release date",
        "value": "July 10, 1981"
      }
    ]
  },
  {
    "name": "Frozen",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris BuckJennifer Lee"
      },
      {
        "key": "Box office",
        "value": "$1.280 billion"
      },
      {
        "key": "Release date",
        "value": "November 19, 2013"
      }
    ]
  },
  {
    "name": "Rock-a-Doodle",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Bluth"
      },
      {
        "key": "Box office",
        "value": "$11.6 million"
      },
      {
        "key": "Release date",
        "value": "2 August 1991"
      }
    ]
  },
  {
    "name": "Alice in Wonderland",
    "hints": [
      {
        "key": "Directed by",
        "value": "Tim Burton"
      },
      {
        "key": "Box office",
        "value": "$1.025 billion"
      },
      {
        "key": "Release date",
        "value": "February 25, 2010"
      }
    ]
  },
  {
    "name": "WarGames",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Badham"
      },
      {
        "key": "Box office",
        "value": "$124.6 million"
      },
      {
        "key": "Release date",
        "value": "May 7, 1983"
      }
    ]
  },
  {
    "name": "Despicable Me 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Renaud, Pierre Coffin"
      },
      {
        "key": "Box office",
        "value": "$971 million"
      },
      {
        "key": "Release date",
        "value": "June 5, 2013"
      }
    ]
  },
  {
    "name": "Dunkirk",
    "hints": [
      {
        "key": "Directed by",
        "value": "Christopher Nolan"
      },
      {
        "key": "Box office",
        "value": "$530.4 million"
      },
      {
        "key": "Release date",
        "value": "13 July 2017"
      }
    ]
  },
  {
    "name": "Lethal Weapon",
    "hints": [
      {
        "key": "Directed by",
        "value": "Richard Donner"
      },
      {
        "key": "Box office",
        "value": "$120.2 million"
      },
      {
        "key": "Release date",
        "value": "March 6, 1987"
      }
    ]
  },
  {
    "name": "The Day of the Jackal",
    "hints": [
      {
        "key": "Directed by",
        "value": "Fred Zinnemann"
      },
      {
        "key": "Box office",
        "value": "$16,056,255"
      },
      {
        "key": "Release date",
        "value": "16 May 1973"
      }
    ]
  },
  {
    "name": "Harry Potter and the Prisoner of Azkaban",
    "hints": [
      {
        "key": "Directed by",
        "value": "Alfonso Cuaron"
      },
      {
        "key": "Box office",
        "value": "$807.8 million"
      },
      {
        "key": "Release date",
        "value": "23 May 2004"
      }
    ]
  },
  {
    "name": "The Wind Rises",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hayao Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$136.5 million"
      },
      {
        "key": "Release date",
        "value": "20 July 2013"
      }
    ]
  },
  {
    "name": "The Power of the Dog",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jane Campion"
      },
      {
        "key": "Box office",
        "value": "$417,022"
      },
      {
        "key": "Release date",
        "value": "2 September 2021"
      }
    ]
  },
  {
    "name": "Apocalypse Now",
    "hints": [
      {
        "key": "Directed by",
        "value": "Francis Coppola"
      },
      {
        "key": "Box office",
        "value": "$104.8 million"
      },
      {
        "key": "Release date",
        "value": "May 19, 1979"
      }
    ]
  },
  {
    "name": "Monty Python and the Holy Grail",
    "hints": [
      {
        "key": "Directed by",
        "value": "Terry Gilliam, Terry Jones"
      },
      {
        "key": "Box office",
        "value": "5.5 million"
      },
      {
        "key": "Release date",
        "value": "3 April 1975"
      }
    ]
  },
  {
    "name": "Anastasia",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Bluth, Gary Goldman"
      },
      {
        "key": "Box office",
        "value": "$140 million"
      },
      {
        "key": "Release date",
        "value": "November 14, 1997"
      }
    ]
  },
  {
    "name": "Kung Fu Panda 3",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jennifer Yuh Nelson, Alessandro Carloni"
      },
      {
        "key": "Box office",
        "value": "$521.2 million"
      },
      {
        "key": "Release date",
        "value": "January 23, 2016"
      }
    ]
  },
  {
    "name": "A Cat in Paris",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jean-Loup Felicioli, Alain Gagnol"
      },
      {
        "key": "Box office",
        "value": "$3.3 million"
      },
      {
        "key": "Release date",
        "value": "15 October 2010"
      }
    ]
  },
  {
    "name": "Wonder Woman",
    "hints": [
      {
        "key": "Directed by",
        "value": "Patty Jenkins"
      },
      {
        "key": "Box office",
        "value": "$824million"
      },
      {
        "key": "Release date",
        "value": "May 26, 2017"
      }
    ]
  },
  {
    "name": "Ponyo",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hayao Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$204.8 million"
      },
      {
        "key": "Release date",
        "value": "July 19, 2008"
      }
    ]
  },
  {
    "name": "The Texas Chain Saw Massacre",
    "hints": [
      {
        "key": "Directed by",
        "value": "Tobe Hooper"
      },
      {
        "key": "Box office",
        "value": "$30.9 million"
      },
      {
        "key": "Release date",
        "value": "October 11, 1974"
      }
    ]
  },
  {
    "name": "Legend",
    "hints": [
      {
        "key": "Directed by",
        "value": "Brian Helgeland"
      },
      {
        "key": "Box office",
        "value": "$43 million"
      },
      {
        "key": "Release date",
        "value": "September 9, 2015"
      }
    ]
  },
  {
    "name": "Barry Lyndon",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stanley Kubrick"
      },
      {
        "key": "Box office",
        "value": "$31.5 million"
      },
      {
        "key": "Release date",
        "value": "18 December 1975"
      }
    ]
  },
  {
    "name": "Batman Begins",
    "hints": [
      {
        "key": "Directed by",
        "value": "Christopher Nolan"
      },
      {
        "key": "Box office",
        "value": "$373.7 million"
      },
      {
        "key": "Release date",
        "value": "May 31, 2005"
      }
    ]
  },
  {
    "name": "Avengers: Endgame",
    "hints": [
      {
        "key": "Directed by",
        "value": "Anthony Russo, Joe Russo"
      },
      {
        "key": "Box office",
        "value": "$2.799 billion"
      },
      {
        "key": "Release date",
        "value": "April 22, 2019"
      }
    ]
  },
  {
    "name": "The Rescuers Down Under",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hendel ButoyMike Gabriel"
      },
      {
        "key": "Box office",
        "value": "$47.4 million"
      },
      {
        "key": "Release date",
        "value": "November 16, 1990"
      }
    ]
  },
  {
    "name": "The Dark Crystal",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jim Henson, Frank Oz"
      },
      {
        "key": "Box office",
        "value": "$41.4 million"
      },
      {
        "key": "Release date",
        "value": "December 13, 1982"
      }
    ]
  },
  {
    "name": "True Grit",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joel Coen, Ethan Coen"
      },
      {
        "key": "Box office",
        "value": "$252.3 million"
      },
      {
        "key": "Release date",
        "value": "December 22, 2010"
      }
    ]
  },
  {
    "name": "Wolf Children",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mamoru Hosoda"
      },
      {
        "key": "Box office",
        "value": "$55 million"
      },
      {
        "key": "Release date",
        "value": "June 25, 2012"
      }
    ]
  },
  {
    "name": "Goodfellas",
    "hints": [
      {
        "key": "Directed by",
        "value": "Martin Scorsese"
      },
      {
        "key": "Box office",
        "value": "$47.1 million"
      },
      {
        "key": "Release date",
        "value": "September 9, 1990"
      }
    ]
  },
  {
    "name": "Mad Max: Fury Road",
    "hints": [
      {
        "key": "Directed by",
        "value": "George Miller"
      },
      {
        "key": "Box office",
        "value": "$380.4 million"
      },
      {
        "key": "Release date",
        "value": "7 May 2015"
      }
    ]
  },
  {
    "name": "The Good Dinosaur",
    "hints": [
      {
        "key": "Directed by",
        "value": "Peter Sohn"
      },
      {
        "key": "Box office",
        "value": "$332.2 million"
      },
      {
        "key": "Release date",
        "value": "November 10, 2015"
      }
    ]
  },
  {
    "name": "Eraserhead",
    "hints": [
      {
        "key": "Directed by",
        "value": "David Lynch"
      },
      {
        "key": "Box office",
        "value": "$7.1 million"
      },
      {
        "key": "Release date",
        "value": "March 19, 1977"
      }
    ]
  },
  {
    "name": "Finding Dory",
    "hints": [
      {
        "key": "Directed by",
        "value": "Andrew Stanton"
      },
      {
        "key": "Box office",
        "value": "$1.029 billion"
      },
      {
        "key": "Release date",
        "value": "June 8, 2016"
      }
    ]
  },
  {
    "name": "Manhattan",
    "hints": [
      {
        "key": "Directed by",
        "value": "Woody Allen"
      },
      {
        "key": "Box office",
        "value": "$40.2 million"
      },
      {
        "key": "Release date",
        "value": "April 18, 1979"
      }
    ]
  },
  {
    "name": "We're Back! A Dinosaur's Story",
    "hints": [
      {
        "key": "Directed by",
        "value": "Dick Zondag, Ralph Zondag, Phil Nibbelink, Simon Wells"
      },
      {
        "key": "Box office",
        "value": "$9.3 million"
      },
      {
        "key": "Release date",
        "value": "November 24, 1993"
      }
    ]
  },
  {
    "name": "La La Land",
    "hints": [
      {
        "key": "Directed by",
        "value": "Damien Chazelle"
      },
      {
        "key": "Box office",
        "value": "$472 million"
      },
      {
        "key": "Release date",
        "value": "August 31, 2016"
      }
    ]
  },
  {
    "name": "The French Dispatch",
    "hints": [
      {
        "key": "Directed by",
        "value": "Wes Anderson"
      },
      {
        "key": "Box office",
        "value": "$46.3 million"
      },
      {
        "key": "Release date",
        "value": "July 12, 2021"
      }
    ]
  },
  {
    "name": "Coco",
    "hints": [
      {
        "key": "Directed by",
        "value": "Lee Unkrich"
      },
      {
        "key": "Box office",
        "value": "$814.3 million"
      },
      {
        "key": "Release date",
        "value": "October 20, 2017"
      }
    ]
  },
  {
    "name": "My Life as a Zucchini",
    "hints": [
      {
        "key": "Directed by",
        "value": "Claude Barras"
      },
      {
        "key": "Box office",
        "value": "$10.2 million"
      },
      {
        "key": "Release date",
        "value": "15 May 2016"
      }
    ]
  },
  {
    "name": "Ferris Bueller's Day Off",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Hughes"
      },
      {
        "key": "Box office",
        "value": "$70.7 million"
      },
      {
        "key": "Release date",
        "value": "June 11, 1986"
      }
    ]
  },
  {
    "name": "Terminator 2: Judgment Day",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Cameron"
      },
      {
        "key": "Box office",
        "value": "$519 million"
      },
      {
        "key": "Release date",
        "value": "July 1, 1991"
      }
    ]
  },
  {
    "name": "The Godfather",
    "hints": [
      {
        "key": "Directed by",
        "value": "Francis Ford Coppola"
      },
      {
        "key": "Box office",
        "value": "$250 million"
      },
      {
        "key": "Release date",
        "value": "March 14, 1972"
      }
    ]
  },
  {
    "name": "Gremlins",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joe Dante"
      },
      {
        "key": "Box office",
        "value": "$212.9 million"
      },
      {
        "key": "Release date",
        "value": "June 8, 1984"
      }
    ]
  },
  {
    "name": "Toy Story 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Lasseter"
      },
      {
        "key": "Box office",
        "value": "$511.4 million"
      },
      {
        "key": "Release date",
        "value": "November 13, 1999"
      }
    ]
  },
  {
    "name": "Pan's Labyrinth",
    "hints": [
      {
        "key": "Directed by",
        "value": "Guillermo del Toro"
      },
      {
        "key": "Box office",
        "value": "$83.9 million"
      },
      {
        "key": "Release date",
        "value": "27 May 2006"
      }
    ]
  },
  {
    "name": "For a Few Dollars More",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sergio Leone"
      },
      {
        "key": "Box office",
        "value": "$25.5 million"
      },
      {
        "key": "Release date",
        "value": "30 December 1965"
      }
    ]
  },
  {
    "name": "A Clockwork Orange",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stanley Kubrick"
      },
      {
        "key": "Box office",
        "value": "$114 million"
      },
      {
        "key": "Release date",
        "value": "19 December 1971"
      }
    ]
  },
  {
    "name": "Cool Hand Luke",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stuart Rosenberg"
      },
      {
        "key": "Box office",
        "value": "$16.2 million"
      },
      {
        "key": "Release date",
        "value": "October 31, 1967"
      }
    ]
  },
  {
    "name": "Harry Potter and the Goblet of Fire",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mike Newell"
      },
      {
        "key": "Box office",
        "value": "$897.5 million"
      },
      {
        "key": "Release date",
        "value": "6 November 2005"
      }
    ]
  },
  {
    "name": "The Great Mouse Detective",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Musker, Dave Michener, Ron Clements, Burny Mattinson"
      },
      {
        "key": "Box office",
        "value": "$38.7 million"
      },
      {
        "key": "Release date",
        "value": "July 2, 1986"
      }
    ]
  },
  {
    "name": "Giant",
    "hints": [
      {
        "key": "Directed by",
        "value": "George Stevens"
      },
      {
        "key": "Box office",
        "value": "$39 million"
      },
      {
        "key": "Release date",
        "value": "October 10, 1956"
      }
    ]
  },
  {
    "name": "Snow White and the Huntsman",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rupert Sanders"
      },
      {
        "key": "Box office",
        "value": "$396.6 million"
      },
      {
        "key": "Release date",
        "value": "May 30, 2012"
      }
    ]
  },
  {
    "name": "Boogie Nights",
    "hints": [
      {
        "key": "Directed by",
        "value": "Paul Thomas Anderson"
      },
      {
        "key": "Box office",
        "value": "$43.1 million"
      },
      {
        "key": "Release date",
        "value": "September 11, 1997"
      }
    ]
  },
  {
    "name": "The Rescuers",
    "hints": [
      {
        "key": "Directed by",
        "value": "Wolfgang Reitherman, John Lounsbery, Art Stevens"
      },
      {
        "key": "Box office",
        "value": "$169 million"
      },
      {
        "key": "Release date",
        "value": "June 22, 1977"
      }
    ]
  },
  {
    "name": "The Illusionist",
    "hints": [
      {
        "key": "Directed by",
        "value": "Neil Burger"
      },
      {
        "key": "Box office",
        "value": "$87.8 million"
      },
      {
        "key": "Release date",
        "value": "August 18, 2006"
      }
    ]
  },
  {
    "name": "Dead Poets Society",
    "hints": [
      {
        "key": "Directed by",
        "value": "Peter Weir"
      },
      {
        "key": "Box office",
        "value": "$235.9 million"
      },
      {
        "key": "Release date",
        "value": "June 2, 1989"
      }
    ]
  },
  {
    "name": "Rogue One: A Star Wars Story",
    "hints": [
      {
        "key": "Directed by",
        "value": "Gareth Edwards"
      },
      {
        "key": "Box office",
        "value": "$1.058 billion"
      },
      {
        "key": "Release date",
        "value": "December 10, 2016"
      }
    ]
  },
  {
    "name": "Play Misty for Me",
    "hints": [
      {
        "key": "Directed by",
        "value": "Clint Eastwood"
      },
      {
        "key": "Box office",
        "value": "$10.6 million"
      },
      {
        "key": "Release date",
        "value": "October 20, 1971"
      }
    ]
  },
  {
    "name": "Total Recall",
    "hints": [
      {
        "key": "Directed by",
        "value": "Len Wiseman"
      },
      {
        "key": "Box office",
        "value": "$211.8 million"
      },
      {
        "key": "Release date",
        "value": "August 3, 2012"
      }
    ]
  },
  {
    "name": "The Hunchback of Notre Dame",
    "hints": [
      {
        "key": "Directed by",
        "value": "William Dieterle"
      },
      {
        "key": "Box office",
        "value": "$3,155,000"
      },
      {
        "key": "Release date",
        "value": "December 29, 1939"
      }
    ]
  },
  {
    "name": "Cloudy with a Chance of Meatballs",
    "hints": [
      {
        "key": "Directed by",
        "value": "Phil Lord, Christopher Miller"
      },
      {
        "key": "Box office",
        "value": "$243 million"
      },
      {
        "key": "Release date",
        "value": "September 12, 2009"
      }
    ]
  },
  {
    "name": "Marriage Story",
    "hints": [
      {
        "key": "Directed by",
        "value": "Noah Baumbach"
      },
      {
        "key": "Box office",
        "value": "$2.3 million"
      },
      {
        "key": "Release date",
        "value": "August 29, 2019"
      }
    ]
  },
  {
    "name": "The Fisher King",
    "hints": [
      {
        "key": "Directed by",
        "value": "Terry Gilliam"
      },
      {
        "key": "Box office",
        "value": "$72.4 million"
      },
      {
        "key": "Release date",
        "value": "September 20, 1991"
      }
    ]
  },
  {
    "name": "Nausicaa of the Valley of the Wind",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hayao Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$14.3 million"
      },
      {
        "key": "Release date",
        "value": "11 March 1984"
      }
    ]
  },
  {
    "name": "Gravity",
    "hints": [
      {
        "key": "Directed by",
        "value": "Alfonso Cuaron"
      },
      {
        "key": "Box office",
        "value": "$723.2 million"
      },
      {
        "key": "Release date",
        "value": "August 28, 2013"
      }
    ]
  },
  {
    "name": "Three Days of the Condor",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sydney Pollack"
      },
      {
        "key": "Box office",
        "value": "$41.5 million"
      },
      {
        "key": "Release date",
        "value": "September 25, 1975"
      }
    ]
  },
  {
    "name": "The Revenant",
    "hints": [
      {
        "key": "Directed by",
        "value": "Alejandro G. Inarritu"
      },
      {
        "key": "Box office",
        "value": "$533 million"
      },
      {
        "key": "Release date",
        "value": "December 16, 2015"
      }
    ]
  },
  {
    "name": "Big Hero 6",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don HallChris Williams"
      },
      {
        "key": "Box office",
        "value": "$657.8 million"
      },
      {
        "key": "Release date",
        "value": "October 23, 2014"
      }
    ]
  },
  {
    "name": "Stand by Me",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rob Reiner"
      },
      {
        "key": "Box office",
        "value": "$52.3 million"
      },
      {
        "key": "Release date",
        "value": "August 8, 1986"
      }
    ]
  },
  {
    "name": "Death Wish",
    "hints": [
      {
        "key": "Directed by",
        "value": "Eli Roth"
      },
      {
        "key": "Box office",
        "value": "$49.6 million"
      },
      {
        "key": "Release date",
        "value": "March 2, 2018"
      }
    ]
  },
  {
    "name": "Ratatouille",
    "hints": [
      {
        "key": "Directed by",
        "value": "Brad Bird"
      },
      {
        "key": "Box office",
        "value": "$623.7 million"
      },
      {
        "key": "Release date",
        "value": "June 22, 2007"
      }
    ]
  },
  {
    "name": "Dirty Harry",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Siegel"
      },
      {
        "key": "Box office",
        "value": "$36 million"
      },
      {
        "key": "Release date",
        "value": "December 23, 1971"
      }
    ]
  },
  {
    "name": "Thor: Ragnarok",
    "hints": [
      {
        "key": "Directed by",
        "value": "Taika Waititi"
      },
      {
        "key": "Box office",
        "value": "$865 million"
      },
      {
        "key": "Release date",
        "value": "October 10, 2017"
      }
    ]
  },
  {
    "name": "One Flew Over the Cuckoo's Nest",
    "hints": [
      {
        "key": "Directed by",
        "value": "Milos Forman"
      },
      {
        "key": "Box office",
        "value": "$163.3 million"
      },
      {
        "key": "Release date",
        "value": "November 19, 1975"
      }
    ]
  },
  {
    "name": "The Dark Knight",
    "hints": [
      {
        "key": "Directed by",
        "value": "Christopher Nolan"
      },
      {
        "key": "Box office",
        "value": "$1.006 billion"
      },
      {
        "key": "Release date",
        "value": "July 14, 2008"
      }
    ]
  },
  {
    "name": "First Blood",
    "hints": [
      {
        "key": "Directed by",
        "value": "Ted Kotcheff"
      },
      {
        "key": "Box office",
        "value": "$125.2 million"
      },
      {
        "key": "Release date",
        "value": "October 22, 1982"
      }
    ]
  },
  {
    "name": "The Incredibles 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Brad Bird"
      },
      {
        "key": "Box office",
        "value": "$1.243 billion"
      },
      {
        "key": "Release date",
        "value": "June 5, 2018"
      }
    ]
  },
  {
    "name": "Young Frankenstein",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mel Brooks"
      },
      {
        "key": "Box office",
        "value": "$86.2 million"
      },
      {
        "key": "Release date",
        "value": "December 15, 1974"
      }
    ]
  },
  {
    "name": "Doctor Strange",
    "hints": [
      {
        "key": "Directed by",
        "value": "Scott Derrickson"
      },
      {
        "key": "Box office",
        "value": "$677.8 million"
      },
      {
        "key": "Release date",
        "value": "October 13, 2016"
      }
    ]
  },
  {
    "name": "Cars 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Lasseter"
      },
      {
        "key": "Box office",
        "value": "$559.8 million"
      },
      {
        "key": "Release date",
        "value": "June 18, 2011"
      }
    ]
  },
  {
    "name": "A Fistful of Dollars",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sergio Leone"
      },
      {
        "key": "Box office",
        "value": "$19.9 million"
      },
      {
        "key": "Release date",
        "value": "12 September 1964"
      }
    ]
  },
  {
    "name": "Citizen Kane",
    "hints": [
      {
        "key": "Directed by",
        "value": "Orson Welles"
      },
      {
        "key": "Box office",
        "value": "$1.8 million"
      },
      {
        "key": "Release date",
        "value": "May 1, 1941"
      }
    ]
  },
  {
    "name": "Saturday Night Fever",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Badham"
      },
      {
        "key": "Box office",
        "value": "$237.1 million"
      },
      {
        "key": "Release date",
        "value": "December 16, 1977"
      }
    ]
  },
  {
    "name": "The Hustler",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Rossen"
      },
      {
        "key": "Box office",
        "value": "$7.6 million"
      },
      {
        "key": "Release date",
        "value": "September 25, 1961"
      }
    ]
  },
  {
    "name": "A Letter to Momo",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hiroyuki Okiura"
      },
      {
        "key": "Box office",
        "value": "$6.7 million"
      },
      {
        "key": "Release date",
        "value": "September 10, 2011"
      }
    ]
  },
  {
    "name": "The Black Cauldron",
    "hints": [
      {
        "key": "Directed by",
        "value": "Ted Berman, Richard Rich"
      },
      {
        "key": "Box office",
        "value": "$21.3 million"
      },
      {
        "key": "Release date",
        "value": "July 24, 1985"
      }
    ]
  },
  {
    "name": "The Secret Life of Pets",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Renaud"
      },
      {
        "key": "Box office",
        "value": "$885.3 million"
      },
      {
        "key": "Release date",
        "value": "June 16, 2016"
      }
    ]
  },
  {
    "name": "Cars 3",
    "hints": [
      {
        "key": "Directed by",
        "value": "Brian Fee"
      },
      {
        "key": "Box office",
        "value": "$383.9 million"
      },
      {
        "key": "Release date",
        "value": "May 23, 2017"
      }
    ]
  },
  {
    "name": "Sonic the Hedgehog 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jeff Fowler"
      },
      {
        "key": "Box office",
        "value": "$405 million"
      },
      {
        "key": "Release date",
        "value": "March 30, 2022"
      }
    ]
  },
  {
    "name": "Robin Hood: Prince of Thieves",
    "hints": [
      {
        "key": "Directed by",
        "value": "Kevin Reynolds"
      },
      {
        "key": "Box office",
        "value": "$390.5 million"
      },
      {
        "key": "Release date",
        "value": "June 14, 1991"
      }
    ]
  },
  {
    "name": "From Up on Poppy Hill",
    "hints": [
      {
        "key": "Directed by",
        "value": "Goro Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$61.5 million"
      },
      {
        "key": "Release date",
        "value": "July 16, 2011"
      }
    ]
  },
  {
    "name": "The Penguins of Madagascar",
    "hints": [
      {
        "key": "Directed by",
        "value": "Eric Darnell, Simon J. Smith"
      },
      {
        "key": "Box office",
        "value": "$373.5 million"
      },
      {
        "key": "Release date",
        "value": "November 14, 2014"
      }
    ]
  },
  {
    "name": "Kung Fu Panda 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jennifer Yuh Nelson"
      },
      {
        "key": "Box office",
        "value": "$665.6 million"
      },
      {
        "key": "Release date",
        "value": "May 22, 2011"
      }
    ]
  },
  {
    "name": "Heat",
    "hints": [
      {
        "key": "Directed by",
        "value": "Paul Feig"
      },
      {
        "key": "Box office",
        "value": "$229.9 million"
      },
      {
        "key": "Release date",
        "value": "June 23, 2013"
      }
    ]
  },
  {
    "name": "The Muppet Movie",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Frawley"
      },
      {
        "key": "Box office",
        "value": "$65.2 million"
      },
      {
        "key": "Release date",
        "value": "May 31, 1979"
      }
    ]
  },
  {
    "name": "Lady Bird",
    "hints": [
      {
        "key": "Directed by",
        "value": "Greta Gerwig"
      },
      {
        "key": "Box office",
        "value": "$79 million"
      },
      {
        "key": "Release date",
        "value": "September 1, 2017"
      }
    ]
  },
  {
    "name": "Independence Day",
    "hints": [
      {
        "key": "Directed by",
        "value": "Roland Emmerich"
      },
      {
        "key": "Box office",
        "value": "$817.4 million"
      },
      {
        "key": "Release date",
        "value": "July 3, 1996"
      }
    ]
  },
  {
    "name": "All the President's Men",
    "hints": [
      {
        "key": "Directed by",
        "value": "Alan J. Pakula"
      },
      {
        "key": "Box office",
        "value": "$70.6 million"
      },
      {
        "key": "Release date",
        "value": "April 7, 1976"
      }
    ]
  },
  {
    "name": "Loving Vincent",
    "hints": [
      {
        "key": "Directed by",
        "value": "Dorota Kobiela, Hugh Welchman"
      },
      {
        "key": "Box office",
        "value": "$42.1 million"
      },
      {
        "key": "Release date",
        "value": "12 June 2017"
      }
    ]
  },
  {
    "name": "The Princess and the Frog",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Musker, Ron Clements"
      },
      {
        "key": "Box office",
        "value": "$271 million"
      },
      {
        "key": "Release date",
        "value": "November 25, 2009"
      }
    ]
  },
  {
    "name": "The Jungle Book",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jon Favreau"
      },
      {
        "key": "Box office",
        "value": "$966.6 million"
      },
      {
        "key": "Release date",
        "value": "April 4, 2016"
      }
    ]
  },
  {
    "name": "Carrie",
    "hints": [
      {
        "key": "Directed by",
        "value": "Kimberly Peirce"
      },
      {
        "key": "Box office",
        "value": "$84.8 million"
      },
      {
        "key": "Release date",
        "value": "October 7, 2013"
      }
    ]
  },
  {
    "name": "The Truman Show",
    "hints": [
      {
        "key": "Directed by",
        "value": "Peter Weir"
      },
      {
        "key": "Box office",
        "value": "$264.1 million"
      },
      {
        "key": "Release date",
        "value": "June 1, 1998"
      }
    ]
  },
  {
    "name": "The Lost Boys",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joel Schumacher"
      },
      {
        "key": "Box office",
        "value": "$32.2 million"
      },
      {
        "key": "Release date",
        "value": "July 31, 1987"
      }
    ]
  },
  {
    "name": "Mary and the Witch's Flower",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hiromasa Yonebayashi"
      },
      {
        "key": "Box office",
        "value": "$42.2 million"
      },
      {
        "key": "Release date",
        "value": "8 July 2017"
      }
    ]
  },
  {
    "name": "The Princess Bride",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rob Reiner"
      },
      {
        "key": "Box office",
        "value": "$30.9 million"
      },
      {
        "key": "Release date",
        "value": "September 25, 1987"
      }
    ]
  },
  {
    "name": "Thumbelina",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Bluth, Gary Goldman"
      },
      {
        "key": "Box office",
        "value": "$17 million"
      },
      {
        "key": "Release date",
        "value": "March 30, 1994"
      }
    ]
  },
  {
    "name": "Midnight Cowboy",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Schlesinger"
      },
      {
        "key": "Box office",
        "value": "$44.8 million"
      },
      {
        "key": "Release date",
        "value": "May 25, 1969"
      }
    ]
  },
  {
    "name": "Coming to America",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Landis"
      },
      {
        "key": "Box office",
        "value": "$288.8 million"
      },
      {
        "key": "Release date",
        "value": "June 29, 1988"
      }
    ]
  },
  {
    "name": "Rio Bravo",
    "hints": [
      {
        "key": "Directed by",
        "value": "Howard Hawks"
      },
      {
        "key": "Box office",
        "value": "$5.75 million "
      },
      {
        "key": "Release date",
        "value": "April 4, 1959"
      }
    ]
  },
  {
    "name": "Captain Marvel",
    "hints": [
      {
        "key": "Directed by",
        "value": "Anna Boden, Ryan Fleck"
      },
      {
        "key": "Box office",
        "value": "$1.131 billion"
      },
      {
        "key": "Release date",
        "value": "February 27, 2019"
      }
    ]
  },
  {
    "name": "Braveheart",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mel Gibson"
      },
      {
        "key": "Box office",
        "value": "$209million"
      },
      {
        "key": "Release date",
        "value": "May 18, 1995"
      }
    ]
  },
  {
    "name": "The Cat Returns",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hiroyuki Morita"
      },
      {
        "key": "Box office",
        "value": "$65 million"
      },
      {
        "key": "Release date",
        "value": "July 20, 2002"
      }
    ]
  },
  {
    "name": "RoboCop",
    "hints": [
      {
        "key": "Directed by",
        "value": "Paul Verhoeven"
      },
      {
        "key": "Box office",
        "value": "$53.4 million"
      },
      {
        "key": "Release date",
        "value": "July 17, 1987"
      }
    ]
  },
  {
    "name": "American Beauty",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sam Mendes"
      },
      {
        "key": "Box office",
        "value": "$356.3 million"
      },
      {
        "key": "Release date",
        "value": "September 8, 1999"
      }
    ]
  },
  {
    "name": "Onward",
    "hints": [
      {
        "key": "Directed by",
        "value": "Dan Scanlon"
      },
      {
        "key": "Box office",
        "value": "$142 million"
      },
      {
        "key": "Release date",
        "value": "February 21, 2020"
      }
    ]
  },
  {
    "name": "The Lorax",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Renaud"
      },
      {
        "key": "Box office",
        "value": "$351.4 million"
      },
      {
        "key": "Release date",
        "value": "February 19, 2012"
      }
    ]
  },
  {
    "name": "The Andromeda Strain",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Wise"
      },
      {
        "key": "Box office",
        "value": "$12.4 million"
      },
      {
        "key": "Release date",
        "value": "March 12, 1971"
      }
    ]
  },
  {
    "name": "The Outsiders",
    "hints": [
      {
        "key": "Directed by",
        "value": "Francis Ford Coppola"
      },
      {
        "key": "Box office",
        "value": "$33.7 million"
      },
      {
        "key": "Release date",
        "value": "March 25, 1983"
      }
    ]
  },
  {
    "name": "To Kill a Mockingbird",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Mulligan"
      },
      {
        "key": "Box office",
        "value": "$13.1 million"
      },
      {
        "key": "Release date",
        "value": "December 25, 1962"
      }
    ]
  },
  {
    "name": "Singin' in the Rain",
    "hints": [
      {
        "key": "Directed by",
        "value": "Gene Kelly, Stanley Donen"
      },
      {
        "key": "Box office",
        "value": "$7.2 million"
      },
      {
        "key": "Release date",
        "value": "March 27, 1952"
      }
    ]
  },
  {
    "name": "The SpongeBob Movie: Sponge on the Run",
    "hints": [
      {
        "key": "Directed by",
        "value": "Tim Hill"
      },
      {
        "key": "Box office",
        "value": "$4.8 million"
      },
      {
        "key": "Release date",
        "value": "August 14, 2020"
      }
    ]
  },
  {
    "name": "The Iron Giant",
    "hints": [
      {
        "key": "Directed by",
        "value": "Brad Bird"
      },
      {
        "key": "Box office",
        "value": "$31.3 million"
      },
      {
        "key": "Release date",
        "value": "July 31, 1999"
      }
    ]
  },
  {
    "name": "Luca",
    "hints": [
      {
        "key": "Directed by",
        "value": "Enrico Casarosa"
      },
      {
        "key": "Box office",
        "value": "$51.1 million"
      },
      {
        "key": "Release date",
        "value": "June 13, 2021"
      }
    ]
  },
  {
    "name": "American Graffiti",
    "hints": [
      {
        "key": "Directed by",
        "value": "George Lucas"
      },
      {
        "key": "Box office",
        "value": "$140 million"
      },
      {
        "key": "Release date",
        "value": "August 2, 1973"
      }
    ]
  },
  {
    "name": "Dragonheart",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rob Cohen"
      },
      {
        "key": "Box office",
        "value": "$115.3 million"
      },
      {
        "key": "Release date",
        "value": "May 31, 1996"
      }
    ]
  },
  {
    "name": "Akira",
    "hints": [
      {
        "key": "Directed by",
        "value": "Katsuhiro Otomo"
      },
      {
        "key": "Box office",
        "value": "$49 million"
      },
      {
        "key": "Release date",
        "value": "July 16, 1988"
      }
    ]
  },
  {
    "name": "The Good, the Bad and the Ugly",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sergio Leone"
      },
      {
        "key": "Box office",
        "value": "$38.9 million"
      },
      {
        "key": "Release date",
        "value": "23 December 1966"
      }
    ]
  },
  {
    "name": "The Dirty Dozen",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Aldrich"
      },
      {
        "key": "Box office",
        "value": "$45.3 million"
      },
      {
        "key": "Release date",
        "value": "June 15, 1967"
      }
    ]
  },
  {
    "name": "Spider-Man 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sam Raimi"
      },
      {
        "key": "Box office",
        "value": "$795.9 million"
      },
      {
        "key": "Release date",
        "value": "June 25, 2004"
      }
    ]
  },
  {
    "name": "Star Wars: The Last Jedi",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rian Johnson"
      },
      {
        "key": "Box office",
        "value": "$1.334billion"
      },
      {
        "key": "Release date",
        "value": "December 9, 2017"
      }
    ]
  },
  {
    "name": "Horton Hears a Who!",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jimmy Hayward, Steve Martino"
      },
      {
        "key": "Box office",
        "value": "$298.5 million"
      },
      {
        "key": "Release date",
        "value": "March 14, 2008"
      }
    ]
  },
  {
    "name": "Monsters University",
    "hints": [
      {
        "key": "Directed by",
        "value": "Dan Scanlon"
      },
      {
        "key": "Box office",
        "value": "$743.6 million"
      },
      {
        "key": "Release date",
        "value": "June 5, 2013"
      }
    ]
  },
  {
    "name": "Dr. Strangelove",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stanley Kubrick"
      },
      {
        "key": "Box office",
        "value": "$9.2 million "
      },
      {
        "key": "Release date",
        "value": "January 29, 1964"
      }
    ]
  },
  {
    "name": "Sonic the Hedgehog",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jeff Fowler"
      },
      {
        "key": "Box office",
        "value": "$319.7 million"
      },
      {
        "key": "Release date",
        "value": "January 25, 2020"
      }
    ]
  },
  {
    "name": "Black Widow",
    "hints": [
      {
        "key": "Directed by",
        "value": "Cate Shortland"
      },
      {
        "key": "Box office",
        "value": "$379.8 million"
      },
      {
        "key": "Release date",
        "value": "June 29, 2021"
      }
    ]
  },
  {
    "name": "Shang-Chi and the Legend of the Ten Rings",
    "hints": [
      {
        "key": "Directed by",
        "value": "Destin Daniel Cretton"
      },
      {
        "key": "Box office",
        "value": "$432.2 million"
      },
      {
        "key": "Release date",
        "value": "August 16, 2021"
      }
    ]
  },
  {
    "name": "Tangled",
    "hints": [
      {
        "key": "Directed by",
        "value": "Nathan Greno, Byron Howard"
      },
      {
        "key": "Box office",
        "value": "$592.5 million"
      },
      {
        "key": "Release date",
        "value": "November 14, 2010"
      }
    ]
  },
  {
    "name": "On the Waterfront",
    "hints": [
      {
        "key": "Directed by",
        "value": "Elia Kazan"
      },
      {
        "key": "Box office",
        "value": "$9.6 million"
      },
      {
        "key": "Release date",
        "value": "July 28, 1954"
      }
    ]
  },
  {
    "name": "Scanners",
    "hints": [
      {
        "key": "Directed by",
        "value": "David Cronenberg"
      },
      {
        "key": "Box office",
        "value": "$14.2 million"
      },
      {
        "key": "Release date",
        "value": "January 14, 1981"
      }
    ]
  },
  {
    "name": "The Killing Fields",
    "hints": [
      {
        "key": "Directed by",
        "value": "Roland Joffe"
      },
      {
        "key": "Box office",
        "value": "$34.7 million"
      },
      {
        "key": "Release date",
        "value": "2 November 1984"
      }
    ]
  },
  {
    "name": "The Sword in the Stone",
    "hints": [
      {
        "key": "Directed by",
        "value": "Wolfgang Reitherman"
      },
      {
        "key": "Box office",
        "value": "$22.2 million"
      },
      {
        "key": "Release date",
        "value": "December 12, 1963"
      }
    ]
  },
  {
    "name": "A Beautiful Mind",
    "hints": [
      {
        "key": "Directed by",
        "value": "Ron Howard"
      },
      {
        "key": "Box office",
        "value": "$316.8 million"
      },
      {
        "key": "Release date",
        "value": "December 13, 2001"
      }
    ]
  },
  {
    "name": "Casino",
    "hints": [
      {
        "key": "Directed by",
        "value": "Martin Scorsese"
      },
      {
        "key": "Box office",
        "value": "$116.1 million"
      },
      {
        "key": "Release date",
        "value": "November 14, 1995"
      }
    ]
  },
  {
    "name": "Saving Private Ryan",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steven Spielberg"
      },
      {
        "key": "Box office",
        "value": "$482.3 million"
      },
      {
        "key": "Release date",
        "value": "July 24, 1998"
      }
    ]
  },
  {
    "name": "The Angry Birds Movie",
    "hints": [
      {
        "key": "Directed by",
        "value": "Clay Kaytis, Fergal Reilly"
      },
      {
        "key": "Box office",
        "value": "$352.3 million"
      },
      {
        "key": "Release date",
        "value": "May 1, 2016"
      }
    ]
  },
  {
    "name": "Joker",
    "hints": [
      {
        "key": "Directed by",
        "value": "Todd Phillips"
      },
      {
        "key": "Box office",
        "value": "$1.079 billion"
      },
      {
        "key": "Release date",
        "value": "August 31, 2019"
      }
    ]
  },
  {
    "name": "Titan A.E.",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Bluth, Gary Goldman"
      },
      {
        "key": "Box office",
        "value": "$36.8 million"
      },
      {
        "key": "Release date",
        "value": "June 16, 2000"
      }
    ]
  },
  {
    "name": "Willy Wonka & the Chocolate Factory",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mel Stuart"
      },
      {
        "key": "Box office",
        "value": "$4 million"
      },
      {
        "key": "Release date",
        "value": "June 30, 1971"
      }
    ]
  },
  {
    "name": "The Abyss",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Cameron"
      },
      {
        "key": "Box office",
        "value": "$90 million"
      },
      {
        "key": "Release date",
        "value": "August 9, 1989"
      }
    ]
  },
  {
    "name": "Predator",
    "hints": [
      {
        "key": "Directed by",
        "value": "John McTiernan"
      },
      {
        "key": "Box office",
        "value": "$98.3 million"
      },
      {
        "key": "Release date",
        "value": "June 12, 1987"
      }
    ]
  },
  {
    "name": "The Omen",
    "hints": [
      {
        "key": "Directed by",
        "value": "Richard Donner"
      },
      {
        "key": "Box office",
        "value": "$60.9 million "
      },
      {
        "key": "Release date",
        "value": "June 6, 1976"
      }
    ]
  },
  {
    "name": "Labyrinth",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jim Henson"
      },
      {
        "key": "Box office",
        "value": "$34 million"
      },
      {
        "key": "Release date",
        "value": "June 27, 1986"
      }
    ]
  },
  {
    "name": "The Towering Inferno",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Guillermin"
      },
      {
        "key": "Box office",
        "value": "$203.3 million"
      },
      {
        "key": "Release date",
        "value": "December 16, 1974"
      }
    ]
  },
  {
    "name": "The Lion King",
    "hints": [
      {
        "key": "Directed by",
        "value": "Roger Allers, Rob Minkoff"
      },
      {
        "key": "Box office",
        "value": "$968.4 million"
      },
      {
        "key": "Release date",
        "value": "June 15, 1994"
      }
    ]
  },
  {
    "name": "Marathon Man",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Schlesinger"
      },
      {
        "key": "Box office",
        "value": "$28.2 million"
      },
      {
        "key": "Release date",
        "value": "October 8, 1976"
      }
    ]
  },
  {
    "name": "Grease",
    "hints": [
      {
        "key": "Directed by",
        "value": "Randal Kleiser"
      },
      {
        "key": "Box office",
        "value": "$396.3 million"
      },
      {
        "key": "Release date",
        "value": "June 16, 1978"
      }
    ]
  },
  {
    "name": "Airplane!",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jim Abrahams, David Zucker, Jerry Zucker"
      },
      {
        "key": "Box office",
        "value": "$171 million"
      },
      {
        "key": "Release date",
        "value": "June 27, 1980"
      }
    ]
  },
  {
    "name": "Iron Man",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jon Favreau"
      },
      {
        "key": "Box office",
        "value": "$585.8 million"
      },
      {
        "key": "Release date",
        "value": "April 14, 2008"
      }
    ]
  },
  {
    "name": "The Thing",
    "hints": [
      {
        "key": "Directed by",
        "value": "Matthijs van Heijningen Jr."
      },
      {
        "key": "Box office",
        "value": "$31.5 million"
      },
      {
        "key": "Release date",
        "value": "October 10, 2011"
      }
    ]
  },
  {
    "name": "Summer Wars",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mamoru Hosoda"
      },
      {
        "key": "Box office",
        "value": "$18.4 million"
      },
      {
        "key": "Release date",
        "value": "August 1, 2009"
      }
    ]
  },
  {
    "name": "The Triplets of Belleville",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sylvain Chomet"
      },
      {
        "key": "Box office",
        "value": "$14.8 million"
      },
      {
        "key": "Release date",
        "value": "18 May 2003"
      }
    ]
  },
  {
    "name": "Guardians of the Galaxy Vol. 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Gunn"
      },
      {
        "key": "Box office",
        "value": "$869.8 million"
      },
      {
        "key": "Release date",
        "value": "April 10, 2017"
      }
    ]
  },
  {
    "name": "Bill & Ted's Excellent Adventure",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stephen Herek"
      },
      {
        "key": "Box office",
        "value": "$40.5 million"
      },
      {
        "key": "Release date",
        "value": "February 17, 1989"
      }
    ]
  },
  {
    "name": "L.A. Confidential",
    "hints": [
      {
        "key": "Directed by",
        "value": "Curtis Hanson"
      },
      {
        "key": "Box office",
        "value": "$126.2 million"
      },
      {
        "key": "Release date",
        "value": "May 14, 1997"
      }
    ]
  },
  {
    "name": "Taxi Driver",
    "hints": [
      {
        "key": "Directed by",
        "value": "Martin Scorsese"
      },
      {
        "key": "Box office",
        "value": "$28.6 million"
      },
      {
        "key": "Release date",
        "value": "February 8, 1976"
      }
    ]
  },
  {
    "name": "Eternals",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chloe Zhao"
      },
      {
        "key": "Box office",
        "value": "$402.1 million"
      },
      {
        "key": "Release date",
        "value": "October 18, 2021"
      }
    ]
  },
  {
    "name": "Jaws",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steven Spielberg"
      },
      {
        "key": "Box office",
        "value": "$476.5 million"
      },
      {
        "key": "Release date",
        "value": "June 20, 1975"
      }
    ]
  },
  {
    "name": "Dog Day Afternoon",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sidney Lumet"
      },
      {
        "key": "Box office",
        "value": "$50 million"
      },
      {
        "key": "Release date",
        "value": "September 20, 1975"
      }
    ]
  },
  {
    "name": "Paper Moon",
    "hints": [
      {
        "key": "Directed by",
        "value": "Peter Bogdanovich"
      },
      {
        "key": "Box office",
        "value": "$30.9 million"
      },
      {
        "key": "Release date",
        "value": "April 9, 1973"
      }
    ]
  },
  {
    "name": "Fiddler on the Roof",
    "hints": [
      {
        "key": "Directed by",
        "value": "Norman Jewison"
      },
      {
        "key": "Box office",
        "value": "$83.3 million"
      },
      {
        "key": "Release date",
        "value": "November 3, 1971"
      }
    ]
  },
  {
    "name": "Trading Places",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Landis"
      },
      {
        "key": "Box office",
        "value": "$120.6 million"
      },
      {
        "key": "Release date",
        "value": "June 8, 1983"
      }
    ]
  },
  {
    "name": "Moana",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Musker, Ron Clements"
      },
      {
        "key": "Box office",
        "value": "$687.2 million"
      },
      {
        "key": "Release date",
        "value": "November 14, 2016"
      }
    ]
  },
  {
    "name": "WALL-E",
    "hints": [
      {
        "key": "Directed by",
        "value": "Andrew Stanton"
      },
      {
        "key": "Box office",
        "value": "$532.5 million"
      },
      {
        "key": "Release date",
        "value": "June 23, 2008"
      }
    ]
  },
  {
    "name": "National Lampoon's Vacation",
    "hints": [
      {
        "key": "Directed by",
        "value": "Harold Ramis"
      },
      {
        "key": "Box office",
        "value": "$61.4 million"
      },
      {
        "key": "Release date",
        "value": "July 29, 1983"
      }
    ]
  },
  {
    "name": "Star Wars",
    "hints": [
      {
        "key": "Directed by",
        "value": "George Lucas"
      },
      {
        "key": "Box office",
        "value": "$775.4 million"
      },
      {
        "key": "Release date",
        "value": "May 25, 1977"
      }
    ]
  },
  {
    "name": "Porco Rosso",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hayao Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$44.6 million"
      },
      {
        "key": "Release date",
        "value": "July 18, 1992"
      }
    ]
  },
  {
    "name": "Forrest Gump",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Zemeckis"
      },
      {
        "key": "Box office",
        "value": "$678.2million"
      },
      {
        "key": "Release date",
        "value": "June 23, 1994"
      }
    ]
  },
  {
    "name": "Harry Potter and the Sorcerer's Stone",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Columbus"
      },
      {
        "key": "Box office",
        "value": "$1.024 billion"
      },
      {
        "key": "Release date",
        "value": "4 November 2001"
      }
    ]
  },
  {
    "name": "Don't Look Up",
    "hints": [
      {
        "key": "Directed by",
        "value": "Adam McKay"
      },
      {
        "key": "Box office",
        "value": "$791,863"
      },
      {
        "key": "Release date",
        "value": "December 5, 2021"
      }
    ]
  },
  {
    "name": "The Sixth Sense",
    "hints": [
      {
        "key": "Directed by",
        "value": "M. Night Shyamalan"
      },
      {
        "key": "Box office",
        "value": "$672.8 million"
      },
      {
        "key": "Release date",
        "value": "August 2, 1999"
      }
    ]
  },
  {
    "name": "The Long Goodbye",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Altman"
      },
      {
        "key": "Box office",
        "value": "$959,000"
      },
      {
        "key": "Release date",
        "value": "March 7, 1973"
      }
    ]
  },
  {
    "name": "An American Tail",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Bluth"
      },
      {
        "key": "Box office",
        "value": "$84 million"
      },
      {
        "key": "Release date",
        "value": "November 21, 1986"
      }
    ]
  },
  {
    "name": "Return of the Jedi",
    "hints": [
      {
        "key": "Directed by",
        "value": "Richard Marquand"
      },
      {
        "key": "Box office",
        "value": "$482 million"
      },
      {
        "key": "Release date",
        "value": "May 25, 1983"
      }
    ]
  },
  {
    "name": "Gone with the Wind",
    "hints": [
      {
        "key": "Directed by",
        "value": "Victor Fleming"
      },
      {
        "key": "Box office",
        "value": "$390 million"
      },
      {
        "key": "Release date",
        "value": "December 15, 1939"
      }
    ]
  },
  {
    "name": "The Fifth Element",
    "hints": [
      {
        "key": "Directed by",
        "value": "Luc Besson"
      },
      {
        "key": "Box office",
        "value": "$263.9 million"
      },
      {
        "key": "Release date",
        "value": "7 May 1997"
      }
    ]
  },
  {
    "name": "Spider-Man: No Way Home",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jon Watts"
      },
      {
        "key": "Box office",
        "value": "$1.923 billion"
      },
      {
        "key": "Release date",
        "value": "December 13, 2021"
      }
    ]
  },
  {
    "name": "Grave of the Fireflies",
    "hints": [
      {
        "key": "Directed by",
        "value": "Isao Takahata"
      },
      {
        "key": "Box office",
        "value": "$516,962"
      },
      {
        "key": "Release date",
        "value": "16 April 1988"
      }
    ]
  },
  {
    "name": "Beverly Hills Cop II",
    "hints": [
      {
        "key": "Directed by",
        "value": "Tony Scott"
      },
      {
        "key": "Box office",
        "value": "$299.97 million"
      },
      {
        "key": "Release date",
        "value": "May 19, 1987"
      }
    ]
  },
  {
    "name": "Fargo",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joel Coen, Ethan Coen"
      },
      {
        "key": "Box office",
        "value": "$60.6 million"
      },
      {
        "key": "Release date",
        "value": "March 8, 1996"
      }
    ]
  },
  {
    "name": "Scarface",
    "hints": [
      {
        "key": "Directed by",
        "value": "Brian De Palma"
      },
      {
        "key": "Box office",
        "value": "$66 million"
      },
      {
        "key": "Release date",
        "value": "December 1, 1983"
      }
    ]
  },
  {
    "name": "1917",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sam Mendes"
      },
      {
        "key": "Box office",
        "value": "$384.6 million"
      },
      {
        "key": "Release date",
        "value": "4 December 2019"
      }
    ]
  },
  {
    "name": "Interstellar",
    "hints": [
      {
        "key": "Directed by",
        "value": "Christopher Nolan"
      },
      {
        "key": "Box office",
        "value": "$705.2 million"
      },
      {
        "key": "Release date",
        "value": "October 26, 2014"
      }
    ]
  },
  {
    "name": "Ghostbusters",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jason Reitman"
      },
      {
        "key": "Box office",
        "value": "$204.3 million"
      },
      {
        "key": "Release date",
        "value": "August 23, 2021"
      }
    ]
  },
  {
    "name": "Vertigo",
    "hints": [
      {
        "key": "Directed by",
        "value": "Alfred Hitchcock"
      },
      {
        "key": "Box office",
        "value": "$7.3 million"
      },
      {
        "key": "Release date",
        "value": "May 9, 1958"
      }
    ]
  },
  {
    "name": "The Best Years of Our Lives",
    "hints": [
      {
        "key": "Directed by",
        "value": "William Wyler"
      },
      {
        "key": "Box office",
        "value": "$23.7 million"
      },
      {
        "key": "Release date",
        "value": "November 21, 1946"
      }
    ]
  },
  {
    "name": "Blazing Saddles",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mel Brooks"
      },
      {
        "key": "Box office",
        "value": "$119.6 million"
      },
      {
        "key": "Release date",
        "value": "February 7, 1974"
      }
    ]
  },
  {
    "name": "Cabaret",
    "hints": [
      {
        "key": "Directed by",
        "value": "Bob Fosse"
      },
      {
        "key": "Box office",
        "value": "$42.8 million"
      },
      {
        "key": "Release date",
        "value": "February 13, 1972"
      }
    ]
  },
  {
    "name": "The Empire Strikes Back",
    "hints": [
      {
        "key": "Directed by",
        "value": "Irvin Kershner"
      },
      {
        "key": "Box office",
        "value": "$538 million"
      },
      {
        "key": "Release date",
        "value": "May 6, 1980"
      }
    ]
  },
  {
    "name": "The Avengers",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joss Whedon"
      },
      {
        "key": "Box office",
        "value": "$1.519 billion"
      },
      {
        "key": "Release date",
        "value": "April 11, 2012"
      }
    ]
  },
  {
    "name": "Full Metal Jacket",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stanley Kubrick"
      },
      {
        "key": "Box office",
        "value": "$120 million"
      },
      {
        "key": "Release date",
        "value": "June 17, 1987"
      }
    ]
  },
  {
    "name": "Close Encounters of the Third Kind",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steven Spielberg"
      },
      {
        "key": "Box office",
        "value": "$306.9 million"
      },
      {
        "key": "Release date",
        "value": "November 16, 1977"
      }
    ]
  },
  {
    "name": "Spider-Man: Far From Home",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jon Watts"
      },
      {
        "key": "Box office",
        "value": "$1.133 billion"
      },
      {
        "key": "Release date",
        "value": "June 26, 2019"
      }
    ]
  },
  {
    "name": "The Lego Movie",
    "hints": [
      {
        "key": "Directed by",
        "value": "Phil LordChristopher Miller"
      },
      {
        "key": "Box office",
        "value": "$470.7 million"
      },
      {
        "key": "Release date",
        "value": "February 1, 2014"
      }
    ]
  },
  {
    "name": "All About Eve",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joseph L. Mankiewicz"
      },
      {
        "key": "Box office",
        "value": "$8.4 million"
      },
      {
        "key": "Release date",
        "value": "October 13, 1950"
      }
    ]
  },
  {
    "name": "Dances with Wolves",
    "hints": [
      {
        "key": "Directed by",
        "value": "Kevin Costner"
      },
      {
        "key": "Box office",
        "value": "$424.2 million"
      },
      {
        "key": "Release date",
        "value": "October 19, 1990"
      }
    ]
  },
  {
    "name": "Ice Age: Dawn of the Dinosaurs",
    "hints": [
      {
        "key": "Directed by",
        "value": "Carlos Saldanha"
      },
      {
        "key": "Box office",
        "value": "$886.7 million"
      },
      {
        "key": "Release date",
        "value": "July 1, 2009"
      }
    ]
  },
  {
    "name": "12 Years a Slave",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steve McQueen"
      },
      {
        "key": "Box office",
        "value": "$187.7 million"
      },
      {
        "key": "Release date",
        "value": "August 30, 2013"
      }
    ]
  },
  {
    "name": "Network",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sidney Lumet"
      },
      {
        "key": "Box office",
        "value": "$23.7 million"
      },
      {
        "key": "Release date",
        "value": "November 27, 1976"
      }
    ]
  },
  {
    "name": "Black Panther",
    "hints": [
      {
        "key": "Directed by",
        "value": "Ryan Coogler"
      },
      {
        "key": "Box office",
        "value": "$1.349 billion"
      },
      {
        "key": "Release date",
        "value": "January 29, 2018"
      }
    ]
  },
  {
    "name": "High Noon",
    "hints": [
      {
        "key": "Directed by",
        "value": "Fred Zinnemann"
      },
      {
        "key": "Box office",
        "value": "$12 million"
      },
      {
        "key": "Release date",
        "value": "July 24, 1952"
      }
    ]
  },
  {
    "name": "The Conversation",
    "hints": [
      {
        "key": "Directed by",
        "value": "Francis Ford Coppola"
      },
      {
        "key": "Box office",
        "value": "$4.4 million"
      },
      {
        "key": "Release date",
        "value": "April 7, 1974"
      }
    ]
  },
  {
    "name": "A Troll in Central Park",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Bluth, Gary Goldman"
      },
      {
        "key": "Box office",
        "value": "$71,368"
      },
      {
        "key": "Release date",
        "value": "October 7, 1994"
      }
    ]
  },
  {
    "name": "King Arthur",
    "hints": [
      {
        "key": "Directed by",
        "value": "Guy Ritchie"
      },
      {
        "key": "Box office",
        "value": "$148.7 million"
      },
      {
        "key": "Release date",
        "value": "8 May 2017"
      }
    ]
  },
  {
    "name": "The Lego Batman Movie",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris McKay"
      },
      {
        "key": "Box office",
        "value": "$312 million"
      },
      {
        "key": "Release date",
        "value": "January 29, 2017"
      }
    ]
  },
  {
    "name": "The Pagemaster",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joe Johnston, Maurice Hunt"
      },
      {
        "key": "Box office",
        "value": "$13.7 million "
      },
      {
        "key": "Release date",
        "value": "November 23, 1994"
      }
    ]
  },
  {
    "name": "Mulan",
    "hints": [
      {
        "key": "Directed by",
        "value": "Barry Cook, Tony Bancroft"
      },
      {
        "key": "Box office",
        "value": "$304.3 million"
      },
      {
        "key": "Release date",
        "value": "June 5, 1998"
      }
    ]
  },
  {
    "name": "A Bug's Life",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Lasseter"
      },
      {
        "key": "Box office",
        "value": "$363.3 million"
      },
      {
        "key": "Release date",
        "value": "November 14, 1998"
      }
    ]
  },
  {
    "name": "Psycho",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mary Harron"
      },
      {
        "key": "Box office",
        "value": "$34.3 million"
      },
      {
        "key": "Release date",
        "value": "January 21, 2000"
      }
    ]
  },
  {
    "name": "Toy Story 4",
    "hints": [
      {
        "key": "Directed by",
        "value": "Josh Cooley"
      },
      {
        "key": "Box office",
        "value": "$1.073 billion"
      },
      {
        "key": "Release date",
        "value": "June 11, 2019"
      }
    ]
  },
  {
    "name": "Minions",
    "hints": [
      {
        "key": "Directed by",
        "value": "Pierre Coffin, Kyle Balda"
      },
      {
        "key": "Box office",
        "value": "$1.159 billion"
      },
      {
        "key": "Release date",
        "value": "June 11, 2015"
      }
    ]
  },
  {
    "name": "Some Like It Hot",
    "hints": [
      {
        "key": "Directed by",
        "value": "Billy Wilder"
      },
      {
        "key": "Box office",
        "value": "$49 million"
      },
      {
        "key": "Release date",
        "value": "March 29, 1959"
      }
    ]
  },
  {
    "name": "Mean Streets",
    "hints": [
      {
        "key": "Directed by",
        "value": "Martin Scorsese"
      },
      {
        "key": "Box office",
        "value": "$3 million"
      },
      {
        "key": "Release date",
        "value": "October 14, 1973"
      }
    ]
  },
  {
    "name": "Rebel Without a Cause",
    "hints": [
      {
        "key": "Directed by",
        "value": "Nicholas Ray"
      },
      {
        "key": "Box office",
        "value": "$4.5 million"
      },
      {
        "key": "Release date",
        "value": "October 27, 1955"
      }
    ]
  },
  {
    "name": "Hotel Transylvania 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Genndy Tartakovsky"
      },
      {
        "key": "Box office",
        "value": "$474.8 million"
      },
      {
        "key": "Release date",
        "value": "September 25, 2015"
      }
    ]
  },
  {
    "name": "The Blues Brothers",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Landis"
      },
      {
        "key": "Box office",
        "value": "$115.2 million"
      },
      {
        "key": "Release date",
        "value": "June 20, 1980"
      }
    ]
  },
  {
    "name": "Fight Club",
    "hints": [
      {
        "key": "Directed by",
        "value": "David Fincher"
      },
      {
        "key": "Box office",
        "value": "$101.2 million"
      },
      {
        "key": "Release date",
        "value": "September 10, 1999"
      }
    ]
  },
  {
    "name": "Do the Right Thing",
    "hints": [
      {
        "key": "Directed by",
        "value": "Spike Lee"
      },
      {
        "key": "Box office",
        "value": "$37.3 million"
      },
      {
        "key": "Release date",
        "value": "May 19, 1989"
      }
    ]
  },
  {
    "name": "The SpongeBob Movie: Sponge Out of Water",
    "hints": [
      {
        "key": "Directed by",
        "value": "Paul Tibbitt"
      },
      {
        "key": "Box office",
        "value": "$325.2 million"
      },
      {
        "key": "Release date",
        "value": "January 28, 2015"
      }
    ]
  },
  {
    "name": "The Magnificent Seven",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Sturges"
      },
      {
        "key": "Box office",
        "value": "$9.75 million"
      },
      {
        "key": "Release date",
        "value": "October 12, 1960"
      }
    ]
  },
  {
    "name": "The Last Detail",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hal Ashby"
      },
      {
        "key": "Box office",
        "value": "$10 million"
      },
      {
        "key": "Release date",
        "value": "December 12, 1973"
      }
    ]
  },
  {
    "name": "Enchanted",
    "hints": [
      {
        "key": "Directed by",
        "value": "Kevin Lima"
      },
      {
        "key": "Box office",
        "value": "$340.5 million"
      },
      {
        "key": "Release date",
        "value": "October 20, 2007"
      }
    ]
  },
  {
    "name": "Hotel Transylvania: Transformania",
    "hints": [
      {
        "key": "Directed by",
        "value": "Derek Drymon, Jennifer Kluska"
      },
      {
        "key": "Box office",
        "value": "$18.5 million"
      },
      {
        "key": "Release date",
        "value": "January 14, 2022"
      }
    ]
  },
  {
    "name": "Raiders of the Lost Ark",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steven Spielberg"
      },
      {
        "key": "Box office",
        "value": "$389.9 million"
      },
      {
        "key": "Release date",
        "value": "June 12, 1981"
      }
    ]
  },
  {
    "name": "Star Wars: The Force Awakens",
    "hints": [
      {
        "key": "Directed by",
        "value": "J. J. Abrams"
      },
      {
        "key": "Box office",
        "value": "$2.071 billion"
      },
      {
        "key": "Release date",
        "value": "December 14, 2015"
      }
    ]
  },
  {
    "name": "The Wolf of Wall Street",
    "hints": [
      {
        "key": "Directed by",
        "value": "Martin Scorsese"
      },
      {
        "key": "Box office",
        "value": "$406.9 million"
      },
      {
        "key": "Release date",
        "value": "December 17, 2013"
      }
    ]
  },
  {
    "name": "Ford v Ferrari",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Mangold"
      },
      {
        "key": "Box office",
        "value": "$225.5 million"
      },
      {
        "key": "Release date",
        "value": "August 30, 2019"
      }
    ]
  },
  {
    "name": "Back to the Future Part II",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Zemeckis"
      },
      {
        "key": "Box office",
        "value": "$332.5 million"
      },
      {
        "key": "Release date",
        "value": "November 22, 1989"
      }
    ]
  },
  {
    "name": "The Boss Baby",
    "hints": [
      {
        "key": "Directed by",
        "value": "Tom McGrath"
      },
      {
        "key": "Box office",
        "value": "$146.8 million"
      },
      {
        "key": "Release date",
        "value": "July 2, 2021"
      }
    ]
  },
  {
    "name": "Aliens",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Cameron"
      },
      {
        "key": "Box office",
        "value": "$131.1 million"
      },
      {
        "key": "Release date",
        "value": "July 18, 1986"
      }
    ]
  },
  {
    "name": "The Lord of the Rings: The Return of the King",
    "hints": [
      {
        "key": "Directed by",
        "value": "Peter Jackson"
      },
      {
        "key": "Box office",
        "value": "$1.151 billion"
      },
      {
        "key": "Release date",
        "value": "1 December 2003"
      }
    ]
  },
  {
    "name": "The Green Mile",
    "hints": [
      {
        "key": "Directed by",
        "value": "Frank Darabont"
      },
      {
        "key": "Box office",
        "value": "$286.8 million"
      },
      {
        "key": "Release date",
        "value": "December 10, 1999"
      }
    ]
  },
  {
    "name": "Sleeping Beauty",
    "hints": [
      {
        "key": "Directed by",
        "value": "Clyde Geronimi, Wolfgang Reitherman, Eric Larson, Les Clark"
      },
      {
        "key": "Box office",
        "value": "$51.6 million "
      },
      {
        "key": "Release date",
        "value": "January 29, 1959"
      }
    ]
  },
  {
    "name": "The Little Mermaid",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rob Marshall"
      },
      {
        "key": "Box office",
        "value": "$569.6 million"
      },
      {
        "key": "Release date",
        "value": "May 8, 2023"
      }
    ]
  },
  {
    "name": "The Untouchables",
    "hints": [
      {
        "key": "Directed by",
        "value": "Brian De Palma"
      },
      {
        "key": "Box office",
        "value": "$106.2 million"
      },
      {
        "key": "Release date",
        "value": "June 2, 1987"
      }
    ]
  },
  {
    "name": "The Lord of the Rings: The Fellowship of the Ring",
    "hints": [
      {
        "key": "Directed by",
        "value": "Peter Jackson"
      },
      {
        "key": "Box office",
        "value": "$887.4 million"
      },
      {
        "key": "Release date",
        "value": "10 December 2001"
      }
    ]
  },
  {
    "name": "The Bridge on the River Kwai",
    "hints": [
      {
        "key": "Directed by",
        "value": "David Lean"
      },
      {
        "key": "Box office",
        "value": "$30.6 million"
      },
      {
        "key": "Release date",
        "value": "2 October 1957"
      }
    ]
  },
  {
    "name": "Mad Max",
    "hints": [
      {
        "key": "Directed by",
        "value": "George Miller"
      },
      {
        "key": "Box office",
        "value": "$100 million"
      },
      {
        "key": "Release date",
        "value": "12 April 1979"
      }
    ]
  },
  {
    "name": "The Secret Life of Pets 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Renaud"
      },
      {
        "key": "Box office",
        "value": "$446.2 million"
      },
      {
        "key": "Release date",
        "value": "May 24, 2019"
      }
    ]
  },
  {
    "name": "FernGully: The Last Rainforest",
    "hints": [
      {
        "key": "Directed by",
        "value": "Bill Kroyer"
      },
      {
        "key": "Box office",
        "value": "$32.7 million"
      },
      {
        "key": "Release date",
        "value": "April 10, 1992"
      }
    ]
  },
  {
    "name": "Back to the Future",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Zemeckis"
      },
      {
        "key": "Box office",
        "value": "$388.8 million"
      },
      {
        "key": "Release date",
        "value": "July 3, 1985"
      }
    ]
  },
  {
    "name": "The Treasure of the Sierra Madre",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Huston"
      },
      {
        "key": "Box office",
        "value": "$4.1 million"
      },
      {
        "key": "Release date",
        "value": "January 14, 1948"
      }
    ]
  },
  {
    "name": "Big Trouble in Little China",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Carpenter"
      },
      {
        "key": "Box office",
        "value": "$11.1 million"
      },
      {
        "key": "Release date",
        "value": "July 2, 1986"
      }
    ]
  },
  {
    "name": "How the West Was Won",
    "hints": [
      {
        "key": "Directed by",
        "value": "Henry Hathaway, John Ford, George Marshall"
      },
      {
        "key": "Box office",
        "value": "$50 million"
      },
      {
        "key": "Release date",
        "value": "November 1, 1962"
      }
    ]
  },
  {
    "name": "Top Gun",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joseph Kosinski"
      },
      {
        "key": "Box office",
        "value": "$1.496 billion"
      },
      {
        "key": "Release date",
        "value": "April 28, 2022"
      }
    ]
  },
  {
    "name": "A Nightmare on Elm Street",
    "hints": [
      {
        "key": "Directed by",
        "value": "Wes Craven"
      },
      {
        "key": "Box office",
        "value": "$57 million"
      },
      {
        "key": "Release date",
        "value": "November 9, 1984"
      }
    ]
  },
  {
    "name": "The Big Lebowski",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joel Coen"
      },
      {
        "key": "Box office",
        "value": "$47.4 million"
      },
      {
        "key": "Release date",
        "value": "January 18, 1998"
      }
    ]
  },
  {
    "name": "My Neighbor Totoro",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hayao Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$41 million"
      },
      {
        "key": "Release date",
        "value": "April 16, 1988"
      }
    ]
  },
  {
    "name": "Ben-Hur",
    "hints": [
      {
        "key": "Directed by",
        "value": "Timur Bekmambetov"
      },
      {
        "key": "Box office",
        "value": "$94.1 million"
      },
      {
        "key": "Release date",
        "value": "August 9, 2016"
      }
    ]
  },
  {
    "name": "Hercules",
    "hints": [
      {
        "key": "Directed by",
        "value": "Brett Ratner"
      },
      {
        "key": "Box office",
        "value": "$244.8 million"
      },
      {
        "key": "Release date",
        "value": "July 25, 2014"
      }
    ]
  },
  {
    "name": "Butch Cassidy and the Sundance Kid",
    "hints": [
      {
        "key": "Directed by",
        "value": "George Roy Hill"
      },
      {
        "key": "Box office",
        "value": "$102.3 million"
      },
      {
        "key": "Release date",
        "value": "September 23, 1969"
      }
    ]
  },
  {
    "name": "Jojo Rabbit",
    "hints": [
      {
        "key": "Directed by",
        "value": "Taika Waititi"
      },
      {
        "key": "Box office",
        "value": "$90.3 million"
      },
      {
        "key": "Release date",
        "value": "September 8, 2019"
      }
    ]
  },
  {
    "name": "Inception",
    "hints": [
      {
        "key": "Directed by",
        "value": "Christopher Nolan"
      },
      {
        "key": "Box office",
        "value": "$839 million"
      },
      {
        "key": "Release date",
        "value": "July 8, 2010"
      }
    ]
  },
  {
    "name": "Zootopia",
    "hints": [
      {
        "key": "Directed by",
        "value": "Byron Howard, Rich Moore"
      },
      {
        "key": "Box office",
        "value": "$1.025 billion"
      },
      {
        "key": "Release date",
        "value": "February 13, 2016"
      }
    ]
  },
  {
    "name": "The Matrix",
    "hints": [
      {
        "key": "Directed by",
        "value": "The Wachowskis"
      },
      {
        "key": "Box office",
        "value": "$467.6 million"
      },
      {
        "key": "Release date",
        "value": "March 24, 1999"
      }
    ]
  },
  {
    "name": "The Elephant Man",
    "hints": [
      {
        "key": "Directed by",
        "value": "David Lynch"
      },
      {
        "key": "Box office",
        "value": "$26 million"
      },
      {
        "key": "Release date",
        "value": "October 3, 1980"
      }
    ]
  },
  {
    "name": "The Social Network",
    "hints": [
      {
        "key": "Directed by",
        "value": "David Fincher"
      },
      {
        "key": "Box office",
        "value": "$224.9 million"
      },
      {
        "key": "Release date",
        "value": "September 24, 2010"
      }
    ]
  },
  {
    "name": "E.T. the Extra-Terrestrial",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steven Spielberg"
      },
      {
        "key": "Box office",
        "value": "$792.9 million"
      },
      {
        "key": "Release date",
        "value": "May 26, 1982"
      }
    ]
  },
  {
    "name": "Lu Over the Wall",
    "hints": [
      {
        "key": "Directed by",
        "value": "Masaaki Yuasa"
      },
      {
        "key": "Box office",
        "value": "$161,253"
      },
      {
        "key": "Release date",
        "value": "May 19, 2017"
      }
    ]
  },
  {
    "name": "A Goofy Movie",
    "hints": [
      {
        "key": "Directed by",
        "value": "Kevin Lima"
      },
      {
        "key": "Box office",
        "value": "$37.6 million"
      },
      {
        "key": "Release date",
        "value": "April 7, 1995"
      }
    ]
  },
  {
    "name": "Toy Story 3",
    "hints": [
      {
        "key": "Directed by",
        "value": "Lee Unkrich"
      },
      {
        "key": "Box office",
        "value": "$1.067 billion"
      },
      {
        "key": "Release date",
        "value": "June 12, 2010"
      }
    ]
  },
  {
    "name": "Patton",
    "hints": [
      {
        "key": "Directed by",
        "value": "Franklin J. Schaffner"
      },
      {
        "key": "Box office",
        "value": "$62.5 million"
      },
      {
        "key": "Release date",
        "value": "February 5, 1970"
      }
    ]
  },
  {
    "name": "Rain Man",
    "hints": [
      {
        "key": "Directed by",
        "value": "Barry Levinson"
      },
      {
        "key": "Box office",
        "value": "$354.8 million"
      },
      {
        "key": "Release date",
        "value": "December 16, 1988"
      }
    ]
  },
  {
    "name": "The Shining",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stanley Kubrick"
      },
      {
        "key": "Box office",
        "value": "$47.3 million"
      },
      {
        "key": "Release date",
        "value": "May 23, 1980"
      }
    ]
  },
  {
    "name": "The Goonies",
    "hints": [
      {
        "key": "Directed by",
        "value": "Richard Donner"
      },
      {
        "key": "Box office",
        "value": "$125 million"
      },
      {
        "key": "Release date",
        "value": "June 7, 1985"
      }
    ]
  },
  {
    "name": "The Secret of NIMH",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Bluth"
      },
      {
        "key": "Box office",
        "value": "$14.7 million"
      },
      {
        "key": "Release date",
        "value": "July 2, 1982"
      }
    ]
  },
  {
    "name": "Spirited Away",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hayao Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$395.8 million"
      },
      {
        "key": "Release date",
        "value": "20 July 2001"
      }
    ]
  },
  {
    "name": "Get Out",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jordan Peele"
      },
      {
        "key": "Box office",
        "value": "$255.4 million"
      },
      {
        "key": "Release date",
        "value": "January 23, 2017"
      }
    ]
  },
  {
    "name": "Oliver & Company",
    "hints": [
      {
        "key": "Directed by",
        "value": "George Scribner"
      },
      {
        "key": "Box office",
        "value": "$121 million"
      },
      {
        "key": "Release date",
        "value": "November 18, 1988"
      }
    ]
  },
  {
    "name": "Her",
    "hints": [
      {
        "key": "Directed by",
        "value": "Spike Jonze"
      },
      {
        "key": "Box office",
        "value": "$48.3 million"
      },
      {
        "key": "Release date",
        "value": "October 12, 2013"
      }
    ]
  },
  {
    "name": "Madagascar",
    "hints": [
      {
        "key": "Directed by",
        "value": "Eric Darnell, Tom McGrath"
      },
      {
        "key": "Box office",
        "value": "$556.6 million"
      },
      {
        "key": "Release date",
        "value": "May 27, 2005"
      }
    ]
  },
  {
    "name": "Hotel Transylvania",
    "hints": [
      {
        "key": "Directed by",
        "value": "Genndy Tartakovsky"
      },
      {
        "key": "Box office",
        "value": "$358.4 million"
      },
      {
        "key": "Release date",
        "value": "September 8, 2012"
      }
    ]
  },
  {
    "name": "Lawrence of Arabia",
    "hints": [
      {
        "key": "Directed by",
        "value": "David Lean"
      },
      {
        "key": "Box office",
        "value": "$70 million"
      },
      {
        "key": "Release date",
        "value": "10 December 1962"
      }
    ]
  },
  {
    "name": "Glory",
    "hints": [
      {
        "key": "Directed by",
        "value": "Edward Zwick"
      },
      {
        "key": "Box office",
        "value": "$27 million"
      },
      {
        "key": "Release date",
        "value": "December 15, 1989"
      }
    ]
  },
  {
    "name": "Soul",
    "hints": [
      {
        "key": "Directed by",
        "value": "Pete Docter"
      },
      {
        "key": "Box office",
        "value": "$122.2 million"
      },
      {
        "key": "Release date",
        "value": "October 11, 2020"
      }
    ]
  },
  {
    "name": "Frozen II",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Buck, Jennifer Lee"
      },
      {
        "key": "Box office",
        "value": "$1.453 billion"
      },
      {
        "key": "Release date",
        "value": "November 7, 2019"
      }
    ]
  },
  {
    "name": "The Croods",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Sanders, Kirk DeMicco"
      },
      {
        "key": "Box office",
        "value": "$587.2 million"
      },
      {
        "key": "Release date",
        "value": "February 15, 2013"
      }
    ]
  },
  {
    "name": "Shrek",
    "hints": [
      {
        "key": "Directed by",
        "value": "Andrew Adamson, Vicky Jenson"
      },
      {
        "key": "Box office",
        "value": "$492.2 million"
      },
      {
        "key": "Release date",
        "value": "April 22, 2001"
      }
    ]
  },
  {
    "name": "Ghost in the Shell",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mamoru Oshii"
      },
      {
        "key": "Box office",
        "value": "$10 million"
      },
      {
        "key": "Release date",
        "value": "18 November 1995"
      }
    ]
  },
  {
    "name": "The Addams Family 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Greg Tiernan, Conrad Vernon"
      },
      {
        "key": "Box office",
        "value": "$120 million"
      },
      {
        "key": "Release date",
        "value": "October 1, 2021"
      }
    ]
  },
  {
    "name": "Straw Dogs",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rod Lurie"
      },
      {
        "key": "Box office",
        "value": "$11.2 million"
      },
      {
        "key": "Release date",
        "value": "September 16, 2011"
      }
    ]
  },
  {
    "name": "Ice Age: Continental Drift",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steve Martino, Michael Thurmeier"
      },
      {
        "key": "Box office",
        "value": "$877.2 million"
      },
      {
        "key": "Release date",
        "value": "July 13, 2012"
      }
    ]
  },
  {
    "name": "Despicable Me",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Renaud, Pierre Coffin"
      },
      {
        "key": "Box office",
        "value": "$543.6 million"
      },
      {
        "key": "Release date",
        "value": "June 9, 2010"
      }
    ]
  },
  {
    "name": "Song of the Sea",
    "hints": [
      {
        "key": "Directed by",
        "value": "Tomm Moore"
      },
      {
        "key": "Box office",
        "value": "$9.3 million"
      },
      {
        "key": "Release date",
        "value": "6 September 2014"
      }
    ]
  },
  {
    "name": "Shrek Forever After",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mike Mitchell"
      },
      {
        "key": "Box office",
        "value": "$756.2 million"
      },
      {
        "key": "Release date",
        "value": "April 21, 2010"
      }
    ]
  },
  {
    "name": "Blade Runner 2049",
    "hints": [
      {
        "key": "Directed by",
        "value": "Denis Villeneuve"
      },
      {
        "key": "Box office",
        "value": "$276.6 million"
      },
      {
        "key": "Release date",
        "value": "October 3, 2017"
      }
    ]
  },
  {
    "name": "The Silence of the Lambs",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jonathan Demme"
      },
      {
        "key": "Box office",
        "value": "$272.7 million"
      },
      {
        "key": "Release date",
        "value": "January 30, 1991"
      }
    ]
  },
  {
    "name": "Madagascar 3: Europe's Most Wanted",
    "hints": [
      {
        "key": "Directed by",
        "value": "Eric Darnell, Conrad Vernon, Tom McGrath"
      },
      {
        "key": "Box office",
        "value": "$746.9 million"
      },
      {
        "key": "Release date",
        "value": "May 18, 2012"
      }
    ]
  },
  {
    "name": "Field of Dreams",
    "hints": [
      {
        "key": "Directed by",
        "value": "Phil Alden Robinson"
      },
      {
        "key": "Box office",
        "value": "$84.4 million"
      },
      {
        "key": "Release date",
        "value": "May 5, 1989"
      }
    ]
  },
  {
    "name": "Excalibur",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Boorman"
      },
      {
        "key": "Box office",
        "value": "$35 million"
      },
      {
        "key": "Release date",
        "value": "10 April 1981"
      }
    ]
  },
  {
    "name": "Princess Mononoke",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hayao Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$194.3 million"
      },
      {
        "key": "Release date",
        "value": "July 12, 1997"
      }
    ]
  },
  {
    "name": "Little Women",
    "hints": [
      {
        "key": "Directed by",
        "value": "Greta Gerwig"
      },
      {
        "key": "Box office",
        "value": "$218.9 million"
      },
      {
        "key": "Release date",
        "value": "December 7, 2019"
      }
    ]
  },
  {
    "name": "Good Will Hunting",
    "hints": [
      {
        "key": "Directed by",
        "value": "Gus Van Sant"
      },
      {
        "key": "Box office",
        "value": "$225.9 million"
      },
      {
        "key": "Release date",
        "value": "December 2, 1997"
      }
    ]
  },
  {
    "name": "Into the Woods",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rob Marshall"
      },
      {
        "key": "Box office",
        "value": "$213.1 million"
      },
      {
        "key": "Release date",
        "value": "December 8, 2014"
      }
    ]
  },
  {
    "name": "Chinatown",
    "hints": [
      {
        "key": "Directed by",
        "value": "Roman Polanski"
      },
      {
        "key": "Box office",
        "value": "$29.2 million"
      },
      {
        "key": "Release date",
        "value": "June 20, 1974"
      }
    ]
  },
  {
    "name": "The Boys from Brazil",
    "hints": [
      {
        "key": "Directed by",
        "value": "Franklin J. Schaffner"
      },
      {
        "key": "Box office",
        "value": "$19 million"
      },
      {
        "key": "Release date",
        "value": "October 6, 1978"
      }
    ]
  },
  {
    "name": "The Last Picture Show",
    "hints": [
      {
        "key": "Directed by",
        "value": "Peter Bogdanovich"
      },
      {
        "key": "Box office",
        "value": "$29.1 million"
      },
      {
        "key": "Release date",
        "value": "October 22, 1971"
      }
    ]
  },
  {
    "name": "Cinderella",
    "hints": [
      {
        "key": "Directed by",
        "value": "Wilfred Jackson, Hamilton Luske, Clyde Geronimi"
      },
      {
        "key": "Box office",
        "value": "$182 million"
      },
      {
        "key": "Release date",
        "value": "February 15, 1950"
      }
    ]
  },
  {
    "name": "Guardians of the Galaxy",
    "hints": [
      {
        "key": "Directed by",
        "value": "James Gunn"
      },
      {
        "key": "Box office",
        "value": "$773.3 million"
      },
      {
        "key": "Release date",
        "value": "July 21, 2014"
      }
    ]
  },
  {
    "name": "The Irishman",
    "hints": [
      {
        "key": "Directed by",
        "value": "Martin Scorsese"
      },
      {
        "key": "Box office",
        "value": "$8 million"
      },
      {
        "key": "Release date",
        "value": "September 27, 2019"
      }
    ]
  },
  {
    "name": "The Color Purple",
    "hints": [
      {
        "key": "Directed by",
        "value": "Blitz Bazawule"
      },
      {
        "key": "Box office",
        "value": "$67.5 million"
      },
      {
        "key": "Release date",
        "value": "November 20, 2023"
      }
    ]
  },
  {
    "name": "Despicable Me 3",
    "hints": [
      {
        "key": "Directed by",
        "value": "Pierre Coffin, Kyle Balda"
      },
      {
        "key": "Box office",
        "value": "$1.034 billion"
      },
      {
        "key": "Release date",
        "value": "June 14, 2017"
      }
    ]
  },
  {
    "name": "Shrek the Third",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Miller"
      },
      {
        "key": "Box office",
        "value": "$813.4 million"
      },
      {
        "key": "Release date",
        "value": "May 6, 2007"
      }
    ]
  },
  {
    "name": "Licorice Pizza",
    "hints": [
      {
        "key": "Directed by",
        "value": "Paul Thomas Anderson"
      },
      {
        "key": "Box office",
        "value": "$33.3 million"
      },
      {
        "key": "Release date",
        "value": "November 26, 2021"
      }
    ]
  },
  {
    "name": "Serpico",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sidney Lumet"
      },
      {
        "key": "Box office",
        "value": "$29.8 million"
      },
      {
        "key": "Release date",
        "value": "December 5, 1973"
      }
    ]
  },
  {
    "name": "The Angry Birds Movie 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Thurop Van Orman"
      },
      {
        "key": "Box office",
        "value": "$152.8 million"
      },
      {
        "key": "Release date",
        "value": "August 7, 2019"
      }
    ]
  },
  {
    "name": "Eragon",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stefen Fangmeier"
      },
      {
        "key": "Box office",
        "value": "$250.4 million"
      },
      {
        "key": "Release date",
        "value": "December 15, 2006"
      }
    ]
  },
  {
    "name": "Pocahontas",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mike GabrielEric Goldberg"
      },
      {
        "key": "Box office",
        "value": "$346.1 million"
      },
      {
        "key": "Release date",
        "value": "June 10, 1995"
      }
    ]
  },
  {
    "name": "Harry Potter and the Chamber of Secrets",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Columbus"
      },
      {
        "key": "Box office",
        "value": "$882.5 million"
      },
      {
        "key": "Release date",
        "value": "3 November 2002"
      }
    ]
  },
  {
    "name": "Maleficent",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Stromberg"
      },
      {
        "key": "Box office",
        "value": "$758.5 million"
      },
      {
        "key": "Release date",
        "value": "May 28, 2014"
      }
    ]
  },
  {
    "name": "The Great Escape",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Sturges"
      },
      {
        "key": "Box office",
        "value": "$11.7 million"
      },
      {
        "key": "Release date",
        "value": "June 20, 1963"
      }
    ]
  },
  {
    "name": "The French Connection",
    "hints": [
      {
        "key": "Directed by",
        "value": "William Friedkin"
      },
      {
        "key": "Box office",
        "value": "$75 million"
      },
      {
        "key": "Release date",
        "value": "October 7, 1971"
      }
    ]
  },
  {
    "name": "In This Corner of the World",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sunao Katabuchi"
      },
      {
        "key": "Box office",
        "value": "$22.5 million"
      },
      {
        "key": "Release date",
        "value": "October 28, 2016"
      }
    ]
  },
  {
    "name": "Police Academy",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hugh Wilson"
      },
      {
        "key": "Box office",
        "value": "$149.8 million"
      },
      {
        "key": "Release date",
        "value": "March 23, 1984"
      }
    ]
  },
  {
    "name": "It's a Wonderful Life",
    "hints": [
      {
        "key": "Directed by",
        "value": "Frank Capra"
      },
      {
        "key": "Box office",
        "value": "$3.3 million"
      },
      {
        "key": "Release date",
        "value": "December 20, 1946"
      }
    ]
  },
  {
    "name": "101 Dalmatians",
    "hints": [
      {
        "key": "Directed by",
        "value": "Stephen Herek"
      },
      {
        "key": "Box office",
        "value": "$320.7 million"
      },
      {
        "key": "Release date",
        "value": "November 18, 1996"
      }
    ]
  },
  {
    "name": "Secret Life of Pets",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Renaud"
      },
      {
        "key": "Box office",
        "value": "$885.3 million"
      },
      {
        "key": "Release date",
        "value": "June 16, 2016"
      }
    ]
  },
  {
    "name": "The Shawshank Redemption",
    "hints": [
      {
        "key": "Directed by",
        "value": "Frank Darabont"
      },
      {
        "key": "Box office",
        "value": "$73.3 million"
      },
      {
        "key": "Release date",
        "value": "September 10, 1994"
      }
    ]
  },
  {
    "name": "Madagascar: Escape 2 Africa",
    "hints": [
      {
        "key": "Directed by",
        "value": "Eric Darnell, Tom McGrath"
      },
      {
        "key": "Box office",
        "value": "$603.9 million"
      },
      {
        "key": "Release date",
        "value": "November 7, 2008"
      }
    ]
  },
  {
    "name": "Belfast",
    "hints": [
      {
        "key": "Directed by",
        "value": "Kenneth Branagh"
      },
      {
        "key": "Box office",
        "value": "$49 million"
      },
      {
        "key": "Release date",
        "value": "2 September 2021"
      }
    ]
  },
  {
    "name": "48 Hrs.",
    "hints": [
      {
        "key": "Directed by",
        "value": "Walter Hill"
      },
      {
        "key": "Box office",
        "value": "$78.9 million"
      },
      {
        "key": "Release date",
        "value": "December 8, 1982"
      }
    ]
  },
  {
    "name": "The Sound of Music",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Wise"
      },
      {
        "key": "Box office",
        "value": "$286.2 million"
      },
      {
        "key": "Release date",
        "value": "March 2, 1965"
      }
    ]
  },
  {
    "name": "The Man Who Fell to Earth",
    "hints": [
      {
        "key": "Directed by",
        "value": "Nicolas Roeg"
      },
      {
        "key": "Box office",
        "value": "$3 million"
      },
      {
        "key": "Release date",
        "value": "18 March 1976"
      }
    ]
  },
  {
    "name": "Parasite",
    "hints": [
      {
        "key": "Directed by",
        "value": "Bong Joon-ho"
      },
      {
        "key": "Box office",
        "value": "$262.1 million"
      },
      {
        "key": "Release date",
        "value": "21 May 2019"
      }
    ]
  },
  {
    "name": "The Lego Ninjago Movie",
    "hints": [
      {
        "key": "Directed by",
        "value": "Charlie Bean, Paul Fisher, Bob Logan"
      },
      {
        "key": "Box office",
        "value": "$123.1 million"
      },
      {
        "key": "Release date",
        "value": "16 September 2017"
      }
    ]
  },
  {
    "name": "Home Alone",
    "hints": [
      {
        "key": "Directed by",
        "value": "Chris Columbus"
      },
      {
        "key": "Box office",
        "value": "$476.7 million"
      },
      {
        "key": "Release date",
        "value": "November 10, 1990"
      }
    ]
  },
  {
    "name": "Toy Story",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Lasseter"
      },
      {
        "key": "Box office",
        "value": "$394.4 million"
      },
      {
        "key": "Release date",
        "value": "November 19, 1995"
      }
    ]
  },
  {
    "name": "Knives Out",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rian Johnson"
      },
      {
        "key": "Box office",
        "value": "$312.9 million"
      },
      {
        "key": "Release date",
        "value": "September 7, 2019"
      }
    ]
  },
  {
    "name": "Hotel Transylvania 3: Summer Vacation",
    "hints": [
      {
        "key": "Directed by",
        "value": "Genndy Tartakovsky"
      },
      {
        "key": "Box office",
        "value": "$528.6 million"
      },
      {
        "key": "Release date",
        "value": "June 13, 2018"
      }
    ]
  },
  {
    "name": "Ice Age: Collision Course",
    "hints": [
      {
        "key": "Directed by",
        "value": "Michael Thurmeier"
      },
      {
        "key": "Box office",
        "value": "$408.5 million"
      },
      {
        "key": "Release date",
        "value": "June 19, 2016"
      }
    ]
  },
  {
    "name": "Robin Hood",
    "hints": [
      {
        "key": "Directed by",
        "value": "Otto Bathurst"
      },
      {
        "key": "Box office",
        "value": "$86.5 million"
      },
      {
        "key": "Release date",
        "value": "November 21, 2018"
      }
    ]
  },
  {
    "name": "Mad Max 2: The Road Warrior",
    "hints": [
      {
        "key": "Directed by",
        "value": "George Miller"
      },
      {
        "key": "Box office",
        "value": "$36 million"
      },
      {
        "key": "Release date",
        "value": "24 December 1981"
      }
    ]
  },
  {
    "name": "Ice Age: The Meltdown",
    "hints": [
      {
        "key": "Directed by",
        "value": "Carlos Saldanha"
      },
      {
        "key": "Box office",
        "value": "$667 million"
      },
      {
        "key": "Release date",
        "value": "March 31, 2006"
      }
    ]
  },
  {
    "name": "Star Wars: The Rise of Skywalker",
    "hints": [
      {
        "key": "Directed by",
        "value": "J. J. Abrams"
      },
      {
        "key": "Box office",
        "value": "$1.077 billion"
      },
      {
        "key": "Release date",
        "value": "December 16, 2019"
      }
    ]
  },
  {
    "name": "Beauty and the Beast",
    "hints": [
      {
        "key": "Directed by",
        "value": "Bill Condon"
      },
      {
        "key": "Box office",
        "value": "$1.266 billion"
      },
      {
        "key": "Release date",
        "value": "February 23, 2017"
      }
    ]
  },
  {
    "name": "When Harry Met Sally",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rob Reiner"
      },
      {
        "key": "Box office",
        "value": "$92.8 million"
      },
      {
        "key": "Release date",
        "value": "July 14, 1989"
      }
    ]
  },
  {
    "name": "Bridge to Terabithia",
    "hints": [
      {
        "key": "Directed by",
        "value": "Gabor Csupo"
      },
      {
        "key": "Box office",
        "value": "$137.6 million"
      },
      {
        "key": "Release date",
        "value": "February 16, 2007"
      }
    ]
  },
  {
    "name": "Rocky",
    "hints": [
      {
        "key": "Directed by",
        "value": "Sylvester Stallone"
      },
      {
        "key": "Box office",
        "value": "$156.2 million"
      },
      {
        "key": "Release date",
        "value": "December 20, 2006"
      }
    ]
  },
  {
    "name": "The Bad News Bears",
    "hints": [
      {
        "key": "Directed by",
        "value": "Michael Ritchie"
      },
      {
        "key": "Box office",
        "value": "$42.3 million"
      },
      {
        "key": "Release date",
        "value": "April 7, 1976"
      }
    ]
  },
  {
    "name": "Weathering with You",
    "hints": [
      {
        "key": "Directed by",
        "value": "Makoto Shinkai"
      },
      {
        "key": "Box office",
        "value": "$193.8 million"
      },
      {
        "key": "Release date",
        "value": "July 19, 2019"
      }
    ]
  },
  {
    "name": "Scream",
    "hints": [
      {
        "key": "Directed by",
        "value": "Matt Bettinelli-Olpin, Tyler Gillett"
      },
      {
        "key": "Box office",
        "value": "$138.9 million"
      },
      {
        "key": "Release date",
        "value": "January 14, 2022"
      }
    ]
  },
  {
    "name": "Moonlight",
    "hints": [
      {
        "key": "Directed by",
        "value": "Barry Jenkins"
      },
      {
        "key": "Box office",
        "value": "$65.2 million"
      },
      {
        "key": "Release date",
        "value": "September 2, 2016"
      }
    ]
  },
  {
    "name": "Your Name",
    "hints": [
      {
        "key": "Directed by",
        "value": "Makoto Shinkai"
      },
      {
        "key": "Box office",
        "value": "$382.2 million"
      },
      {
        "key": "Release date",
        "value": "July 3, 2016"
      }
    ]
  },
  {
    "name": "The Borrower Arrietty",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hiromasa Yonebayashi"
      },
      {
        "key": "Box office",
        "value": "$146 million"
      },
      {
        "key": "Release date",
        "value": "17 July 2010"
      }
    ]
  },
  {
    "name": "Ralph Breaks the Internet",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rich Moore, Phil Johnston"
      },
      {
        "key": "Box office",
        "value": "$529.3 million"
      },
      {
        "key": "Release date",
        "value": "November 5, 2018"
      }
    ]
  },
  {
    "name": "Howl's Moving Castle",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hayao Miyazaki"
      },
      {
        "key": "Box office",
        "value": "$236 million"
      },
      {
        "key": "Release date",
        "value": "5 September 2004"
      }
    ]
  },
  {
    "name": "Shrek 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Andrew Adamson, Kelly Asbury, Conrad Vernon"
      },
      {
        "key": "Box office",
        "value": "$935.5 million"
      },
      {
        "key": "Release date",
        "value": "May 15, 2004"
      }
    ]
  },
  {
    "name": "Mirror Mirror",
    "hints": [
      {
        "key": "Directed by",
        "value": "Tarsem Singh"
      },
      {
        "key": "Box office",
        "value": "$183 million"
      },
      {
        "key": "Release date",
        "value": "March 30, 2012"
      }
    ]
  },
  {
    "name": "Monsters, Inc.",
    "hints": [
      {
        "key": "Directed by",
        "value": "Pete Docter"
      },
      {
        "key": "Box office",
        "value": "$579.7 million"
      },
      {
        "key": "Release date",
        "value": "October 28, 2001"
      }
    ]
  },
  {
    "name": "Big Fish & Begonia",
    "hints": [
      {
        "key": "Directed by",
        "value": "Liang Xuan, Zhang Chun"
      },
      {
        "key": "Box office",
        "value": "$79.9 million"
      },
      {
        "key": "Release date",
        "value": "8 July 2016"
      }
    ]
  },
  {
    "name": "The Godfather Part II",
    "hints": [
      {
        "key": "Directed by",
        "value": "Francis Ford Coppola"
      },
      {
        "key": "Box office",
        "value": "$93 million"
      },
      {
        "key": "Release date",
        "value": "December 12, 1974"
      }
    ]
  },
  {
    "name": "Dune",
    "hints": [
      {
        "key": "Directed by",
        "value": "Denis Villeneuve"
      },
      {
        "key": "Box office",
        "value": "$407 million"
      },
      {
        "key": "Release date",
        "value": "September 3, 2021"
      }
    ]
  },
  {
    "name": "Who Framed Roger Rabbit",
    "hints": [
      {
        "key": "Directed by",
        "value": "Robert Zemeckis"
      },
      {
        "key": "Box office",
        "value": "$351.5 million"
      },
      {
        "key": "Release date",
        "value": "June 21, 1988"
      }
    ]
  },
  {
    "name": "All Dogs Go to Heaven",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Bluth"
      },
      {
        "key": "Box office",
        "value": "$27.1 million"
      },
      {
        "key": "Release date",
        "value": "November 17, 1989"
      }
    ]
  },
  {
    "name": "The Usual Suspects",
    "hints": [
      {
        "key": "Directed by",
        "value": "Bryan Singer"
      },
      {
        "key": "Box office",
        "value": "$67 million"
      },
      {
        "key": "Release date",
        "value": "January 25, 1995"
      }
    ]
  },
  {
    "name": "The Warriors",
    "hints": [
      {
        "key": "Directed by",
        "value": "Walter Hill"
      },
      {
        "key": "Box office",
        "value": "$22.5 million"
      },
      {
        "key": "Release date",
        "value": "February 9, 1979"
      }
    ]
  },
  {
    "name": "Edward Scissorhands",
    "hints": [
      {
        "key": "Directed by",
        "value": "Tim Burton"
      },
      {
        "key": "Box office",
        "value": "$86 million"
      },
      {
        "key": "Release date",
        "value": "December 6, 1990"
      }
    ]
  },
  {
    "name": "Once Upon a Forest",
    "hints": [
      {
        "key": "Directed by",
        "value": "Charles Grosvenor"
      },
      {
        "key": "Box office",
        "value": "$6.6 million "
      },
      {
        "key": "Release date",
        "value": "June 18, 1993"
      }
    ]
  },
  {
    "name": "Rear Window",
    "hints": [
      {
        "key": "Directed by",
        "value": "Alfred Hitchcock"
      },
      {
        "key": "Box office",
        "value": "$37 million"
      },
      {
        "key": "Release date",
        "value": "August 4, 1954"
      }
    ]
  },
  {
    "name": "Platoon",
    "hints": [
      {
        "key": "Directed by",
        "value": "Oliver Stone"
      },
      {
        "key": "Box office",
        "value": "$138 million"
      },
      {
        "key": "Release date",
        "value": "December 19, 1986"
      }
    ]
  },
  {
    "name": "The Deer Hunter",
    "hints": [
      {
        "key": "Directed by",
        "value": "Michael Cimino"
      },
      {
        "key": "Box office",
        "value": "$49 million"
      },
      {
        "key": "Release date",
        "value": "December 8, 1978"
      }
    ]
  },
  {
    "name": "Once Upon a Time in Hollywood",
    "hints": [
      {
        "key": "Directed by",
        "value": "Quentin Tarantino"
      },
      {
        "key": "Box office",
        "value": "$377.6 million"
      },
      {
        "key": "Release date",
        "value": "May 21, 2019"
      }
    ]
  },
  {
    "name": "How to Train Your Dragon: The Hidden World",
    "hints": [
      {
        "key": "Directed by",
        "value": "Dean DeBlois"
      },
      {
        "key": "Box office",
        "value": "$525.7 million"
      },
      {
        "key": "Release date",
        "value": "January 3, 2019"
      }
    ]
  },
  {
    "name": "Sunset Boulevard",
    "hints": [
      {
        "key": "Directed by",
        "value": "Billy Wilder"
      },
      {
        "key": "Box office",
        "value": "$5 million"
      },
      {
        "key": "Release date",
        "value": "August 10, 1950"
      }
    ]
  },
  {
    "name": "Invasion of the Body Snatchers",
    "hints": [
      {
        "key": "Directed by",
        "value": "Don Siegel"
      },
      {
        "key": "Box office",
        "value": "$3 million"
      },
      {
        "key": "Release date",
        "value": "February 5, 1956"
      }
    ]
  },
  {
    "name": "Camelot",
    "hints": [
      {
        "key": "Directed by",
        "value": "Joshua Logan"
      },
      {
        "key": "Box office",
        "value": "$31.5 million"
      },
      {
        "key": "Release date",
        "value": "October 25, 1967"
      }
    ]
  },
  {
    "name": "Aladdin",
    "hints": [
      {
        "key": "Directed by",
        "value": "Guy Ritchie"
      },
      {
        "key": "Box office",
        "value": "$1.054 billion"
      },
      {
        "key": "Release date",
        "value": "May 8, 2019"
      }
    ]
  },
  {
    "name": "Se7en",
    "hints": [
      {
        "key": "Directed by",
        "value": "David Fincher"
      },
      {
        "key": "Box office",
        "value": "$327.3 million"
      },
      {
        "key": "Release date",
        "value": "September 22, 1995"
      }
    ]
  },
  {
    "name": "Avengers: Infinity War",
    "hints": [
      {
        "key": "Directed by",
        "value": "Anthony Russo, Joe Russo"
      },
      {
        "key": "Box office",
        "value": "$2.052 billion"
      },
      {
        "key": "Release date",
        "value": "April 23, 2018"
      }
    ]
  },
  {
    "name": "Detective Pikachu",
    "hints": [
      {
        "key": "Directed by",
        "value": "Rob Letterman"
      },
      {
        "key": "Box office",
        "value": "$450.1 million"
      },
      {
        "key": "Release date",
        "value": "May 3, 2019"
      }
    ]
  },
  {
    "name": "Jurassic Park",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steven Spielberg"
      },
      {
        "key": "Box office",
        "value": "$1.058 billion"
      },
      {
        "key": "Release date",
        "value": "June 9, 1993"
      }
    ]
  },
  {
    "name": "Alien",
    "hints": [
      {
        "key": "Directed by",
        "value": "Ridley Scott"
      },
      {
        "key": "Box office",
        "value": "$184.7 million"
      },
      {
        "key": "Release date",
        "value": "May 25, 1979"
      }
    ]
  },
  {
    "name": "Monty Python's Life of Brian",
    "hints": [
      {
        "key": "Directed by",
        "value": "Terry Jones"
      },
      {
        "key": "Box office",
        "value": "$20.7 million"
      },
      {
        "key": "Release date",
        "value": "17 August 1979"
      }
    ]
  },
  {
    "name": "No Time to Die",
    "hints": [
      {
        "key": "Directed by",
        "value": "Cary Joji Fukunaga"
      },
      {
        "key": "Box office",
        "value": "$774.2 million"
      },
      {
        "key": "Release date",
        "value": "28 September 2021"
      }
    ]
  },
  {
    "name": "Blade Runner",
    "hints": [
      {
        "key": "Directed by",
        "value": "Ridley Scott"
      },
      {
        "key": "Box office",
        "value": "$41.8 million"
      },
      {
        "key": "Release date",
        "value": "June 25, 1982"
      }
    ]
  },
  {
    "name": "The Pink Panther Strikes Again",
    "hints": [
      {
        "key": "Directed by",
        "value": "Blake Edwards"
      },
      {
        "key": "Box office",
        "value": "$75 million"
      },
      {
        "key": "Release date",
        "value": "15 December 1976"
      }
    ]
  },
  {
    "name": "My Fair Lady",
    "hints": [
      {
        "key": "Directed by",
        "value": "George Cukor"
      },
      {
        "key": "Box office",
        "value": "$72.7 million"
      },
      {
        "key": "Release date",
        "value": "October 21, 1964"
      }
    ]
  },
  {
    "name": "The Incredibles",
    "hints": [
      {
        "key": "Directed by",
        "value": "Brad Bird"
      },
      {
        "key": "Box office",
        "value": "$631.6 million"
      },
      {
        "key": "Release date",
        "value": "October 24, 2004"
      }
    ]
  },
  {
    "name": "Encanto",
    "hints": [
      {
        "key": "Directed by",
        "value": "Jared BushByron Howard"
      },
      {
        "key": "Box office",
        "value": "$256.8 million"
      },
      {
        "key": "Release date",
        "value": "November 3, 2021"
      }
    ]
  },
  {
    "name": "Gladiator",
    "hints": [
      {
        "key": "Directed by",
        "value": "Ridley Scott"
      },
      {
        "key": "Box office",
        "value": "$465.4 million"
      },
      {
        "key": "Release date",
        "value": "May 1, 2000"
      }
    ]
  },
  {
    "name": "The Secret World of Arrietty",
    "hints": [
      {
        "key": "Directed by",
        "value": "Hiromasa Yonebayashi"
      },
      {
        "key": "Box office",
        "value": "$146 million"
      },
      {
        "key": "Release date",
        "value": "17 July 2010"
      }
    ]
  },
  {
    "name": "Kung Fu Panda",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Stevenson, Mark Osborne"
      },
      {
        "key": "Box office",
        "value": "$631.7 million"
      },
      {
        "key": "Release date",
        "value": "May 15, 2008"
      }
    ]
  },
  {
    "name": "The Karate Kid",
    "hints": [
      {
        "key": "Directed by",
        "value": "John G. Avildsen"
      },
      {
        "key": "Box office",
        "value": "$130.8 million"
      },
      {
        "key": "Release date",
        "value": "June 22, 1984"
      }
    ]
  },
  {
    "name": "A Silent Voice",
    "hints": [
      {
        "key": "Directed by",
        "value": "Naoko Yamada"
      },
      {
        "key": "Box office",
        "value": "$31.6 million"
      },
      {
        "key": "Release date",
        "value": "September 17, 2016"
      }
    ]
  },
  {
    "name": "Schindler's List",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steven Spielberg"
      },
      {
        "key": "Box office",
        "value": "$322.2 million"
      },
      {
        "key": "Release date",
        "value": "November 30, 1993"
      }
    ]
  },
  {
    "name": "The Breakfast Club",
    "hints": [
      {
        "key": "Directed by",
        "value": "John Hughes"
      },
      {
        "key": "Box office",
        "value": "$51.5 million"
      },
      {
        "key": "Release date",
        "value": "February 7, 1985"
      }
    ]
  },
  {
    "name": "The Peanuts Movie",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steve Martino"
      },
      {
        "key": "Box office",
        "value": "$246.2 million"
      },
      {
        "key": "Release date",
        "value": "November 1, 2015"
      }
    ]
  },
  {
    "name": "Trolls World Tour",
    "hints": [
      {
        "key": "Directed by",
        "value": "Walt Dohrn"
      },
      {
        "key": "Box office",
        "value": "$48 million"
      },
      {
        "key": "Release date",
        "value": "April 10, 2020"
      }
    ]
  },
  {
    "name": "Trolls",
    "hints": [
      {
        "key": "Directed by",
        "value": "Mike Mitchell"
      },
      {
        "key": "Box office",
        "value": "$347 million"
      },
      {
        "key": "Release date",
        "value": "October 8, 2016"
      }
    ]
  },
  {
    "name": "Cloudy with a Chance of Meatballs 2",
    "hints": [
      {
        "key": "Directed by",
        "value": "Cody Cameron, Kris Pearn"
      },
      {
        "key": "Box office",
        "value": "$274.3 million"
      },
      {
        "key": "Release date",
        "value": "September 27, 2013"
      }
    ]
  },
  {
    "name": "Finding Nemo",
    "hints": [
      {
        "key": "Directed by",
        "value": "Andrew Stanton"
      },
      {
        "key": "Box office",
        "value": "$940.3 million"
      },
      {
        "key": "Release date",
        "value": "May 18, 2003"
      }
    ]
  },
  {
    "name": "The Exorcist",
    "hints": [
      {
        "key": "Directed by",
        "value": "David Gordon Green"
      },
      {
        "key": "Box office",
        "value": "$137 million"
      },
      {
        "key": "Release date",
        "value": "October 6, 2023"
      }
    ]
  },
  {
    "name": "Raging Bull",
    "hints": [
      {
        "key": "Directed by",
        "value": "Martin Scorsese"
      },
      {
        "key": "Box office",
        "value": "$23.4 million"
      },
      {
        "key": "Release date",
        "value": "November 14, 1980"
      }
    ]
  },
  {
    "name": "Indiana Jones and the Temple of Doom",
    "hints": [
      {
        "key": "Directed by",
        "value": "Steven Spielberg"
      },
      {
        "key": "Box office",
        "value": "$333.1 million"
      },
      {
        "key": "Release date",
        "value": "May 8, 1984"
      }
    ]
  },
  {
    "name": "Annie Hall",
    "hints": [
      {
        "key": "Directed by",
        "value": "Woody Allen"
      },
      {
        "key": "Box office",
        "value": "$44 million"
      },
      {
        "key": "Release date",
        "value": "March 27, 1977"
      }
    ]
  }
]