import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import { Link } from 'react-router-dom';

export default function Home() {

  const buttonStyle = {
    border: '2px solid #ccc',
    width: '350px',
    padding: '10px',
    margin: '10px',
    borderRadius: '50px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      borderRadius: '50px',
    },
  };

  const textStyle = {
    fontSize: '18px',
    fontWeight: '100',
  }

  return (
    <>
      <div style={{ marginTop: 40, textAlign: 'center' }}>
        <Button component={Link} to='/album' sx={buttonStyle}>
          <Typography sx={textStyle}>Album Cover</Typography>
        </Button>
        <Button component={Link} to='/celebrity' sx={buttonStyle}>
          <Typography sx={textStyle}>Celebrity</Typography>
        </Button>
        <Button component={Link} to='/game' sx={buttonStyle}>
          <Typography sx={textStyle}>Video Game</Typography>
        </Button>
        <Button component={Link} to='/movie' sx={buttonStyle}>
          <Typography sx={textStyle}>Movie Poster</Typography>
        </Button>
      </div>
    </>
  )
}