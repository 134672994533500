import CloseIcon from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';

export default function Guess({ guess }) {

  const style = {
    color: 'white',
    margin: '2px',
    background: guess.isCorrect ? 'green' : '#d32f2f',
  };

  return (
    <Chip
      icon={guess.isCorrect ? <Check color="white" /> : <CloseIcon color="white" />}
      label={guess.text}
      sx={style}
    />
  )
}