import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Home from './pages/Home';
import Game from './pages/Game';
import Privacy from './pages/Privacy'
import Page404 from './pages/404';
import Settings from "./components/Settings";
import { GAME_ANSWERS } from "./config/Games";
import { ALBUM_ANSWERS } from "./config/Albums";
import { MOVIE_ANSWERS } from './config/Movies';
import { CELEBRITY_ANSWERS } from './config/Celebrities';
import { Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from "@mui/material/Typography";
import { PREFERENCES } from "./config/Constants";
import './css/App.css';

function App() {

  const [darkMode, setDarkMode] = useState(false);
  const [hintPreferences, setHintPreferences] = useState({
    album: true,
    celebrity: true,
    game: true,
    movie: true,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const preferences = localStorage.getItem(PREFERENCES);
    if (preferences) {
      const { darkModeEnabled, hintPreferences } = JSON.parse(preferences);
      setDarkMode(darkModeEnabled);
      setHintPreferences(hintPreferences);
    } else {
      localStorage.setItem(PREFERENCES, JSON.stringify({ darkModeEnabled: false, hintPreferences: hintPreferences }));
    }
    setIsLoading(false);
  }, []);

  if (isLoading) return;

  const toggleDarkMode = () => {
    const updatedDarkMode = !darkMode;
    setDarkMode(updatedDarkMode);
    localStorage.setItem(PREFERENCES, JSON.stringify({ darkModeEnabled: updatedDarkMode, hintPreferences: hintPreferences }));
  }

  const toggleHints = (type) => {
    const updatedHintPreferences = {
      ...hintPreferences,
      [type]: !hintPreferences[type]
    };
    setHintPreferences(updatedHintPreferences);
    localStorage.setItem(PREFERENCES, JSON.stringify({ darkModeEnabled: darkMode, hintPreferences: updatedHintPreferences }));
  }

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#2196f3',
        contrastText: 'white',
      }
    },
    typography: {
      allVariants: {
        color: darkMode ? 'white' : '#424242',
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='container'>
          <Router>
            <div className='top-nav'>
              <div className='menu-btn'>
                <Menu />
              </div>
              <div className='title'>
                <Link to='/' style={{ textDecoration: 'none' }}>
                  <Typography sx={{ fontSize: '30px', fontWeight: '900' }}>DAILY<img style={{ position: 'relative', top: '4px', height: '30px', padding: '0 5px 0 5px' }} src="/logo.png" />BLUR</Typography>
                </Link>
              </div>
              <div className='settings'>
                <Settings darkMode={darkMode}
                  toggleDarkMode={toggleDarkMode}
                  hintPreferences={hintPreferences}
                  toggleHints={toggleHints} />
              </div>
            </div>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/album' element=
                {<Game type='album' answers={ALBUM_ANSWERS} key="album" hints={hintPreferences.album} />}
              />
              <Route path='/celebrity' element=
                {<Game type='celebrity' answers={CELEBRITY_ANSWERS} key="celebrity" hints={hintPreferences.celebrity} />}
              />
              <Route path='/game' element=
                {<Game type='game' answers={GAME_ANSWERS} key="game" hints={hintPreferences.game} />}
              />
              <Route path='/movie' element=
                {<Game type='movie' answers={MOVIE_ANSWERS} key="movie" hints={hintPreferences.movie} />}
              />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='*' element={<Page404 />} />
            </Routes>
          </Router>
        </div >
      </ThemeProvider >
    </>
  );
}

export default App;
