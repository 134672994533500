import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';

export const PREFERENCES = 'preferences';
export const GUESSES_REMAINING = 4;
export const GAME_STATES = {
  WIN: 'Win',
  LOSS: 'Loss',
  PLAYING: 'Playing',
};
export const BLUR_STATES = [13, 10, 7, 4];

export const RENDER_GUESSES = (guesses) => {
  return Array.from({ length: GUESSES_REMAINING }, (_, index) => {
    const guess = guesses[index];
    if (guess && guess.isCorrect) {
      return <CheckCircleIcon key={index} sx={{ color: 'green' }} />
    } else if (guess && !guesses[index.isCorrect]) {
      return <CancelIcon key={index} sx={{ color: '#d32f2f' }} />
    } else {
      return <CircleIcon key={index} />
    }
  });
};

export const calculateTimeUntilMidnight = () => {
  const now = new Date();
  const midnight = new Date().setHours(23, 59, 59, 0);
  const timeRemaining = midnight - now;
  return timeRemaining > 0 ? timeRemaining : 0;
};