export const CELEBRITY_ANSWERS = [
  {
    "name": "Neil Flynn",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1960" },
      { "key": "Known for", "value": "Scrubs" }
    ]
  },
  {
    "name": "Phyllis Smith",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1951" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "John Benjamin Hickey",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "The Big C" }
    ]
  },
  {
    "name": "Nick Zano",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1978" },
      { "key": "Known for", "value": "DC's Legends of Tomorrow" }
    ]
  },
  {
    "name": "Meryl Streep",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1949" },
      { "key": "Known for", "value": "The Devil Wears Prada" }
    ]
  },
  {
    "name": "Mary-Kate Olsen",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1986" },
      { "key": "Known for", "value": "Full House" }
    ]
  },
  {
    "name": "Miranda Lambert",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1983" },
      { "key": "Known for", "value": "The House That Built Me" }
    ]
  },
  {
    "name": "Ryan Reynolds",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1976" },
      { "key": "Known for", "value": "Deadpool" }
    ]
  },
  {
    "name": "Gwyneth Paltrow",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1972" },
      { "key": "Known for", "value": "Shakespeare in Love" }
    ]
  },
  {
    "name": "Gwen Stefani",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1969" },
      { "key": "Known for", "value": "No Doubt" }
    ]
  },
  {
    "name": "Rebel Wilson",
    "hints": [
      { "key": "Nationality", "value": "Australian" },
      { "key": "Birthyear", "value": "1980" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "Nicole Kidman",
    "hints": [
      { "key": "Nationality", "value": "Australian, American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Moulin Rouge!" }
    ]
  },
  {
    "name": "Neil Finn",
    "hints": [
      { "key": "Nationality", "value": "New Zealander" },
      { "key": "Birthyear", "value": "1958" },
      { "key": "Known for", "value": "Crowded House" }
    ]
  },
  {
    "name": "Mike Campbell",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1950" },
      { "key": "Known for", "value": "Tom Petty and the Heartbreakers" }
    ]
  },
  {
    "name": "David Krumholtz",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1978" },
      { "key": "Known for", "value": "Numb3rs" }
    ]
  },
  {
    "name": "Judy Reyes",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Scrubs" }
    ]
  },
  {
    "name": "Brie Larson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1989" },
      { "key": "Known for", "value": "Room" }
    ]
  },
  {
    "name": "Townes Van Zandt",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1944" },
      { "key": "Known for", "value": "Pancho and Lefty" }
    ]
  },
  {
    "name": "Christopher Mintz-Plasse",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1989" },
      { "key": "Known for", "value": "Superbad" }
    ]
  },
  {
    "name": "Chris Parnell",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Saturday Night Live" }
    ]
  },
  {
    "name": "Christine McVie",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1943" },
      { "key": "Known for", "value": "Fleetwood Mac" }
    ]
  },
  {
    "name": "Jon Hamm",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "Mad Men" }
    ]
  },
  {
    "name": "Brittany Snow",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1986" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "Oprah Winfrey",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1954" },
      { "key": "Known for", "value": "Hosting a Hosting a talk show" }
    ]
  },
  {
    "name": "Cate Blanchett",
    "hints": [
      { "key": "Nationality", "value": "Australian" },
      { "key": "Birthyear", "value": "1969" },
      { "key": "Known for", "value": "The Lord of the Rings" }
    ]
  },
  {
    "name": "Jodie Sweetin",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Full House" }
    ]
  },
  {
    "name": "Blake Lively",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1987" },
      { "key": "Known for", "value": "Gossip Girl" }
    ]
  },
  {
    "name": "Morgan Freeman",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1937" },
      { "key": "Known for", "value": "The Shawshank Redemption" }
    ]
  },
  {
    "name": "Jenna Fischer",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Conway Twitty",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1933" },
      { "key": "Known for", "value": "Hello Darlin'" }
    ]
  },
  {
    "name": "Vince Gill",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1957" },
      { "key": "Known for", "value": "When I Call Your Name" }
    ]
  },
  {
    "name": "Jeremy Spencer",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1948" },
      { "key": "Known for", "value": "Fleetwood Mac" }
    ]
  },
  {
    "name": "Rihanna",
    "hints": [
      { "key": "Nationality", "value": "Barbadian" },
      { "key": "Birthyear", "value": "1988" },
      { "key": "Known for", "value": "Umbrella" }
    ]
  },
  {
    "name": "Oscar Nunez",
    "hints": [
      { "key": "Nationality", "value": "American, Cuban" },
      { "key": "Birthyear", "value": "1958" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Alexis Knapp",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1989" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "Natalie Portman",
    "hints": [
      { "key": "Nationality", "value": "American, Israeli" },
      { "key": "Birthyear", "value": "1981" },
      { "key": "Known for", "value": "Black Swan" }
    ]
  },
  {
    "name": "Rob Lowe",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "Parks and Recreation" }
    ]
  },
  {
    "name": "Jerry Stiller",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1927" },
      { "key": "Known for", "value": "Seinfeld" }
    ]
  },
  {
    "name": "George Strait",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1952" },
      { "key": "Known for", "value": "All My Ex's Live in Texas" }
    ]
  },
  {
    "name": "Paul Hester",
    "hints": [
      { "key": "Nationality", "value": "New Zealander" },
      { "key": "Birthyear", "value": "1959" },
      { "key": "Known for", "value": "Crowded House" }
    ]
  },
  {
    "name": "Lindsey Buckingham",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1949" },
      { "key": "Known for", "value": "Fleetwood Mac" }
    ]
  },
  {
    "name": "Amy Adams",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "Enchanted" }
    ]
  },
  {
    "name": "Donald Faison",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "Scrubs" }
    ]
  },
  {
    "name": "Hailee Steinfeld",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1996" },
      { "key": "Known for", "value": "True Grit" }
    ]
  },
  {
    "name": "Elisabeth Moss",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "The Handmaid's Tale" }
    ]
  },
  {
    "name": "Ellen DeGeneres",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1958" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "Aubrey Plaza",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1984" },
      { "key": "Known for", "value": "Parks and Recreation" }
    ]
  },
  {
    "name": "Vin Diesel",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Fast & Furious" }
    ]
  },
  {
    "name": "Emmylou Harris",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1947" },
      { "key": "Known for", "value": "Pieces of the Sky" }
    ]
  },
  {
    "name": "Sandra Bullock",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "The Blind Side" }
    ]
  },
  {
    "name": "Hank Williams",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1923" },
      { "key": "Known for", "value": "Your Cheatin' Heart" }
    ]
  },
  {
    "name": "Karen Gillan",
    "hints": [
      { "key": "Nationality", "value": "Scottish" },
      { "key": "Birthyear", "value": "1987" },
      { "key": "Known for", "value": "Guardians of the Galaxy" }
    ]
  },
  {
    "name": "John Michael Higgins",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "Joaquin Phoenix",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "Joker" }
    ]
  },
  {
    "name": "Tom Cavanagh",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "Ed" }
    ]
  },
  {
    "name": "Ben Affleck",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1972" },
      { "key": "Known for", "value": "Good Will Hunting" }
    ]
  },
  {
    "name": "Conan O'Brien",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "Kacey Musgraves",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1988" },
      { "key": "Known for", "value": "Golden Hour" }
    ]
  },
  {
    "name": "Roger Miller",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1936" },
      { "key": "Known for", "value": "King of the Road" }
    ]
  },
  {
    "name": "Grant Gustin",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1990" },
      { "key": "Known for", "value": "The Flash" }
    ]
  },
  {
    "name": "Matt Smith",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Neil Patrick Harris",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1973" },
      { "key": "Known for", "value": "How I Met Your Mother" }
    ]
  },
  {
    "name": "Orlando Bloom",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1977" },
      { "key": "Known for", "value": "Pirates of the Caribbean" }
    ]
  },
  {
    "name": "Britney Spears",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1981" },
      { "key": "Known for", "value": "...Baby One More Time" }
    ]
  },
  {
    "name": "Tala Ashe",
    "hints": [
      { "key": "Nationality", "value": "Iranian-American" },
      { "key": "Birthyear", "value": "1984" },
      { "key": "Known for", "value": "Legends of Tomorrow" }
    ]
  },
  {
    "name": "Guy Clark",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1941" },
      { "key": "Known for", "value": "Desperados Waiting for a Train" }
    ]
  },
  {
    "name": "Jessi Colter",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1943" },
      { "key": "Known for", "value": "I'm Not Lisa" }
    ]
  },
  {
    "name": "Megan Mullally",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1958" },
      { "key": "Known for", "value": "Will & Grace" }
    ]
  },
  {
    "name": "Jay Leno",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1950" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "Lefty Frizzell",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1928" },
      { "key": "Known for", "value": "Long Black Veil" }
    ]
  },
  {
    "name": "Glen Campbell",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1936" },
      { "key": "Known for", "value": "Rhinestone Cowboy" }
    ]
  },
  {
    "name": "Christopher Eccleston",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Pharrell Williams",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1973" },
      { "key": "Known for", "value": "Happy" }
    ]
  },
  {
    "name": "Kiernan Shipka",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1999" },
      { "key": "Known for", "value": "Chilling Adventures of Sabrina" }
    ]
  },
  {
    "name": "Estelle Harris",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1928" },
      { "key": "Known for", "value": "Toy Story series" }
    ]
  },
  {
    "name": "Johnny Cash",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1932" },
      { "key": "Known for", "value": "Ring of Fire" }
    ]
  },
  {
    "name": "Hugh Jackman",
    "hints": [
      { "key": "Nationality", "value": "Australian" },
      { "key": "Birthyear", "value": "1968" },
      { "key": "Known for", "value": "Logan" }
    ]
  },
  {
    "name": "Seth Meyers",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1973" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "George Jones",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1931" },
      { "key": "Known for", "value": "He Stopped Loving Her Today" }
    ]
  },
  {
    "name": "Mel Tillis",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1932" },
      { "key": "Known for", "value": "Coca Cola Cowboy" }
    ]
  },
  {
    "name": "Carrie Underwood",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1983" },
      { "key": "Known for", "value": "Before He Cheats" }
    ]
  },
  {
    "name": "Danny Pudi",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "Community" }
    ]
  },
  {
    "name": "Ben Schwartz",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1981" },
      { "key": "Known for", "value": "Parks and Recreation" }
    ]
  },
  {
    "name": "Mandip Gill",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1988" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Jodie Whittaker",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Andy Serkis",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "The Lord of the Rings" }
    ]
  },
  {
    "name": "Jimmy Kimmel",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Jimmy Kimmel Live!" }
    ]
  },
  {
    "name": "Steve Carell",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1962" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "John Barrowman",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Mick Fleetwood",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1947" },
      { "key": "Known for", "value": "Fleetwood Mac" }
    ]
  },
  {
    "name": "Emily Bett Rickards",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1991" },
      { "key": "Known for", "value": "Arrow" }
    ]
  },
  {
    "name": "Jerry Reed",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1937" },
      { "key": "Known for", "value": "East Bound and Down" }
    ]
  },
  {
    "name": "Billy Ray Cyrus",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1961" },
      { "key": "Known for", "value": "Achy Breaky Heart" }
    ]
  },
  {
    "name": "Michelle Gomez",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1966" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "James Franco",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1978" },
      { "key": "Known for", "value": "127 Hours" }
    ]
  },
  {
    "name": "Arthur Darvill",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Samuel L. Jackson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1948" },
      { "key": "Known for", "value": "Pulp Fiction" }
    ]
  },
  {
    "name": "Channing Tatum",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1980" },
      { "key": "Known for", "value": "Magic Mike" }
    ]
  },
  {
    "name": "Billy Joe Shaver",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1939" },
      { "key": "Known for", "value": "Old Five and Dimers Like Me" }
    ]
  },
  {
    "name": "Kris Kristofferson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1936" },
      { "key": "Known for", "value": "Me and Bobby McGee" }
    ]
  },
  {
    "name": "Wayne Knight",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1955" },
      { "key": "Known for", "value": "Seinfeld" }
    ]
  },
  {
    "name": "Kathryn Hahn",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1973" },
      { "key": "Known for", "value": "Bad Moms" }
    ]
  },
  {
    "name": "Benedict Cumberbatch",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1976" },
      { "key": "Known for", "value": "Sherlock" }
    ]
  },
  {
    "name": "Yvette Nicole Brown",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "Community" }
    ]
  },
  {
    "name": "Dolly Parton",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1946" },
      { "key": "Known for", "value": "9 to 5" }
    ]
  },
  {
    "name": "Trevor Noah",
    "hints": [
      { "key": "Nationality", "value": "South African" },
      { "key": "Birthyear", "value": "1984" },
      { "key": "Known for", "value": "The Daily Show" }
    ]
  },
  {
    "name": "Ariana Grande",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1993" },
      { "key": "Known for", "value": "Thank U, Next" }
    ]
  },
  {
    "name": "Tammy Wynette",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1942" },
      { "key": "Known for", "value": "Stand by Your Man" }
    ]
  },
  {
    "name": "Joe Ely",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1947" },
      { "key": "Known for", "value": "Musta Notta Gotta Lotta" }
    ]
  },
  {
    "name": "Jason Aldean",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1977" },
      { "key": "Known for", "value": "Dirt Road Anthem" }
    ]
  },
  {
    "name": "Chevy Chase",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1943" },
      { "key": "Known for", "value": "National Lampoon's Vacation" }
    ]
  },
  {
    "name": "John C. McGinley",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1959" },
      { "key": "Known for", "value": "Scrubs" }
    ]
  },
  {
    "name": "Bryan Cranston",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1956" },
      { "key": "Known for", "value": "Breaking Bad" }
    ]
  },
  {
    "name": "Adam Scott",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1973" },
      { "key": "Known for", "value": "Parks and Recreation" }
    ]
  },
  {
    "name": "Drew Barrymore",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1975" },
      { "key": "Known for", "value": "E.T. the Extra-Terrestrial" }
    ]
  },
  {
    "name": "Willa Holland",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1991" },
      { "key": "Known for", "value": "Arrow" }
    ]
  },
  {
    "name": "Robert De Niro",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1943" },
      { "key": "Known for", "value": "Taxi Driver" }
    ]
  },
  {
    "name": "Brandon Routh",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "Superman Returns" }
    ]
  },
  {
    "name": "Leonardo DiCaprio",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "Titanic" }
    ]
  },
  {
    "name": "Emma Stone",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1988" },
      { "key": "Known for", "value": "La La Land" }
    ]
  },
  {
    "name": "Skylar Astin",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1987" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "Vincent Kartheiser",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "Mad Men" }
    ]
  },
  {
    "name": "Bradley Walsh",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1960" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Caity Lotz",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1986" },
      { "key": "Known for", "value": "Legends of Tomorrow" }
    ]
  },
  {
    "name": "Ernest Tubb",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1914" },
      { "key": "Known for", "value": "Walking the Floor Over You" }
    ]
  },
  {
    "name": "Faith Hill",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Breathe" }
    ]
  },
  {
    "name": "Tom Cruise",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1962" },
      { "key": "Known for", "value": "Top Gun" }
    ]
  },
  {
    "name": "John Hurt",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1940" },
      { "key": "Known for", "value": "The Elephant Man" }
    ]
  },
  {
    "name": "Gillian Jacobs",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Community" }
    ]
  },
  {
    "name": "Cobie Smulders",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "How I Met Your Mother" }
    ]
  },
  {
    "name": "Martina McBride",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1966" },
      { "key": "Known for", "value": "Independence Day" }
    ]
  },
  {
    "name": "Juice Newton",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1952" },
      { "key": "Known for", "value": "Angel of the Morning" }
    ]
  },
  {
    "name": "Renee Zellweger",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1969" },
      { "key": "Known for", "value": "Bridget Jones's Diary" }
    ]
  },
  {
    "name": "Peter Capaldi",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1958" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Donald Glover",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1983" },
      { "key": "Known for", "value": "Atlanta" }
    ]
  },
  {
    "name": "Dave Franco",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1985" },
      { "key": "Known for", "value": "Now You See Me" }
    ]
  },
  {
    "name": "Sara Evans",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "A Little Bit Stronger" }
    ]
  },
  {
    "name": "Ian McKellen",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1939" },
      { "key": "Known for", "value": "The Lord of the Rings" }
    ]
  },
  {
    "name": "Sam Lloyd",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "Scrubs" }
    ]
  },
  {
    "name": "Anne Murray",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1945" },
      { "key": "Known for", "value": "Snowbird" }
    ]
  },
  {
    "name": "Elijah Wood",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1981" },
      { "key": "Known for", "value": "The Lord of the Rings" }
    ]
  },
  {
    "name": "Miley Cyrus",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1992" },
      { "key": "Known for", "value": "Hannah Montana" }
    ]
  },
  {
    "name": "Sheryl Crow",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1962" },
      { "key": "Known for", "value": "All I Wanna Do" }
    ]
  },
  {
    "name": "Alex Kingston",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "ER" }
    ]
  },
  {
    "name": "Kate Flannery",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Angela Kinsey",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Brian Baumgartner",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1972" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Amber Stevens West",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1986" },
      { "key": "Known for", "value": "22 Jump Street" }
    ]
  },
  {
    "name": "Hana Mae Lee",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1988" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "Billie Piper",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Tim McGraw",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Live Like You Were Dying" }
    ]
  },
  {
    "name": "Jerry Seinfeld",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1954" },
      { "key": "Known for", "value": "Seinfeld" }
    ]
  },
  {
    "name": "Fred Willard",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1933" },
      { "key": "Known for", "value": "Best in Show" }
    ]
  },
  {
    "name": "Merle Haggard",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1937" },
      { "key": "Known for", "value": "Okie from Muskogee" }
    ]
  },
  {
    "name": "Lori Loughlin",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "Full House" }
    ]
  },
  {
    "name": "John C. Reilly",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1965" },
      { "key": "Known for", "value": "Chicago" }
    ]
  },
  {
    "name": "Jim O'Heir",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1962" },
      { "key": "Known for", "value": "Parks and Recreation" }
    ]
  },
  {
    "name": "Julia Louis-Dreyfus",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1961" },
      { "key": "Known for", "value": "Seinfeld" }
    ]
  },
  {
    "name": "Robert Downey Jr.",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1965" },
      { "key": "Known for", "value": "Iron Man" }
    ]
  },
  {
    "name": "Jorma Taccone",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1977" },
      { "key": "Known for", "value": "The Lonely Island" }
    ]
  },
  {
    "name": "Clint Eastwood",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1930" },
      { "key": "Known for", "value": "The Good, the Bad and the Ugly" }
    ]
  },
  {
    "name": "Maren Morris",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1990" },
      { "key": "Known for", "value": "The Middle" }
    ]
  },
  {
    "name": "Wentworth Miller",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1972" },
      { "key": "Known for", "value": "Prison Break" }
    ]
  },
  {
    "name": "Christina Hendricks",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1975" },
      { "key": "Known for", "value": "Mad Men" }
    ]
  },
  {
    "name": "Jeremy Renner",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "The Hurt Locker" }
    ]
  },
  {
    "name": "Kane Brown",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1993" },
      { "key": "Known for", "value": "What Ifs" }
    ]
  },
  {
    "name": "John Krasinski",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Demi Lovato",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1992" },
      { "key": "Known for", "value": "Camp Rock" }
    ]
  },
  {
    "name": "Waylon Jennings",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1937" },
      { "key": "Known for", "value": "Good Hearted Woman" }
    ]
  },
  {
    "name": "Rainn Wilson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1966" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Stevie Nicks",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1948" },
      { "key": "Known for", "value": "Fleetwood Mac" }
    ]
  },
  {
    "name": "Eric Church",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1977" },
      { "key": "Known for", "value": "Springsteen" }
    ]
  },
  {
    "name": "Ashley Olsen",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1986" },
      { "key": "Known for", "value": "Full House" }
    ]
  },
  {
    "name": "Will Smith",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1968" },
      { "key": "Known for", "value": "The Fresh Prince of Bel-Air" }
    ]
  },
  {
    "name": "Elizabeth Banks",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "CeeLo Green",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "Crazy" }
    ]
  },
  {
    "name": "Steve Buscemi",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1957" },
      { "key": "Known for", "value": "Fargo" }
    ]
  },
  {
    "name": "Shakira",
    "hints": [
      { "key": "Nationality", "value": "Colombian" },
      { "key": "Birthyear", "value": "1977" },
      { "key": "Known for", "value": "Hips Don't Lie" }
    ]
  },
  {
    "name": "Paul Lieberstein",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Gal Gadot",
    "hints": [
      { "key": "Nationality", "value": "Israeli" },
      { "key": "Birthyear", "value": "1985" },
      { "key": "Known for", "value": "Wonder Woman" }
    ]
  },
  {
    "name": "Ellie Kemper",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1980" },
      { "key": "Known for", "value": "Unbreakable Kimmy Schmidt" }
    ]
  },
  {
    "name": "Jesse L. Martin",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1969" },
      { "key": "Known for", "value": "Law & Order" }
    ]
  },
  {
    "name": "David Letterman",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1947" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "Ice Cube",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1969" },
      { "key": "Known for", "value": "Boyz n the Hood" }
    ]
  },
  {
    "name": "John McVie",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1945" },
      { "key": "Known for", "value": "Fleetwood Mac" }
    ]
  },
  {
    "name": "Larry David",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1947" },
      { "key": "Known for", "value": "Curb Your Enthusiasm" }
    ]
  },
  {
    "name": "Chris Evans",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1981" },
      { "key": "Known for", "value": "Captain America" }
    ]
  },
  {
    "name": "Chris Stapleton",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1978" },
      { "key": "Known for", "value": "Traveller" }
    ]
  },
  {
    "name": "Martin Freeman",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "The Hobbit" }
    ]
  },
  {
    "name": "Linda Ronstadt",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1946" },
      { "key": "Known for", "value": "Blue Bayou" }
    ]
  },
  {
    "name": "Christina Aguilera",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1980" },
      { "key": "Known for", "value": "Genie in a Bottle" }
    ]
  },
  {
    "name": "January Jones",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1978" },
      { "key": "Known for", "value": "Mad Men" }
    ]
  },
  {
    "name": "Betsy Beutler",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1988" },
      { "key": "Known for", "value": "You're the Worst" }
    ]
  },
  {
    "name": "Loretta Lynn",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1932" },
      { "key": "Known for", "value": "Coal Miner's Daughter" }
    ]
  },
  {
    "name": "Brad Pitt",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "Fight Club" }
    ]
  },
  {
    "name": "Matt Damon",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1970" },
      { "key": "Known for", "value": "Good Will Hunting" }
    ]
  },
  {
    "name": "Amy Grant",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1960" },
      { "key": "Known for", "value": "Baby Baby" }
    ]
  },
  {
    "name": "Steve Earle",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1955" },
      { "key": "Known for", "value": "Copperhead Road" }
    ]
  },
  {
    "name": "Ricky Gervais",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1961" },
      { "key": "Known for", "value": "The Office (UK)" }
    ]
  },
  {
    "name": "Jim Rash",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "Community" }
    ]
  },
  {
    "name": "Freema Agyeman",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Stephen Fry",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1957" },
      { "key": "Known for", "value": "A Bit of Fry and Laurie" }
    ]
  },
  {
    "name": "Josh Radnor",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "How I Met Your Mother" }
    ]
  },
  {
    "name": "Adam Devine",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1983" },
      { "key": "Known for", "value": "Workaholics" }
    ]
  },
  {
    "name": "Utkarsh Ambudkar",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1983" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "Angelina Jolie",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1975" },
      { "key": "Known for", "value": "Maleficent" }
    ]
  },
  {
    "name": "Mindy Kaling",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Paul Blackthorne",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1969" },
      { "key": "Known for", "value": "Arrow" }
    ]
  },
  {
    "name": "Dave Mason",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1946" },
      { "key": "Known for", "value": "Traffic" }
    ]
  },
  {
    "name": "Dave Walker",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1945" },
      { "key": "Known for", "value": "Black Sabbath" }
    ]
  },
  {
    "name": "Michael Richards",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1949" },
      { "key": "Known for", "value": "Seinfeld" }
    ]
  },
  {
    "name": "Rowan Atkinson",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1955" },
      { "key": "Known for", "value": "Mr. Bean" }
    ]
  },
  {
    "name": "Akiva Schaffer",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1977" },
      { "key": "Known for", "value": "The Lonely Island" }
    ]
  },
  {
    "name": "Kinky Friedman",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1944" },
      { "key": "Known for", "value": "Ride 'Em Jewboy" }
    ]
  },
  {
    "name": "John Legend",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1978" },
      { "key": "Known for", "value": "All of Me" }
    ]
  },
  {
    "name": "Lady Gaga",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1986" },
      { "key": "Known for", "value": "A Star Is Born" }
    ]
  },
  {
    "name": "Jennifer Aniston",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1969" },
      { "key": "Known for", "value": "Friends" }
    ]
  },
  {
    "name": "Trisha Yearwood",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "How Do I Live" }
    ]
  },
  {
    "name": "Rick Vito",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1949" },
      { "key": "Known for", "value": "Fleetwood Mac" }
    ]
  },
  {
    "name": "Candace Cameron Bure",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1976" },
      { "key": "Known for", "value": "Full House" }
    ]
  },
  {
    "name": "Willie Nelson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1933" },
      { "key": "Known for", "value": "On the Road Again" }
    ]
  },
  {
    "name": "Anna Kendrick",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1985" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "Denzel Washington",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1954" },
      { "key": "Known for", "value": "Training Day" }
    ]
  },
  {
    "name": "Keith Whitley",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1955" },
      { "key": "Known for", "value": "Don't Close Your Eyes" }
    ]
  },
  {
    "name": "Olivia Newton-John",
    "hints": [
      { "key": "Nationality", "value": "Australian" },
      { "key": "Birthyear", "value": "1948" },
      { "key": "Known for", "value": "Grease" }
    ]
  },
  {
    "name": "Richard Armitage",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "The Hobbit" }
    ]
  },
  {
    "name": "Charley McClain",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1956" },
      { "key": "Known for", "value": "Who's Cheatin' Who" }
    ]
  },
  {
    "name": "Martin Starr",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Freaks and Geeks" }
    ]
  },
  {
    "name": "Cristin Milioti",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1985" },
      { "key": "Known for", "value": "How I Met Your Mother" }
    ]
  },
  {
    "name": "Randy Travis",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1959" },
      { "key": "Known for", "value": "Forever and Ever, Amen" }
    ]
  },
  {
    "name": "Sean Astin",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "The Lord of the Rings" }
    ]
  },
  {
    "name": "Rob Riggle",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1970" },
      { "key": "Known for", "value": "The Daily Show" }
    ]
  },
  {
    "name": "Barney Martin",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1923" },
      { "key": "Known for", "value": "Seinfeld" }
    ]
  },
  {
    "name": "Alyson Hannigan",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "How I Met Your Mother" }
    ]
  },
  {
    "name": "Rupert Grint",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1988" },
      { "key": "Known for", "value": "Harry Potter series" }
    ]
  },
  {
    "name": "Victor Garber",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1949" },
      { "key": "Known for", "value": "Alias" }
    ]
  },
  {
    "name": "Judd Apatow",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Knocked Up" }
    ]
  },
  {
    "name": "Evangeline Lilly",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "Lost" }
    ]
  },
  {
    "name": "Rashida Jones",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1976" },
      { "key": "Known for", "value": "Parks and Recreation" }
    ]
  },
  {
    "name": "Johnny Depp",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "Pirates of the Caribbean" }
    ]
  },
  {
    "name": "James Corden",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1978" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "John Slattery",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1962" },
      { "key": "Known for", "value": "Mad Men" }
    ]
  },
  {
    "name": "John O'Hurley",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1954" },
      { "key": "Known for", "value": "Seinfeld" }
    ]
  },
  {
    "name": "Patrick Warburton",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "Family Guy" }
    ]
  },
  {
    "name": "Dwight Yoakam",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1956" },
      { "key": "Known for", "value": "Guitars, Cadillacs, Etc., Etc." }
    ]
  },
  {
    "name": "Jennifer Hudson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1981" },
      { "key": "Known for", "value": "Dreamgirls" }
    ]
  },
  {
    "name": "John Rhys-Davies",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1944" },
      { "key": "Known for", "value": "The Lord of the Rings" }
    ]
  },
  {
    "name": "Al Pacino",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1940" },
      { "key": "Known for", "value": "The Godfather" }
    ]
  },
  {
    "name": "Dakota Johnson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1989" },
      { "key": "Known for", "value": "Fifty Shades of Grey" }
    ]
  },
  {
    "name": "Taylor Swift",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1989" },
      { "key": "Known for", "value": "Love Story" }
    ]
  },
  {
    "name": "Keanu Reeves",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "The Matrix" }
    ]
  },
  {
    "name": "Tanya Tucker",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1958" },
      { "key": "Known for", "value": "Delta Dawn" }
    ]
  },
  {
    "name": "Tosin Cole",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1992" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Charley Pride",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1934" },
      { "key": "Known for", "value": "Kiss an Angel Good Mornin'" }
    ]
  },
  {
    "name": "David Allan Coe",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1939" },
      { "key": "Known for", "value": "Take This Job and Shove It" }
    ]
  },
  {
    "name": "Chris Pratt",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "Guardians of the Galaxy" }
    ]
  },
  {
    "name": "Seth Rogen",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Knocked Up" }
    ]
  },
  {
    "name": "Keith Urban",
    "hints": [
      { "key": "Nationality", "value": "Australian" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Blue Ain't Your Color" }
    ]
  },
  {
    "name": "Lyle Lovett",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1957" },
      { "key": "Known for", "value": "Lyle Lovett" }
    ]
  },
  {
    "name": "Julia Roberts",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Pretty Woman" }
    ]
  },
  {
    "name": "Craig Robinson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Joel McHale",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "Community" }
    ]
  },
  {
    "name": "Johnny Carson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1925" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "Franz Drameh",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1993" },
      { "key": "Known for", "value": "Legends of Tomorrow" }
    ]
  },
  {
    "name": "Will Ferrell",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Anchorman" }
    ]
  },
  {
    "name": "Katie Cassidy",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1986" },
      { "key": "Known for", "value": "Arrow" }
    ]
  },
  {
    "name": "John Stamos",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "Full House" }
    ]
  },
  {
    "name": "Sarah Chalke",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1976" },
      { "key": "Known for", "value": "Scrubs" }
    ]
  },
  {
    "name": "Daniel Radcliffe",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1989" },
      { "key": "Known for", "value": "Harry Potter series" }
    ]
  },
  {
    "name": "Roy Acuff",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1903" },
      { "key": "Known for", "value": "The Great Speckled Bird" }
    ]
  },
  {
    "name": "Kelly Clarkson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Since U Been Gone" }
    ]
  },
  {
    "name": "Ken Jeong",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1969" },
      { "key": "Known for", "value": "The Hangover" }
    ]
  },
  {
    "name": "B.J. Novak",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "The Office" }
    ]
  },
  {
    "name": "Zach Braff",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1975" },
      { "key": "Known for", "value": "Scrubs" }
    ]
  },
  {
    "name": "Bill Hader",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1978" },
      { "key": "Known for", "value": "Saturday Night Live" }
    ]
  },
  {
    "name": "Anne Hathaway",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Les Misérables" }
    ]
  },
  {
    "name": "Bob Saget",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1956" },
      { "key": "Known for", "value": "Full House" }
    ]
  },
  {
    "name": "Adam Levine",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "Maroon 5" }
    ]
  },
  {
    "name": "Matt Ryan",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1981" },
      { "key": "Known for", "value": "Constantine" }
    ]
  },
  {
    "name": "Lee Ann Womack",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1966" },
      { "key": "Known for", "value": "I Hope You Dance" }
    ]
  },
  {
    "name": "Jay Baruchel",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "She's Out of My League" }
    ]
  },
  {
    "name": "Liv Tyler",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1977" },
      { "key": "Known for", "value": "Armageddon" }
    ]
  },
  {
    "name": "Lucinda Williams",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1953" },
      { "key": "Known for", "value": "Car Wheels on a Gravel Road" }
    ]
  },
  {
    "name": "Katy Perry",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1984" },
      { "key": "Known for", "value": "Firework" }
    ]
  },
  {
    "name": "Anna Camp",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Pitch Perfect" }
    ]
  },
  {
    "name": "Tim Finn",
    "hints": [
      { "key": "Nationality", "value": "New Zealand" },
      { "key": "Birthyear", "value": "1952" },
      { "key": "Known for", "value": "Crowded House" }
    ]
  },
  {
    "name": "Maisie Richardson-Sellers",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1992" },
      { "key": "Known for", "value": "Legends of Tomorrow" }
    ]
  },
  {
    "name": "Garth Brooks",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1962" },
      { "key": "Known for", "value": "Friends in Low Places" }
    ]
  },
  {
    "name": "Jason Segel",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1980" },
      { "key": "Known for", "value": "How I Met Your Mother" }
    ]
  },
  {
    "name": "Mickey Gilley",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1936" },
      { "key": "Known for", "value": "Stand by Me" }
    ]
  },
  {
    "name": "Graham Norton",
    "hints": [
      { "key": "Nationality", "value": "Irish" },
      { "key": "Birthyear", "value": "1963" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "Luke Evans",
    "hints": [
      { "key": "Nationality", "value": "Welsh" },
      { "key": "Birthyear", "value": "1979" },
      { "key": "Known for", "value": "Beauty and the Beast" }
    ]
  },
  {
    "name": "Andrea Barber",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1976" },
      { "key": "Known for", "value": "Full House" }
    ]
  },
  {
    "name": "Kate Winslet",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1975" },
      { "key": "Known for", "value": "Titanic" }
    ]
  },
  {
    "name": "Nick Offerman",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1970" },
      { "key": "Known for", "value": "Parks and Recreation" }
    ]
  },
  {
    "name": "Paul Rudd",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1969" },
      { "key": "Known for", "value": "Ant-Man" }
    ]
  },
  {
    "name": "Andy Samberg",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1978" },
      { "key": "Known for", "value": "Brooklyn Nine-Nine" }
    ]
  },
  {
    "name": "Aziz Ansari",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1983" },
      { "key": "Known for", "value": "Master of None" }
    ]
  },
  {
    "name": "Christian Bale",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "The Dark Knight" }
    ]
  },
  {
    "name": "David Tennant",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "Doctor Who" }
    ]
  },
  {
    "name": "Blake Shelton",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1976" },
      { "key": "Known for", "value": "God's Country" }
    ]
  },
  {
    "name": "Stephen Amell",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1981" },
      { "key": "Known for", "value": "Arrow" }
    ]
  },
  {
    "name": "Joan Rivers",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1933" },
      { "key": "Known for", "value": "Fashion Police" }
    ]
  },
  {
    "name": "Avril Lavigne",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1984" },
      { "key": "Known for", "value": "Complicated" }
    ]
  },
  {
    "name": "Scarlett Johansson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1984" },
      { "key": "Known for", "value": "Lost in Translation" }
    ]
  },
  {
    "name": "Whoopi Goldberg",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1955" },
      { "key": "Known for", "value": "Ghost" }
    ]
  },
  {
    "name": "Jessica Simpson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1980" },
      { "key": "Known for", "value": "With You" }
    ]
  },
  {
    "name": "David Ramsey",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "Arrow" }
    ]
  },
  {
    "name": "Danny McBride",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1976" },
      { "key": "Known for", "value": "Eastbound & Down" }
    ]
  },
  {
    "name": "Nick Jonas",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1992" },
      { "key": "Known for", "value": "Jealous" }
    ]
  },
  {
    "name": "Tom Hanks",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1956" },
      { "key": "Known for", "value": "Forrest Gump" }
    ]
  },
  {
    "name": "John Simm",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1970" },
      { "key": "Known for", "value": "Life on Mars" }
    ]
  },
  {
    "name": "Selena Gomez",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1992" },
      { "key": "Known for", "value": "Wizards of Waverly Place" }
    ]
  },
  {
    "name": "Helen Reddy",
    "hints": [
      { "key": "Nationality", "value": "Australian" },
      { "key": "Birthyear", "value": "1941" },
      { "key": "Known for", "value": "I Am Woman" }
    ]
  },
  {
    "name": "Luke Bryan",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1976" },
      { "key": "Known for", "value": "Country Girl (Shake It for Me)" }
    ]
  },
  {
    "name": "Chris Hemsworth",
    "hints": [
      { "key": "Nationality", "value": "Australian" },
      { "key": "Birthyear", "value": "1983" },
      { "key": "Known for", "value": "Thor" }
    ]
  },
  {
    "name": "Harrison Ford",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1942" },
      { "key": "Known for", "value": "Star Wars" }
    ]
  },
  {
    "name": "Johnny Horton",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1925" },
      { "key": "Known for", "value": "The Battle of New Orleans" }
    ]
  },
  {
    "name": "Shania Twain",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1965" },
      { "key": "Known for", "value": "Man! I Feel Like a Woman!" }
    ]
  },
  {
    "name": "Creed Bratton",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1943" },
      { "key": "Known for", "value": "The Grass Roots" }
    ]
  },
  {
    "name": "Hugh Laurie",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1959" },
      { "key": "Known for", "value": "House" }
    ]
  },
  {
    "name": "Alison Brie",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Community" }
    ]
  },
  {
    "name": "Bonnie Raitt",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1949" },
      { "key": "Known for", "value": "Something to Talk About" }
    ]
  },
  {
    "name": "Vern Gosdin",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1934" },
      { "key": "Known for", "value": "Chiseled in Stone" }
    ]
  },
  {
    "name": "Mark Ruffalo",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1967" },
      { "key": "Known for", "value": "Avengers: Endgame" }
    ]
  },
  {
    "name": "Patsy Cline",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1932" },
      { "key": "Known for", "value": "Crazy" }
    ]
  },
  {
    "name": "Charlize Theron",
    "hints": [
      { "key": "Nationality", "value": "South African" },
      { "key": "Birthyear", "value": "1975" },
      { "key": "Known for", "value": "Monster" }
    ]
  },
  {
    "name": "Faron Young",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1932" },
      { "key": "Known for", "value": "Hello Walls" }
    ]
  },
  {
    "name": "David Koechner",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1962" },
      { "key": "Known for", "value": "Anchorman: The Legend of Ron Burgundy" }
    ]
  },
  {
    "name": "Ed Helms",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "The Hangover" }
    ]
  },
  {
    "name": "Emma Watson",
    "hints": [
      { "key": "Nationality", "value": "British" },
      { "key": "Birthyear", "value": "1990" },
      { "key": "Known for", "value": "Harry Potter series" }
    ]
  },
  {
    "name": "Jason Alexander",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1959" },
      { "key": "Known for", "value": "Seinfeld" }
    ]
  },
  {
    "name": "Reba McEntire",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1955" },
      { "key": "Known for", "value": "Fancy" }
    ]
  },
  {
    "name": "Candice Patton",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1988" },
      { "key": "Known for", "value": "The Flash" }
    ]
  },
  {
    "name": "Stephen Colbert",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1964" },
      { "key": "Known for", "value": "The Colbert Report" }
    ]
  },
  {
    "name": "Charles Esten",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1965" },
      { "key": "Known for", "value": "Nashville" }
    ]
  },
  {
    "name": "Eliza Coupe",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1981" },
      { "key": "Known for", "value": "Happy Endings" }
    ]
  },
  {
    "name": "Dominic Purcell",
    "hints": [
      { "key": "Nationality", "value": "British-Australian" },
      { "key": "Birthyear", "value": "1970" },
      { "key": "Known for", "value": "Prison Break" }
    ]
  },
  {
    "name": "Christina Applegate",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1971" },
      { "key": "Known for", "value": "Married... with Children" }
    ]
  },
  {
    "name": "Jonah Hill",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1983" },
      { "key": "Known for", "value": "Superbad" }
    ]
  },
  {
    "name": "Ben Platt",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1993" },
      { "key": "Known for", "value": "Dear Evan Hansen" }
    ]
  },
  {
    "name": "Michael Cera",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1988" },
      { "key": "Known for", "value": "Juno" }
    ]
  },
  {
    "name": "Danielle Panabaker",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1987" },
      { "key": "Known for", "value": "The Flash" }
    ]
  },
  {
    "name": "Rosie O'Donnell",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1962" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "Viggo Mortensen",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1958" },
      { "key": "Known for", "value": "The Lord of the Rings" }
    ]
  },
  {
    "name": "Barbara Mandrell",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1948" },
      { "key": "Known for", "value": "Sleeping Single in a Double Bed" }
    ]
  },
  {
    "name": "George Clooney",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1961" },
      { "key": "Known for", "value": "Ocean's Eleven" }
    ]
  },
  {
    "name": "Jimmy Fallon",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1974" },
      { "key": "Known for", "value": "Hosting a talk show" }
    ]
  },
  {
    "name": "Luke Macfarlane",
    "hints": [
      { "key": "Nationality", "value": "Canadian" },
      { "key": "Birthyear", "value": "1980" },
      { "key": "Known for", "value": "Brothers & Sisters" }
    ]
  },
  {
    "name": "Jes Macallan",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1982" },
      { "key": "Known for", "value": "Mistresses" }
    ]
  },
  {
    "name": "Alan Jackson",
    "hints": [
      { "key": "Nationality", "value": "American" },
      { "key": "Birthyear", "value": "1958" },
      { "key": "Known for", "value": "Chattahoochee" }
    ]
  }
]