import React, { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";


export default function Settings({ darkMode, toggleDarkMode, hintPreferences, toggleHints }) {
  const [open, setOpen] = useState(false);
  const spacing = { marginTop: '20px', marginBottom: '20px' };
  const header = { fontSize: '22px', fontWeight: '900' };
  const paragraph = { fontSize: '18px', fontWeight: '100' }

  const handleClick = () => {
    setOpen(!open);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <>
      <Button
        id="basic-button"
        onClick={handleClick}
      >
        <SettingsIcon color="action" />
      </Button>
      {open ? <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            zIndex: 1,
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography sx={header}>Preferences</Typography>
          <FormGroup>
            <FormControlLabel control={<Switch onClick={toggleDarkMode} checked={darkMode ? true : false} />} label="Dark mode" />
          </FormGroup>
          <Divider sx={spacing} />
          <Typography sx={header}>Hints</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel control={<Switch onClick={() => toggleHints("album")} checked={hintPreferences.album ? true : false} />} label="Album" />
                <FormControlLabel control={<Switch onClick={() => toggleHints("celebrity")} checked={hintPreferences.celebrity ? true : false} />} label="Celebrity" />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel control={<Switch onClick={() => toggleHints("game")} checked={hintPreferences.game ? true : false} />} label="Game" />
                <FormControlLabel control={<Switch onClick={() => toggleHints("movie")} checked={hintPreferences.movie ? true : false} />} label="Movie" />
              </FormGroup>
            </Grid>
          </Grid>
          <Divider sx={spacing} />
          <Typography sx={header}>How to play</Typography>
          <Typography sx={paragraph}>You're given 4 guesses to correctly identify the blurred image. Every incorrect guess the image gets slightly less blurry.</Typography>
          <br />
          <Typography sx={paragraph}>If you have hints enabled you will also be given a hint for each incorrect guess.</Typography>
          <Divider sx={spacing} />
          <Link to='/privacy' style={{ textDecoration: 'none' }} onClick={handleClose}>
            <Typography sx={paragraph}>Privacy policy</Typography>
          </Link>
        </DialogContent>
      </Dialog> : null}
    </>
  )
}