export const ALBUM_ANSWERS = [
  {
    "name": "Ready to Die - The Notorious B.I.G.",
    "hints": [
      { "key": "Record label", "value": "Bad Boy" },
      { "key": "Release year", "value": "1994" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "The Freewheelin' Bob Dylan - Bob Dylan",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1963" },
      { "key": "Genre", "value": "Folk" }
    ]
  },
  {
    "name": "The Queen Is Dead - The Smiths",
    "hints": [
      { "key": "Record label", "value": "Rough Trade, Sire" },
      { "key": "Release year", "value": "1986" },
      { "key": "Genre", "value": "Alternative rock, Indie pop" }
    ]
  },
  {
    "name": "21st Century Breakdown - Green Day",
    "hints": [
      { "key": "Record label", "value": "Reprise" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Punk rock, Alternative rock" }
    ]
  },
  {
    "name": "Like a Virgin - Madonna",
    "hints": [
      { "key": "Record label", "value": "Sire" },
      { "key": "Release year", "value": "1984" },
      { "key": "Genre", "value": "Pop, Dance-pop" }
    ]
  },
  {
    "name": "Waiting for the Sun - The Doors",
    "hints": [
      { "key": "Record label", "value": "Elektra" },
      { "key": "Release year", "value": "1968" },
      { "key": "Genre", "value": "Psychedelic rock" }
    ]
  },
  {
    "name": "Viva la Vida or Death and All His Friends - Coldplay",
    "hints": [
      { "key": "Record label", "value": "Parlophone, Capitol" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Alternative rock, Art rock" }
    ]
  },
  {
    "name": "What's Your Pleasure? - Jessie Ware",
    "hints": [
      { "key": "Record label", "value": "PMR, Virgin EMI" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Disco, Pop" }
    ]
  },
  {
    "name": "Life After Death - The Notorious B.I.G.",
    "hints": [
      { "key": "Record label", "value": "Bad Boy" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Hip hop, Gangsta rap" }
    ]
  },
  {
    "name": "Man on the Moon III: The Chosen - Kid Cudi",
    "hints": [
      { "key": "Record label", "value": "Republic, Wicked Awesome" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Hip hop, Alternative hip hop" }
    ]
  },
  {
    "name": "Hood Hottest Princess (Deluxe) - Sexyy Red",
    "hints": [
      { "key": "Record label", "value": "Open Shift, Giant Music" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Hip hop, Trap" }
    ]
  },
  {
    "name": "Sign o' the Times - Prince",
    "hints": [
      { "key": "Record label", "value": "Paisley Park, Warner Bros." },
      { "key": "Release year", "value": "1987" },
      { "key": "Genre", "value": "Pop, Funk, R&B" }
    ]
  },
  {
    "name": "The House Is Burning - Isaiah Rashad",
    "hints": [
      { "key": "Record label", "value": "Top Dawg, Warner" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Mr. Morale & the Big Steppers - Kendrick Lamar",
    "hints": [
      { "key": "Record label", "value": "Top Dawg, Aftermath, Interscope" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Parade - Prince",
    "hints": [
      { "key": "Record label", "value": "Paisley Park, Warner Bros." },
      { "key": "Release year", "value": "1986" },
      { "key": "Genre", "value": "Pop, Funk" }
    ]
  },
  {
    "name": "Un Verano Sin Ti - Bad Bunny",
    "hints": [
      { "key": "Record label", "value": "Rimas" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Reggaeton, Latin trap" }
    ]
  },
  {
    "name": "Foo Fighters - Foo Fighters",
    "hints": [
      { "key": "Record label", "value": "Roswell, Capitol" },
      { "key": "Release year", "value": "1995" },
      { "key": "Genre", "value": "Alternative rock, Post-grunge" }
    ]
  },
  {
    "name": "Sandinista! - The Clash",
    "hints": [
      { "key": "Record label", "value": "CBS, Epic" },
      { "key": "Release year", "value": "1980" },
      { "key": "Genre", "value": "Punk rock, Reggae, Dub" }
    ]
  },
  {
    "name": "A Moon Shaped Pool - Radiohead",
    "hints": [
      { "key": "Record label", "value": "XL" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Art rock, Electronica" }
    ]
  },
  {
    "name": "Life Support - Madison Beer",
    "hints": [
      { "key": "Record label", "value": "Epic" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Solar Power - Lorde",
    "hints": [
      { "key": "Record label", "value": "Universal, Lava, Republic" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Pop, Folk pop" }
    ]
  },
  {
    "name": "Scarlet - Doja Cat",
    "hints": [
      { "key": "Record label", "value": "Kemosabe, RCA" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Hip hop, R&B" }
    ]
  },
  {
    "name": "Led Zeppelin IV - Led Zeppelin",
    "hints": [
      { "key": "Record label", "value": "Atlantic" },
      { "key": "Release year", "value": "1971" },
      { "key": "Genre", "value": "Hard rock, Heavy metal" }
    ]
  },
  {
    "name": "How Big, How Blue, How Beautiful - Florence + The Machine",
    "hints": [
      { "key": "Record label", "value": "Island, Republic" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Indie rock, Baroque pop" }
    ]
  },
  {
    "name": "Blue Weekend - Wolf Alice",
    "hints": [
      { "key": "Record label", "value": "Dirty Hit" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Bury the Hatchet - The Cranberries",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Alternative rock, Pop rock" }
    ]
  },
  {
    "name": "Hollywood's Bleeding - Post Malone",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Hip hop, Pop" }
    ]
  },
  {
    "name": "Dawn FM - The Weeknd",
    "hints": [
      { "key": "Record label", "value": "XO, Republic" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Synth-pop, R&B" }
    ]
  },
  {
    "name": "OK Computer - Radiohead",
    "hints": [
      { "key": "Record label", "value": "Parlophone, Capitol" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Alternative rock, Art rock" }
    ]
  },
  {
    "name": "Jubilee - Japanese Breakfast",
    "hints": [
      { "key": "Record label", "value": "Dead Oceans" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Indie pop" }
    ]
  },
  {
    "name": "Plastic Hearts - Miley Cyrus",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Rock, Pop" }
    ]
  },
  {
    "name": "Daydream - Mariah Carey",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1995" },
      { "key": "Genre", "value": "Pop, R&B" }
    ]
  },
  {
    "name": "Certified Lover Boy - Drake",
    "hints": [
      { "key": "Record label", "value": "OVO, Republic" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Hip hop, R&B" }
    ]
  },
  {
    "name": "Madvillainy - MF DOOM",
    "hints": [
      { "key": "Record label", "value": "Stones Throw" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Bleach - Nirvana",
    "hints": [
      { "key": "Record label", "value": "Sub Pop" },
      { "key": "Release year", "value": "1989" },
      { "key": "Genre", "value": "Grunge" }
    ]
  },
  {
    "name": "4:44 - Jay-Z",
    "hints": [
      { "key": "Record label", "value": "Roc Nation" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Blonde on Blonde - Bob Dylan",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1966" },
      { "key": "Genre", "value": "Folk rock" }
    ]
  },
  {
    "name": "25 - Adele",
    "hints": [
      { "key": "Record label", "value": "XL, Columbia" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Pop, Soul" }
    ]
  },
  {
    "name": "Coloring Book - Chance the Rapper",
    "hints": [
      { "key": "Record label", "value": "Self-released" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Hip hop, Gospel rap" }
    ]
  },
  {
    "name": "Meat Is Murder - The Smiths",
    "hints": [
      { "key": "Record label", "value": "Rough Trade, Sire" },
      { "key": "Release year", "value": "1985" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Wolfgang Amadeus Phoenix - Phoenix",
    "hints": [
      { "key": "Record label", "value": "V2, Glassnote" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Indie pop, Synth-pop" }
    ]
  },
  {
    "name": "Magdalene - FKA twigs",
    "hints": [
      { "key": "Record label", "value": "Young Turks" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Art pop, R&B" }
    ]
  },
  {
    "name": "The White Album - The Beatles",
    "hints": [
      { "key": "Record label", "value": "Apple" },
      { "key": "Release year", "value": "1968" },
      { "key": "Genre", "value": "Rock, Pop" }
    ]
  },
  {
    "name": "Hall of Fame - Polo G",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Hip hop, Trap" }
    ]
  },
  {
    "name": "Wonderful Wonderful - The Killers",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Alternative rock, Synth-pop" }
    ]
  },
  {
    "name": "SOUR - Olivia Rodrigo",
    "hints": [
      { "key": "Record label", "value": "Geffen" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Pop rock, Alternative rock" }
    ]
  },
  {
    "name": "1999 - Prince",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "1982" },
      { "key": "Genre", "value": "Pop, Funk" }
    ]
  },
  {
    "name": "Beggars Banquet - The Rolling Stones",
    "hints": [
      { "key": "Record label", "value": "Decca, London" },
      { "key": "Release year", "value": "1968" },
      { "key": "Genre", "value": "Rock, Blues rock" }
    ]
  },
  {
    "name": "KiCk i - Arca",
    "hints": [
      { "key": "Record label", "value": "XL" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Electronic, Avant-pop" }
    ]
  },
  {
    "name": "Emotions - Mariah Carey",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1991" },
      { "key": "Genre", "value": "Pop, R&B" }
    ]
  },
  {
    "name": "From Under the Cork Tree - Fall Out Boy",
    "hints": [
      { "key": "Record label", "value": "Island, Fueled by Ramen" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Pop punk, Emo" }
    ]
  },
  {
    "name": "The College Dropout - Kanye West",
    "hints": [
      { "key": "Record label", "value": "Roc-A-Fella, Def Jam" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Blood Sugar Sex Magik - Red Hot Chili Peppers",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "1991" },
      { "key": "Genre", "value": "Funk rock, Alternative rock" }
    ]
  },
  {
    "name": "Scorpion - Drake",
    "hints": [
      { "key": "Record label", "value": "Young Money, Cash Money, Republic" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Hip hop, R&B" }
    ]
  },
  {
    "name": "thank u, next - Ariana Grande",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Pop, R&B" }
    ]
  },
  {
    "name": "Strange Days - The Doors",
    "hints": [
      { "key": "Record label", "value": "Elektra" },
      { "key": "Release year", "value": "1967" },
      { "key": "Genre", "value": "Psychedelic rock" }
    ]
  },
  {
    "name": "Endless Summer Vacation - Miley Cyrus",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Pop, Rock" }
    ]
  },
  {
    "name": "High as Hope - Florence + The Machine",
    "hints": [
      { "key": "Record label", "value": "Republic, Virgin EMI" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Indie rock" }
    ]
  },
  {
    "name": "Songs in the Key of Life - Stevie Wonder",
    "hints": [
      { "key": "Record label", "value": "Tamla" },
      { "key": "Release year", "value": "1976" },
      { "key": "Genre", "value": "Soul, Funk" }
    ]
  },
  {
    "name": "Abbey Road - The Beatles",
    "hints": [
      { "key": "Record label", "value": "Apple" },
      { "key": "Release year", "value": "1969" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "DNA - Backstreet Boys",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Saint Cloud - Waxahatchee",
    "hints": [
      { "key": "Record label", "value": "Merge" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Indie rock, Americana" }
    ]
  },
  {
    "name": "It's Only Me - Lil Baby",
    "hints": [
      { "key": "Record label", "value": "Quality Control, Motown, Wolfpack" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Graduation - Kanye West",
    "hints": [
      { "key": "Record label", "value": "Roc-A-Fella, Def Jam" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "The Eminem Show - Eminem",
    "hints": [
      { "key": "Record label", "value": "Aftermath, Shady, Interscope" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "The Off-Season - J. Cole",
    "hints": [
      { "key": "Record label", "value": "Dreamville, Roc Nation, Interscope" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Starboy - The Weeknd",
    "hints": [
      { "key": "Record label", "value": "XO, Republic" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "R&B, Pop" }
    ]
  },
  {
    "name": "Nothing Was the Same - Drake",
    "hints": [
      { "key": "Record label", "value": "Young Money, Cash Money, Republic" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Hip hop, R&B" }
    ]
  },
  {
    "name": "Thank Me Later - Drake",
    "hints": [
      { "key": "Record label", "value": "Young Money, Cash Money, Universal Motown" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Hip hop, R&B" }
    ]
  },
  {
    "name": "Blood on the Tracks - Bob Dylan",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1975" },
      { "key": "Genre", "value": "Folk rock" }
    ]
  },
  {
    "name": "Four - One Direction",
    "hints": [
      { "key": "Record label", "value": "Columbia, Syco" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Pop, Pop rock" }
    ]
  },
  {
    "name": "No Need to Argue - The Cranberries",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "1994" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Feed the Machine - Nickelback",
    "hints": [
      { "key": "Record label", "value": "BMG" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Get Behind Me Satan - The White Stripes",
    "hints": [
      { "key": "Record label", "value": "V2, Third Man, XL" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Alternative rock, Garage rock" }
    ]
  },
  {
    "name": "Me Against the World - Tupac",
    "hints": [
      { "key": "Record label", "value": "Interscope" },
      { "key": "Release year", "value": "1995" },
      { "key": "Genre", "value": "Hip hop, Gangsta rap" }
    ]
  },
  {
    "name": "Reasonable Doubt - Jay-Z",
    "hints": [
      { "key": "Record label", "value": "Roc-A-Fella, Priority" },
      { "key": "Release year", "value": "1996" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Born to Run - Bruce Springsteen",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1975" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Born in the U.S.A. - Bruce Springsteen",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1984" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Human After All - Daft Punk",
    "hints": [
      { "key": "Record label", "value": "Virgin" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Electronic, House" }
    ]
  },
  {
    "name": "Purple Rain - Prince",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "1984" },
      { "key": "Genre", "value": "Rock, Funk" }
    ]
  },
  {
    "name": "Montero - Lil Nas X",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Hip hop, Pop" }
    ]
  },
  {
    "name": "An Evening With Silk Sonic - Silk Sonic (Bruno Mars & Anderson .Paak)",
    "hints": [
      { "key": "Record label", "value": "Aftermath, Atlantic" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "R&B, Soul" }
    ]
  },
  {
    "name": "Believe - Cher",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "Dance-pop, Pop" }
    ]
  },
  {
    "name": "Believe - Justin Bieber",
    "hints": [
      { "key": "Record label", "value": "Island, RBMG" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Pop, R&B" }
    ]
  },
  {
    "name": "Lemonade - Beyoncé",
    "hints": [
      { "key": "Record label", "value": "Parkwood, Columbia" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "R&B, Pop" }
    ]
  },
  {
    "name": "This Is All Yours - alt-J",
    "hints": [
      { "key": "Record label", "value": "Infectious" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Indie rock" }
    ]
  },
  {
    "name": "Homework - Daft Punk",
    "hints": [
      { "key": "Record label", "value": "Virgin" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "House, French house" }
    ]
  },
  {
    "name": "Women in Music Pt. III - HAIM",
    "hints": [
      { "key": "Record label", "value": "Columbia, Polydor" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Rock, Pop" }
    ]
  },
  {
    "name": "In Rainbows - Radiohead",
    "hints": [
      { "key": "Record label", "value": "XL" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Alternative rock, Art rock" }
    ]
  },
  {
    "name": "Teenage Dream - Katy Perry",
    "hints": [
      { "key": "Record label", "value": "Capitol" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Millennium - Backstreet Boys",
    "hints": [
      { "key": "Record label", "value": "Jive" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "The Rise and Fall of Ziggy Stardust - David Bowie",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "1972" },
      { "key": "Genre", "value": "Glam rock" }
    ]
  },
  {
    "name": "The Smiths - The Smiths",
    "hints": [
      { "key": "Record label", "value": "Rough Trade, Sire" },
      { "key": "Release year", "value": "1984" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "A Thousand Suns - Linkin Park",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Electronic rock, Industrial rock" }
    ]
  },
  {
    "name": "London Calling - The Clash",
    "hints": [
      { "key": "Record label", "value": "CBS, Epic" },
      { "key": "Release year", "value": "1979" },
      { "key": "Genre", "value": "Punk rock, Post-punk" }
    ]
  },
  {
    "name": "Physical Graffiti - Led Zeppelin",
    "hints": [
      { "key": "Record label", "value": "Swan Song" },
      { "key": "Release year", "value": "1975" },
      { "key": "Genre", "value": "Hard rock" }
    ]
  },
  {
    "name": "The Doors - The Doors",
    "hints": [
      { "key": "Record label", "value": "Elektra" },
      { "key": "Release year", "value": "1967" },
      { "key": "Genre", "value": "Psychedelic rock" }
    ]
  },
  {
    "name": "I Like It When You Sleep... - The 1975",
    "hints": [
      { "key": "Record label", "value": "Dirty Hit, Polydor" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Pop rock, Synth-pop" }
    ]
  },
  {
    "name": "After Hours - The Weeknd",
    "hints": [
      { "key": "Record label", "value": "XO, Republic" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "R&B, Synth-pop" }
    ]
  },
  {
    "name": "The Black Album - Jay-Z",
    "hints": [
      { "key": "Record label", "value": "Roc-A-Fella, Def Jam" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Midnight Memories - One Direction",
    "hints": [
      { "key": "Record label", "value": "Columbia, Syco" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Pop rock" }
    ]
  },
  {
    "name": "Echoes, Silence, Patience & Grace - Foo Fighters",
    "hints": [
      { "key": "Record label", "value": "RCA, Roswell" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "A Rush of Blood to the Head - Coldplay",
    "hints": [
      { "key": "Record label", "value": "Parlophone" },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Take Care - Drake",
    "hints": [
      { "key": "Record label", "value": "Young Money" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Notes on a Conditional Form - The 1975",
    "hints": [
      { "key": "Record label", "value": "Dirty Hit" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Pop rock" }
    ]
  },
  {
    "name": "No Strings Attached - NSYNC",
    "hints": [
      { "key": "Record label", "value": "Jive" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Led Zeppelin II - Led Zeppelin",
    "hints": [
      { "key": "Record label", "value": "Atlantic" },
      { "key": "Release year", "value": "1969" },
      { "key": "Genre", "value": "Hard rock" }
    ]
  },
  {
    "name": "5-STAR - Stray Kids",
    "hints": [
      { "key": "Record label", "value": "JYP" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "K-pop" }
    ]
  },
  {
    "name": "Positions - Ariana Grande",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Broken Machine - Nothing But Thieves",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Nurture - Porter Robinson",
    "hints": [
      { "key": "Record label", "value": "Mom+Pop" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Electronic" }
    ]
  },
  {
    "name": "Father of the Bride - Vampire Weekend",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Indie rock" }
    ]
  },
  {
    "name": "Aquemini - Outkast",
    "hints": [
      { "key": "Record label", "value": "LaFace" },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Scaring the Hoes - JPEGMafia and Danny Brown",
    "hints": [
      { "key": "Record label", "value": "Peggy" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Future Nostalgia - Dua Lipa",
    "hints": [
      { "key": "Record label", "value": "Warner" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Hot Fuss - The Killers",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "MGMT - MGMT",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Psychedelic rock" }
    ]
  },
  {
    "name": "Witness - Katy Perry",
    "hints": [
      { "key": "Record label", "value": "Capitol" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Desperado - Eagles",
    "hints": [
      { "key": "Record label", "value": "Asylum" },
      { "key": "Release year", "value": "1973" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Silver Side Up - Nickelback",
    "hints": [
      { "key": "Record label", "value": "Roadrunner" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Post-grunge" }
    ]
  },
  {
    "name": "Shore - Fleet Foxes",
    "hints": [
      { "key": "Record label", "value": "Anti-" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Folk rock" }
    ]
  },
  {
    "name": "Sticky Fingers - The Rolling Stones",
    "hints": [
      { "key": "Record label", "value": "Rolling Stones Records" },
      { "key": "Release year", "value": "1971" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Ill Communication - Beastie Boys",
    "hints": [
      { "key": "Record label", "value": "Grand Royal" },
      { "key": "Release year", "value": "1994" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Back Of My Mind - H.E.R.",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "R&B" }
    ]
  },
  {
    "name": "Living Proof - Cher",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "If I Can't Have Love, I Want Power - Halsey",
    "hints": [
      { "key": "Record label", "value": "Capitol" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Alternative" }
    ]
  },
  {
    "name": "YHLQMDLG - Bad Bunny",
    "hints": [
      { "key": "Record label", "value": "Rimas" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Reggaeton" }
    ]
  },
  {
    "name": "Chromatica - Lady Gaga",
    "hints": [
      { "key": "Record label", "value": "Interscope" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Angles - The Strokes",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Indie rock" }
    ]
  },
  {
    "name": "Exile on Main St. - The Rolling Stones",
    "hints": [
      { "key": "Record label", "value": "Rolling Stones Records" },
      { "key": "Release year", "value": "1972" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Cowboy Carter - Beyoncé",
    "hints": [
      { "key": "Record label", "value": "Parkwood, Columbia" },
      { "key": "Release year", "value": "2024" },
      { "key": "Genre", "value": "Americana" }
    ]
  },
  {
    "name": "The Slow Rush - Tame Impala",
    "hints": [
      { "key": "Record label", "value": "Modular" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Psychedelic pop" }
    ]
  },
  {
    "name": "Illmatic - Nas",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1994" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "By the Way - Red Hot Chili Peppers",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2002" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "The Colour and the Shape - Foo Fighters",
    "hints": [
      { "key": "Record label", "value": "Roswell/Capitol" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Cuz I Love You - Lizzo",
    "hints": [
      { "key": "Record label", "value": "Nice Life/Atlantic" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Save Rock and Roll - Fall Out Boy",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Pop punk" }
    ]
  },
  {
    "name": "True Blue - Madonna",
    "hints": [
      { "key": "Record label", "value": "Sire" },
      { "key": "Release year", "value": "1986" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Evermore - Taylor Swift",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Californication - Red Hot Chili Peppers",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Parachutes - Coldplay",
    "hints": [
      { "key": "Record label", "value": "Parlophone" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Flower Boy - Tyler, The Creator",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Folklore - Taylor Swift",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Beauty Behind the Madness - The Weeknd",
    "hints": [
      { "key": "Record label", "value": "XO/Republic" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "R&B" }
    ]
  },
  {
    "name": "Merry Christmas - Mariah Carey",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1994" },
      { "key": "Genre", "value": "Christmas" }
    ]
  },
  {
    "name": "Blonde - Frank Ocean",
    "hints": [
      { "key": "Record label", "value": "Boys Don't Cry" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "R&B" }
    ]
  },
  {
    "name": "The Long Goodbye - Riz Ahmed",
    "hints": [
      { "key": "Record label", "value": "Mongrel" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Wolf - Tyler, The Creator",
    "hints": [
      { "key": "Record label", "value": "Odd Future" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Fearless (Taylor's Version) - Taylor Swift",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Country pop" }
    ]
  },
  {
    "name": "Ti Amo - Phoenix",
    "hints": [
      { "key": "Record label", "value": "Glassnote" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Synth-pop" }
    ]
  },
  {
    "name": "SOS - SZA",
    "hints": [
      { "key": "Record label", "value": "Top Dawg" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "R&B" }
    ]
  },
  {
    "name": "Call Me If You Get Lost - Tyler, The Creator",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Strangeways, Here We Come - The Smiths",
    "hints": [
      { "key": "Record label", "value": "Rough Trade" },
      { "key": "Release year", "value": "1987" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Attack & Release - The Black Keys",
    "hints": [
      { "key": "Record label", "value": "Nonesuch" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Blues rock" }
    ]
  },
  {
    "name": "Bankrupt! - Phoenix",
    "hints": [
      { "key": "Record label", "value": "Glassnote" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Circles - Mac Miller",
    "hints": [
      { "key": "Record label", "value": "Warner" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Take Me Home - One Direction",
    "hints": [
      { "key": "Record label", "value": "Syco" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Closer - Joy Division",
    "hints": [
      { "key": "Record label", "value": "Factory" },
      { "key": "Release year", "value": "1980" },
      { "key": "Genre", "value": "Post-punk" }
    ]
  },
  {
    "name": "White Blood Cells - The White Stripes",
    "hints": [
      { "key": "Record label", "value": "Sympathy for the Record Industry" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Garage rock" }
    ]
  },
  {
    "name": "Low - David Bowie",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "1977" },
      { "key": "Genre", "value": "Art rock" }
    ]
  },
  {
    "name": "Let It Bleed - The Rolling Stones",
    "hints": [
      { "key": "Record label", "value": "Decca" },
      { "key": "Release year", "value": "1969" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Nebraska - Bruce Springsteen",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1982" },
      { "key": "Genre", "value": "Folk rock" }
    ]
  },
  {
    "name": "Smile - Katy Perry",
    "hints": [
      { "key": "Record label", "value": "Capitol" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Sweetener - Ariana Grande",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Sleep Through the Static - Jack Johnson",
    "hints": [
      { "key": "Record label", "value": "Brushfire" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Folk rock" }
    ]
  },
  {
    "name": "Nevermind - Nirvana",
    "hints": [
      { "key": "Record label", "value": "DGC" },
      { "key": "Release year", "value": "1991" },
      { "key": "Genre", "value": "Grunge" }
    ]
  },
  {
    "name": "Let's Start Here - Lil Yachty",
    "hints": [
      { "key": "Record label", "value": "Quality Control" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Lonerism - Tame Impala",
    "hints": [
      { "key": "Record label", "value": "Modular" },
      { "key": "Release year", "value": "2012" },
      { "key": "Genre", "value": "Psychedelic rock" }
    ]
  },
  {
    "name": "Heaven to a Tortured Mind - Yves Tumor",
    "hints": [
      { "key": "Record label", "value": "Warp" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Experimental rock" }
    ]
  },
  {
    "name": "Utopia - Travis Scott",
    "hints": [
      { "key": "Record label", "value": "Cactus Jack" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "The Bends - Radiohead",
    "hints": [
      { "key": "Record label", "value": "Parlophone" },
      { "key": "Release year", "value": "1995" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "New Blue Sun - André 3000",
    "hints": [
      { "key": "Record label", "value": "Epic, Sony" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Ambient, Spiritual jazz" }
    ]
  },
  {
    "name": "Born Like This - MF DOOM",
    "hints": [
      { "key": "Record label", "value": "Lex" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "My Everything - Ariana Grande",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "MM.. FOOD - MF DOOM",
    "hints": [
      { "key": "Record label", "value": "Rhymesayers" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Innervisions - Stevie Wonder",
    "hints": [
      { "key": "Record label", "value": "Tamla" },
      { "key": "Release year", "value": "1973" },
      { "key": "Genre", "value": "Soul" }
    ]
  },
  {
    "name": "Sam's Town - The Killers",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Renaissance - Beyonce",
    "hints": [
      { "key": "Record label", "value": "Parkwood" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "R&B" }
    ]
  },
  {
    "name": "Stadium Arcadium - Red Hot Chili Peppers",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Recovery - Eminem",
    "hints": [
      { "key": "Record label", "value": "Aftermath" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Everybody Else Is Doing It, So Why Can't We? - The Cranberries",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "1993" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "The Slim Shady LP - Eminem",
    "hints": [
      { "key": "Record label", "value": "Aftermath" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Up All Night - One Direction",
    "hints": [
      { "key": "Record label", "value": "Syco" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "how i'm feeling now - Charli XCX",
    "hints": [
      { "key": "Record label", "value": "Atlantic" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Meteora - Linkin Park",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Nu metal" }
    ]
  },
  {
    "name": "Unreal Unearth - Hozier",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Revolution Radio - Green Day",
    "hints": [
      { "key": "Record label", "value": "Reprise" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Punk rock" }
    ]
  },
  {
    "name": "The New Abnormal - The Strokes",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Indie rock" }
    ]
  },
  {
    "name": "Prism - Katy Perry",
    "hints": [
      { "key": "Record label", "value": "Capitol" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "The Melodic Blue - Baby Keem",
    "hints": [
      { "key": "Record label", "value": "pgLang" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Random Access Memories - Daft Punk",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Electronic" }
    ]
  },
  {
    "name": "Heart of Stone - Cher",
    "hints": [
      { "key": "Record label", "value": "Geffen" },
      { "key": "Release year", "value": "1989" },
      { "key": "Genre", "value": "Pop rock" }
    ]
  },
  {
    "name": "Song Machine, Season One: Strange Timez - Gorillaz",
    "hints": [
      { "key": "Record label", "value": "Parlophone" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Elephant - The White Stripes",
    "hints": [
      { "key": "Record label", "value": "V2" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Garage rock" }
    ]
  },
  {
    "name": "Astroworld - Travis Scott",
    "hints": [
      { "key": "Record label", "value": "Cactus Jack" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Closer to the Truth - Cher",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Beer Bongs & Bentleys - Post Malone",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Hip hop, R&B, Pop" }
    ]
  },
  {
    "name": "X&Y - Coldplay",
    "hints": [
      { "key": "Record label", "value": "Parlophone, Capitol" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Turn Blue - The Black Keys",
    "hints": [
      { "key": "Record label", "value": "Nonesuch" },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Blues rock, Psychedelic rock" }
    ]
  },
  {
    "name": "One Thing at a Time - Morgan Wallen",
    "hints": [
      { "key": "Record label", "value": "Big Loud, Mercury, Republic" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Country" }
    ]
  },
  {
    "name": "Currents - Tame Impala",
    "hints": [
      { "key": "Record label", "value": "Modular" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Psychedelic pop, Synth-pop" }
    ]
  },
  {
    "name": "Fulfillingness' First Finale - Stevie Wonder",
    "hints": [
      { "key": "Record label", "value": "Tamla" },
      { "key": "Release year", "value": "1974" },
      { "key": "Genre", "value": "Soul, Funk" }
    ]
  },
  {
    "name": "The Blueprint - Jay-Z",
    "hints": [
      { "key": "Record label", "value": "Roc-A-Fella, Def Jam" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Icky Thump - The White Stripes",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Alternative rock, Garage rock" }
    ]
  },
  {
    "name": "Check Your Head - Beastie Boys",
    "hints": [
      { "key": "Record label", "value": "Capitol" },
      { "key": "Release year", "value": "1992" },
      { "key": "Genre", "value": "Rap rock, Funk rock" }
    ]
  },
  {
    "name": "Highway 61 Revisited - Bob Dylan",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1965" },
      { "key": "Genre", "value": "Folk rock, Blues rock" }
    ]
  },
  {
    "name": "Music Box - Mariah Carey",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1993" },
      { "key": "Genre", "value": "Pop, R&B" }
    ]
  },
  {
    "name": "More Life - Drake",
    "hints": [
      { "key": "Record label", "value": "Young Money, Cash Money, Republic" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Hip hop, R&B, Dancehall" }
    ]
  },
  {
    "name": "Wish You Were Here - Pink Floyd",
    "hints": [
      { "key": "Record label", "value": "Harvest, Columbia" },
      { "key": "Release year", "value": "1975" },
      { "key": "Genre", "value": "Progressive rock" }
    ]
  },
  {
    "name": "Donda - Kanye West",
    "hints": [
      { "key": "Record label", "value": "GOOD, Def Jam" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Hip hop, Gospel" }
    ]
  },
  {
    "name": "Hunky Dory - David Bowie",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "1971" },
      { "key": "Genre", "value": "Glam rock, Art rock" }
    ]
  },
  {
    "name": "Justice - Justin Bieber",
    "hints": [
      { "key": "Record label", "value": "Def Jam" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Pop, R&B" }
    ]
  },
  {
    "name": "The Dark Side of the Moon - Pink Floyd",
    "hints": [
      { "key": "Record label", "value": "Harvest" },
      { "key": "Release year", "value": "1973" },
      { "key": "Genre", "value": "Progressive rock, Psychedelic rock" }
    ]
  },
  {
    "name": "Hybrid Theory - Linkin Park",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Nu metal, Rap metal" }
    ]
  },
  {
    "name": "If You're Reading This It's Too Late - Drake",
    "hints": [
      { "key": "Record label", "value": "Cash Money, OVO Sound, Young Money, Republic" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Kiss Land - The Weeknd",
    "hints": [
      { "key": "Record label", "value": "XO, Republic" },
      { "key": "Release year", "value": "2013" },
      { "key": "Genre", "value": "Alternative R&B" }
    ]
  },
  {
    "name": "Like a Prayer - Madonna",
    "hints": [
      { "key": "Record label", "value": "Sire, Warner Bros." },
      { "key": "Release year", "value": "1989" },
      { "key": "Genre", "value": "Pop, Gospel" }
    ]
  },
  {
    "name": "Damn - Kendrick Lamar",
    "hints": [
      { "key": "Record label", "value": "Top Dawg, Aftermath, Interscope" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Ohms - Deftones",
    "hints": [
      { "key": "Record label", "value": "Reprise" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Alternative metal, Art rock" }
    ]
  },
  {
    "name": "Off the Wall - Michael Jackson",
    "hints": [
      { "key": "Record label", "value": "Epic" },
      { "key": "Release year", "value": "1979" },
      { "key": "Genre", "value": "Pop, Disco, Funk" }
    ]
  },
  {
    "name": "El Camino - The Black Keys",
    "hints": [
      { "key": "Record label", "value": "Nonesuch" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Garage rock" }
    ]
  },
  {
    "name": "Wasteland, Baby! - Hozier",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "All the Right Reasons - Nickelback",
    "hints": [
      { "key": "Record label", "value": "Roadrunner" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Post-grunge" }
    ]
  },
  {
    "name": "Discovery - Daft Punk",
    "hints": [
      { "key": "Record label", "value": "Virgin" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Electronic" }
    ]
  },
  {
    "name": "Brothers - The Black Keys",
    "hints": [
      { "key": "Record label", "value": "Nonesuch" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Blues rock" }
    ]
  },
  {
    "name": "Midnights - Taylor Swift",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Delta Kream - The Black Keys",
    "hints": [
      { "key": "Record label", "value": "Nonesuch" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Blues rock" }
    ]
  },
  {
    "name": "Proof - BTS",
    "hints": [
      { "key": "Record label", "value": "Big Hit" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "K-pop" }
    ]
  },
  {
    "name": "Cavalcade - Black Midi",
    "hints": [
      { "key": "Record label", "value": "Rough Trade" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Experimental rock" }
    ]
  },
  {
    "name": "Black & Blue - Backstreet Boys",
    "hints": [
      { "key": "Record label", "value": "Jive" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Backstreet's Back - Backstreet Boys",
    "hints": [
      { "key": "Record label", "value": "Jive" },
      { "key": "Release year", "value": "1997" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Kid A - Radiohead",
    "hints": [
      { "key": "Record label", "value": "Parlophone" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Electronic" }
    ]
  },
  {
    "name": "Bad - Michael Jackson",
    "hints": [
      { "key": "Record label", "value": "Epic" },
      { "key": "Release year", "value": "1987" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "÷ (Divide) - Ed Sheeran",
    "hints": [
      { "key": "Record label", "value": "Asylum" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Guts - Olivia Rodrigo",
    "hints": [
      { "key": "Record label", "value": "Geffen" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Pop rock" }
    ]
  },
  {
    "name": "24K Magic - Bruno Mars",
    "hints": [
      { "key": "Record label", "value": "Atlantic" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "R&B" }
    ]
  },
  {
    "name": "RTJ4 - Run The Jewels",
    "hints": [
      { "key": "Record label", "value": "Jewel Runners" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Houses of the Holy - Led Zeppelin",
    "hints": [
      { "key": "Record label", "value": "Atlantic" },
      { "key": "Release year", "value": "1973" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Imploding the Mirage - The Killers",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Wake Up and Smell the Coffee - The Cranberries",
    "hints": [
      { "key": "Record label", "value": "MCA" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Unknown Pleasures - Joy Division",
    "hints": [
      { "key": "Record label", "value": "Factory" },
      { "key": "Release year", "value": "1979" },
      { "key": "Genre", "value": "Post-punk" }
    ]
  },
  {
    "name": "Paul's Boutique - Beastie Boys",
    "hints": [
      { "key": "Record label", "value": "Capitol" },
      { "key": "Release year", "value": "1989" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Licensed to Ill - Beastie Boys",
    "hints": [
      { "key": "Record label", "value": "Def Jam" },
      { "key": "Release year", "value": "1986" },
      { "key": "Genre", "value": "Hip hop, Rap rock" }
    ]
  },
  {
    "name": "Infinity on High - Fall Out Boy",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Pop punk, Pop rock" }
    ]
  },
  {
    "name": "Norman Fucking Rockwell! - Lana Del Rey",
    "hints": [
      { "key": "Record label", "value": "Polydor, Interscope" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Soft rock, Psychedelic rock" }
    ]
  },
  {
    "name": "American Idiot - Green Day",
    "hints": [
      { "key": "Record label", "value": "Reprise" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Punk rock, Alternative rock" }
    ]
  },
  {
    "name": "Purpose - Justin Bieber",
    "hints": [
      { "key": "Record label", "value": "Def Jam" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Pop, R&B" }
    ]
  },
  {
    "name": "Room on Fire - The Strokes",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "2003" },
      { "key": "Genre", "value": "Indie rock, Garage rock" }
    ]
  },
  {
    "name": "Rough and Rowdy Ways - Bob Dylan",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Folk rock, Blues" }
    ]
  },
  {
    "name": "The Marshall Mathers LP - Eminem",
    "hints": [
      { "key": "Record label", "value": "Aftermath, Interscope" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "In Between Dreams - Jack Johnson",
    "hints": [
      { "key": "Record label", "value": "Brushfire" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Folk rock, Soft rock" }
    ]
  },
  {
    "name": "The Ascension - Sufjan Stevens",
    "hints": [
      { "key": "Record label", "value": "Asthmatic Kitty" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Art pop, Electronica" }
    ]
  },
  {
    "name": "Minutes to Midnight - Linkin Park",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Alternative rock, Nu metal" }
    ]
  },
  {
    "name": "Brushfire Fairytales - Jack Johnson",
    "hints": [
      { "key": "Record label", "value": "Enjoy, Universal" },
      { "key": "Release year", "value": "2001" },
      { "key": "Genre", "value": "Folk rock, Acoustic" }
    ]
  },
  {
    "name": "Short n' Sweet - Sabrina Carpenter",
    "hints": [
      { "key": "Record label", "value": "Hollywood" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Willy and the Poor Boys - Creedence Clearwater Revival",
    "hints": [
      { "key": "Record label", "value": "Fantasy" },
      { "key": "Release year", "value": "1969" },
      { "key": "Genre", "value": "Roots rock, Swamp rock" }
    ]
  },
  {
    "name": "Invasion of Privacy - Cardi B",
    "hints": [
      { "key": "Record label", "value": "Atlantic" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "This Is Why - Paramore",
    "hints": [
      { "key": "Record label", "value": "Atlantic" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Alternative rock" }
    ]
  },
  {
    "name": "Miss Anthropocene - Grimes",
    "hints": [
      { "key": "Record label", "value": "4AD" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Electropop, Synth-pop" }
    ]
  },
  {
    "name": "My Beautiful Dark Twisted Fantasy - Kanye West",
    "hints": [
      { "key": "Record label", "value": "Def Jam" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Revolver - The Beatles",
    "hints": [
      { "key": "Record label", "value": "Parlophone" },
      { "key": "Release year", "value": "1966" },
      { "key": "Genre", "value": "Rock, Psychedelic rock" }
    ]
  },
  {
    "name": "IGOR - Tyler, The Creator",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Hip hop, R&B" }
    ]
  },
  {
    "name": "Happier Than Ever - Billie Eilish",
    "hints": [
      { "key": "Record label", "value": "Darkroom, Interscope" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Pop, Electropop" }
    ]
  },
  {
    "name": "Rumours - Fleetwood Mac",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "1977" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "I'm Not Dead - P!nk",
    "hints": [
      { "key": "Record label", "value": "LaFace" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Pop rock" }
    ]
  },
  {
    "name": "A Brief Inquiry into Online Relationships - The 1975",
    "hints": [
      { "key": "Record label", "value": "Dirty Hit, Polydor" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Pop rock, Synth-pop" }
    ]
  },
  {
    "name": "Blackstar - David Bowie",
    "hints": [
      { "key": "Record label", "value": "ISO, RCA, Columbia" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Art rock, Jazz" }
    ]
  },
  {
    "name": "First Impressions of Earth - The Strokes",
    "hints": [
      { "key": "Record label", "value": "RCA" },
      { "key": "Release year", "value": "2006" },
      { "key": "Genre", "value": "Indie rock, Garage rock" }
    ]
  },
  {
    "name": "L.A. Woman - The Doors",
    "hints": [
      { "key": "Record label", "value": "Elektra" },
      { "key": "Release year", "value": "1971" },
      { "key": "Genre", "value": "Blues rock, Psychedelic rock" }
    ]
  },
  {
    "name": "Brick Body Kids Still Daydream - Open Mike Eagle",
    "hints": [
      { "key": "Record label", "value": "Mello Music Group" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "beerbongs & bentleys - Post Malone",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Hip hop, Trap" }
    ]
  },
  {
    "name": "Wasting Light - Foo Fighters",
    "hints": [
      { "key": "Record label", "value": "RCA, Roswell" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Alternative rock, Hard rock" }
    ]
  },
  {
    "name": "30 - Adele",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Pop, Soul" }
    ]
  },
  {
    "name": "MTV Unplugged in New York - Nirvana",
    "hints": [
      { "key": "Record label", "value": "DGC, Geffen" },
      { "key": "Release year", "value": "1994" },
      { "key": "Genre", "value": "Alternative rock, Acoustic rock" }
    ]
  },
  {
    "name": "Born Pink - Blackpink",
    "hints": [
      { "key": "Record label", "value": "YG, Interscope" },
      { "key": "Release year", "value": "2022" },
      { "key": "Genre", "value": "K-pop" }
    ]
  },
  {
    "name": "Hotel California - Eagles",
    "hints": [
      { "key": "Record label", "value": "Asylum" },
      { "key": "Release year", "value": "1976" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Fine Line - Harry Styles",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2019" },
      { "key": "Genre", "value": "Pop rock" }
    ]
  },
  {
    "name": "Operation: Doomsday - MF DOOM",
    "hints": [
      { "key": "Record label", "value": "Fondle 'Em" },
      { "key": "Release year", "value": "1999" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "Alphabetical - Phoenix",
    "hints": [
      { "key": "Record label", "value": "Source" },
      { "key": "Release year", "value": "2004" },
      { "key": "Genre", "value": "Alternative rock, Synth-pop" }
    ]
  },
  {
    "name": "the record - boygenius",
    "hints": [
      { "key": "Record label", "value": "Interscope" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Indie rock" }
    ]
  },
  {
    "name": "The River - Bruce Springsteen",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "1980" },
      { "key": "Genre", "value": "Rock" }
    ]
  },
  {
    "name": "Dookie - Green Day",
    "hints": [
      { "key": "Record label", "value": "Reprise" },
      { "key": "Release year", "value": "1994" },
      { "key": "Genre", "value": "Punk rock" }
    ]
  },
  {
    "name": "The Beggar - Swans",
    "hints": [
      { "key": "Record label", "value": "Young God" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Experimental rock" }
    ]
  },
  {
    "name": "Punisher - Phoebe Bridgers",
    "hints": [
      { "key": "Record label", "value": "Dead Oceans" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Indie rock, Folk rock" }
    ]
  },
  {
    "name": "Oracular Spectacular - MGMT",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2007" },
      { "key": "Genre", "value": "Psychedelic rock, Synth-pop" }
    ]
  },
  {
    "name": "Animals - Pink Floyd",
    "hints": [
      { "key": "Record label", "value": "Harvest, Columbia" },
      { "key": "Release year", "value": "1977" },
      { "key": "Genre", "value": "Progressive rock" }
    ]
  },
  {
    "name": "Ceremonials - Florence + The Machine",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "2011" },
      { "key": "Genre", "value": "Baroque pop, Art rock" }
    ]
  },
  {
    "name": "The Golden Echo - Kimbra",
    "hints": [
      { "key": "Record label", "value": "Warner Bros." },
      { "key": "Release year", "value": "2014" },
      { "key": "Genre", "value": "Art pop, Funk" }
    ]
  },
  {
    "name": "American Dream - 21 Savage",
    "hints": [
      { "key": "Record label", "value": "Epic, Slaughter Gang" },
      { "key": "Release year", "value": "2017" },
      { "key": "Genre", "value": "Hip hop, Trap" }
    ]
  },
  {
    "name": "Stankonia - Outkast",
    "hints": [
      { "key": "Record label", "value": "LaFace, Arista" },
      { "key": "Release year", "value": "2000" },
      { "key": "Genre", "value": "Hip hop, Funk" }
    ]
  },
  {
    "name": "Little Dark Age - MGMT",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2018" },
      { "key": "Genre", "value": "Synth-pop, New wave" }
    ]
  },
  {
    "name": "Ray of Light - Madonna",
    "hints": [
      { "key": "Record label", "value": "Maverick, Warner Bros." },
      { "key": "Release year", "value": "1998" },
      { "key": "Genre", "value": "Electronica, Trip hop" }
    ]
  },
  {
    "name": "Planet Her - Doja Cat",
    "hints": [
      { "key": "Record label", "value": "Kemosabe, RCA" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Pop, R&B" }
    ]
  },
  {
    "name": "Combat Rock - The Clash",
    "hints": [
      { "key": "Record label", "value": "CBS, Epic" },
      { "key": "Release year", "value": "1982" },
      { "key": "Genre", "value": "Punk rock, Post-punk" }
    ]
  },
  {
    "name": "Whole Lotta Red - Playboi Carti",
    "hints": [
      { "key": "Record label", "value": "Interscope, AWGE" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "Hip hop, Trap" }
    ]
  },
  {
    "name": "Dark Horse - Nickelback",
    "hints": [
      { "key": "Record label", "value": "Roadrunner" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Post-grunge, Hard rock" }
    ]
  },
  {
    "name": "Folie à Deux - Fall Out Boy",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "2008" },
      { "key": "Genre", "value": "Pop punk, Pop rock" }
    ]
  },
  {
    "name": "The Don Killuminati: The 7 Day Theory - Tupac",
    "hints": [
      { "key": "Record label", "value": "Death Row, Interscope" },
      { "key": "Release year", "value": "1996" },
      { "key": "Genre", "value": "Hip hop, Rap" }
    ]
  },
  {
    "name": "Dangerous Woman - Ariana Grande",
    "hints": [
      { "key": "Record label", "value": "Republic" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Pop, R&B" }
    ]
  },
  {
    "name": "Talking Book - Stevie Wonder",
    "hints": [
      { "key": "Record label", "value": "Tamla" },
      { "key": "Release year", "value": "1972" },
      { "key": "Genre", "value": "Soul, Funk" }
    ]
  },
  {
    "name": "Chemtrails over the Country Club - Lana Del Rey",
    "hints": [
      { "key": "Record label", "value": "Interscope, Polydor" },
      { "key": "Release year", "value": "2021" },
      { "key": "Genre", "value": "Alternative pop, Baroque pop" }
    ]
  },
  {
    "name": "Did You Know That There's a Tunnel Under Ocean Blvd - Lana Del Rey",
    "hints": [
      { "key": "Record label", "value": "Interscope, Polydor" },
      { "key": "Release year", "value": "2023" },
      { "key": "Genre", "value": "Alternative pop, Soft rock" }
    ]
  },
  {
    "name": "My World 2.0 - Justin Bieber",
    "hints": [
      { "key": "Record label", "value": "Island, RBMG" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Pop, Teen pop" }
    ]
  },
  {
    "name": "To Pimp a Butterfly - Kendrick Lamar",
    "hints": [
      { "key": "Record label", "value": "Top Dawg, Aftermath, Interscope" },
      { "key": "Release year", "value": "2015" },
      { "key": "Genre", "value": "Hip hop, Jazz rap" }
    ]
  },
  {
    "name": "The French Album - Celine Dion",
    "hints": [
      { "key": "Record label", "value": "Sony, Epic" },
      { "key": "Release year", "value": "1995" },
      { "key": "Genre", "value": "Pop" }
    ]
  },
  {
    "name": "Dangerous - Michael Jackson",
    "hints": [
      { "key": "Record label", "value": "Epic" },
      { "key": "Release year", "value": "1991" },
      { "key": "Genre", "value": "Pop, New jack swing" }
    ]
  },
  {
    "name": "Views - Drake",
    "hints": [
      { "key": "Record label", "value": "Young Money, Cash Money, Republic" },
      { "key": "Release year", "value": "2016" },
      { "key": "Genre", "value": "Hip hop, R&B" }
    ]
  },
  {
    "name": "Thriller - Michael Jackson",
    "hints": [
      { "key": "Record label", "value": "Epic" },
      { "key": "Release year", "value": "1982" },
      { "key": "Genre", "value": "Pop, Rock, Funk" }
    ]
  },
  {
    "name": "Lungs - Florence + The Machine",
    "hints": [
      { "key": "Record label", "value": "Island" },
      { "key": "Release year", "value": "2009" },
      { "key": "Genre", "value": "Indie rock, Baroque pop" }
    ]
  },
  {
    "name": "One of These Nights - Eagles",
    "hints": [
      { "key": "Record label", "value": "Asylum" },
      { "key": "Release year", "value": "1975" },
      { "key": "Genre", "value": "Rock, Soft rock" }
    ]
  },
  {
    "name": "Hit Me Hard and Soft - Billie Eilish",
    "hints": [
      { "key": "Record label", "value": "Darkroom, Interscope" },
      { "key": "Release year", "value": "2024" },
      { "key": "Genre", "value": "Alt-pop" }
    ]
  },
  {
    "name": "Congratulations - MGMT",
    "hints": [
      { "key": "Record label", "value": "Columbia" },
      { "key": "Release year", "value": "2010" },
      { "key": "Genre", "value": "Psychedelic pop, Neo-psychedelia" }
    ]
  },
  {
    "name": "In Utero - Nirvana",
    "hints": [
      { "key": "Record label", "value": "DGC" },
      { "key": "Release year", "value": "1993" },
      { "key": "Genre", "value": "Grunge, Alternative rock" }
    ]
  },
  {
    "name": "Late Registration - Kanye West",
    "hints": [
      { "key": "Record label", "value": "Def Jam, Roc-A-Fella" },
      { "key": "Release year", "value": "2005" },
      { "key": "Genre", "value": "Hip hop" }
    ]
  },
  {
    "name": "ATLiens - Outkast",
    "hints": [
      { "key": "Record label", "value": "LaFace" },
      { "key": "Release year", "value": "1996" },
      { "key": "Genre", "value": "Southern hip hop" }
    ]
  },
  {
    "name": "Ungodly Hour - Chloe x Halle",
    "hints": [
      { "key": "Record label", "value": "Parkwood, Columbia" },
      { "key": "Release year", "value": "2020" },
      { "key": "Genre", "value": "R&B, Pop" }
    ]
  }
]